import React, { FC, SVGProps, useEffect } from "react";
import "../../CommonComponents/Modal/CommonModal.css";
import { Modal } from "react-bootstrap";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CrossIcon } from "../../../../assets/Images/CrossIcon.svg";
import { buttonStyles } from "./styles";
import { LoadingSlides } from "../Common";

interface CommonIconProp {
  message: string;
  show: boolean;
  toggle: () => void;
  heading: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  spin?: boolean;
  headerText?: string;
}

const CommonPopup = ({
  headerText = "Success",
  message,
  show,
  toggle,
  spin = false,
  heading = "",
  Icon,
}: CommonIconProp) => {

  useEffect(() => {
    let timer;

    if (show) {
      timer = setTimeout(() => {
        toggle();
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [show, toggle]);

  return (
    <Modal
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
      contentClassName=".common-modal-450"
      centered={true}
    >
      <Modal.Header
        className="new-modal-header"
        style={{ backgroundColor: "#F1F4FA", border: "none" }}
      >
        <span className="font-14-600-grey">{headerText}</span>
        <span onClick={toggle} className="modal-cross-icon">
          <CrossIcon />
        </span>
      </Modal.Header>
      <Modal.Body className="new-modal-body">
        <Box className="flex-column" sx={{ justifyContent: "center" }}>
          <Box className="flex-xy-center">
            <Box width={"270px"}>
              <Icon className="ml-4" />
              <Typography
                mt={2}
                textAlign={"center"}
                fontSize={18}
                fontWeight={600}
                color={"#181818"}
              >
                {heading}
              </Typography>
            </Box>
          </Box>
          <Typography
            marginTop={1}
            textAlign={"center"}
            fontSize={14}
            fontWeight={400}
            color={"#555555"}
          >
            {message}
          </Typography>
        </Box>
        <Box
          my={3}
          px={1.5}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button sx={buttonStyles.cancel} onClick={toggle}>
            Close
          </Button>
          <Button
            className="flex-xy-center"
            sx={buttonStyles.saveButton}
            onClick={toggle}
          >
            {spin ? <LoadingSlides bgColor="white" /> : "Okay, thanks"}
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default CommonPopup;
