import React from "react";
import { Autocomplete, MenuItem, TextField, Typography } from "@mui/material";
import { ReactComponent as ArrowDownwardIcon } from "../../../../assets/icons/arrow-downward.svg";

const inputStyles = {
  "& .MuiOutlinedInput-root": {
    fontSize: 13,
    fontWeight: 400,
    padding: "3px 9px",
    borderRadius: 2,
    "& fieldset": {
      borderColor: "#D1D9EF",
    },
    "&:hover fieldset": {
      borderColor: "#181818",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#181818",
      border: 1,
    },
  },
};

const SearchableSelect = ({
  value,
  setValue,
  options,
  placeholder = "Select an option",
}) => {
  return (
    <Autocomplete
      value={options.find((opt) => opt.value === value) || null}
      onChange={(event, newValue) => setValue(newValue?.value || "")}
      options={options}
      getOptionLabel={(option) => option.label || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
          }}
          sx={inputStyles}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <Typography fontSize={13} fontWeight={500}>
            {option.label}
          </Typography>
        </MenuItem>
      )}
      sx={{
        "& .MuiAutocomplete-endAdornment": {
          top: "25%",
        },
      }}
      popupIcon={<ArrowDownwardIcon />}
    />
  );
};

export default SearchableSelect;
