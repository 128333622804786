import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTable from "../CommonComponents/Table/DynamicTable";
import { getCustomWriteAccess } from "../CommonComponents/Common";
import { useSelector } from "react-redux";
import BaseButton from "../Common/Buttons/BaseButton";
import { ReactComponent as ArrowBack } from "../../../assets/icons/arrow-back.svg";

const ViewAudience = ({
  toggleViewList,
  deleteData,
  audienceData,
  saveDeletedData,
  headerList,
}) => {
  const reducer: any = useSelector((state: any) => state);

  return (
    <Box position="relative">
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={1}
        mb={2}
        className="viewlist-btn-container"
      >
        {getCustomWriteAccess(reducer) === "write" && (
          <Button
            disabled={audienceData?.length <= 1}
            onClick={saveDeletedData}
            variant="contained"
            sx={buttonStyles}
          >
            Delete
          </Button>
        )}
        <BaseButton
          title="Back"
          variant="contained"
          handleClick={toggleViewList}
          startIcon={<ArrowBack className="back-icon" />}
        />
      </Box>
      <DynamicTable
        columns={[
          {
            label: "#",
            render: (row) => (
              <input
                onChange={(e) => deleteData(e.target.checked, row.phone)}
                type="checkbox"
              />
            ),
            styles: { padding: 2 },
          },
          {
            label: "FIRST NAME",
            field: "firstName",
          },
          {
            label: "LAST NAME",
            field: "lastName",
          },
          {
            label: "PHONE NUMBER",
            field: "phone",
          },
          ...headerList?.map((header) => ({
            label: header,
            render: (row) => row[header] || "--",
          })),
        ]}
        data={audienceData}
        showTotalRows={true}
        loading={false}
        totalPages={0}
        currentPage={0}
        handlePageChange={() => ""}
        handlePrevious={() => ""}
        handleNext={() => ""}
        noDataAvailableMessage={"No Audiences Available"}
        rowCount={audienceData?.length}
        pagination={true}
      />
    </Box>
  );
};

export default ViewAudience;

const buttonStyles = {
  fontSize: 13,
  fontWeight: 500,
  borderRadius: 2,
  textTransform: "capitalize",
  boxShadow: "none",
  backgroundColor: "#FF5E00BF",
  color: "white",
  "&:hover": {
    backgroundColor: "#FF7E33BF",
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(255, 94, 0, 0.55)",
    color: "white",
  },
};
