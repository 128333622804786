import {
    swalErrorMessage,
    swalSuccessMessage,
  } from "../../../../Constant/swalMessage";
  import {
    deleteProjectsService,
    editProjectsService,
    getAdvanceSettingsService,
    getProjectByIdService,
    getProjectSheetsDataService,
    getProjectSheetsService,
    getProjectsService,
    saveAdvanceSettingsService,
    saveProjectsService,
  } from "../../services/projects.services";
  import { projectsType } from "./projects.type";
  export const getProjectsAction = () => async (dispatch) => {
    let res = await getProjectsService();
    if (res) {
      await dispatch({
        type: projectsType.GET_PROJECTS_LIST,
        payload: res?.data,
      });
    }
  };
  
  export const getProjectByIdAction = (id) => async (dispatch) => {
    let res = await getProjectByIdService(id);
    if (res) {
      await dispatch({
        type: projectsType.GET_SELECTED_PROJECT,
        payload: res?.data,
      });
    }
  };
  
  export const saveProjectsAction =
    (obj, swal = true) =>
    async (dispatch) => {
      let res = await saveProjectsService(obj);
      if (res.status === 200) {
        return res;
      } else {
        swalErrorMessage(res.errMsg);
      }
    };
  
  export const editProjectsAction =
    (obj, id, displayAlert = true) =>
    async (dispatch) => {
      let res = await editProjectsService(obj, id);
      if (res.status === 200) {
        if (displayAlert) {
          swalSuccessMessage(res.msg);
        }
        return res.status;
      } else {
        swalErrorMessage(res.errMsg);
      }
    };
  
  export const deleteProjectsAction = (id) => async (dispatch) => {
    let res = await deleteProjectsService(id);
    if (res.status === 200) {
      swalSuccessMessage(res.msg);
      return res.status;
    } else {
      swalErrorMessage(res.errMsg);
    }
  };
  
  export const getAdvanceSettingAction = (id) => async (dispatch) => {
    let res = await getAdvanceSettingsService(id);
    if (res) {
      await dispatch({
        type: projectsType.GET_ADVANCE_SETTING,
        payload: res?.data,
      });
    }
  };
  
  export const saveAdvanceSettingAction = (obj, id) => async (dispatch) => {
    let res = await saveAdvanceSettingsService(obj, id);
    if (res.status === 200) {
      swalSuccessMessage(res.msg);
      return res.status;
    } else {
      swalErrorMessage(res.errMsg);
    }
  };
  
  export const clearProjectReducer = () => async (dispatch) => {
    await dispatch({
      type: projectsType.CLEAR_PROJECT_REDUCER,
      payload: {},
    });
  };
  
  export const updateProjectAccessAction = (arr) => async (dispatch) => {
    await dispatch({
      type: projectsType.PROJECT_ACCESS,
      payload: arr,
    });
  };

  export const getProjectSheetsAction = async (data) => {
    const res = await getProjectSheetsService(data);
    if (res?.status === 200) return res?.data;
  };

  export const getProjectSheetsDataAction = async (data,projectId) => {
    const res = await getProjectSheetsDataService(data,projectId);
    if (res?.status === 200) return res?.data;
  };
  