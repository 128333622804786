import React from "react";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../../../assets/Images/CalendarIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
import BaseButton from "../Common/Buttons/BaseButton";

const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const Heading = ({
  filterState,
  setFilterState,
  resetFilters,
  applyFilters,
  refreshLoading,
}) => {
  const { selectedprojectId, dateRange } = filterState;
  const [startingDate, endingDate] = dateRange;

  const handleProjectChange = (e) => {
    setFilterState((prev) => ({
      ...prev,
      selectedprojectId: e.target.value,
    }));
  };

  const handleDateChange = (updated) => {
    setFilterState((prev) => ({
      ...prev,
      dateRange: updated,
    }));
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography color="#181818" fontSize={20} fontWeight={600}>
        Dashboard
      </Typography>
      <Box height={36} display="flex" justifyContent="space-between" gap={1}>
        <Box sx={styles.calendarContainer}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selectsRange
            maxDate={addDays(new Date(), 0)}
            startDate={startingDate}
            endDate={endingDate}
            onChange={handleDateChange}
            placeholderText="From date - To date"
            className="custom-date-input"
          />
          <CalendarIcon className="calendar-icon" />
        </Box>
        <BaseButton
          variant="contained"
          title="Apply"
          disabled={
            refreshLoading ||
            (!selectedprojectId && (!startingDate || !endingDate))
          }
          handleClick={() => applyFilters(false)}
        />
        <BaseButton
          variant="outlined"
          title="Reset"
          disabled={refreshLoading}
          handleClick={resetFilters}
        />

        <IconButton
          title="Refresh"
          onClick={() => applyFilters(true)}
          sx={{
            ...styles.refreshButton,
            "& .refresh-icon": {
              animation: refreshLoading ? "rotate 1s linear infinite" : "none",
            },
            "@keyframes rotate": {
              from: {
                transform: "rotate(0deg)",
              },
              to: {
                transform: "rotate(360deg)",
              },
            },
          }}
          disabled={refreshLoading}
        >
          <RefreshIcon className="refresh-icon" height={14} width={14} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Heading;

const styles = {
  projectSelect: {
    fontSize: 14,
    fontWeight: 400,
    borderColor: "#D1D9EF",
    borderRadius: 1,
    width: 160,
    color: "#9E9FA1",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
    },
  },
  calendarContainer: {
    position: "relative",
    width: "auto",
    border: "1px solid #CCD2DE",
    borderRadius: 2,
    padding: 0,
    "&:hover": {
      borderColor: "#666",
    },
  },
  refreshButton: {
    borderRadius: 2,
    px: 1.25,
    color: "#D1D9EF",
    border: 1,
    "&:hover": {
      borderColor: "#666",
    },
  },
};
