import React, { useEffect, useState } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { axiosClient } from "../../../../Constant/apiConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  HintIconWithTooltip,
  LoadingDotsSpin,
  renderThumb,
} from "../../CommonComponents/Common";
import { Scrollbars } from "react-custom-scrollbars";
import "../../Dashboard/Onboarding.css";

function SummaryModal({ show, toggle, state, setState }) {
  const reducer: any = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    RecordingReducer: { recordings },
  } = reducer;
  const [localState, setLocalState] = useState({
    summaryView: false,
    summary: "",
    buttonTrue: false,
    noSummary: false,
    language: "English",
    summarySpin: false,
  });
  const [messages, setMessages] = useState<any>([]);
  const [spin, setSpin] = useState(true);
  const { selectedCampaignRun } = state;
  useEffect(() => {
    const init = async () => {
      let res = await axiosClient.get(
        `${process.env.REACT_APP_BASE_URL_AI}/analyze/transcript/${selectedCampaignRun?.call_uuid}`
      );
      setMessages(res?.data?.transcription || []);
      setSpin(false);
    };

    init();
  }, []);

  const viewCallSummary = async () => {
    setLocalState((prev) => ({
      ...prev,
      summaryView: true,
      buttonTrue: true,
      summarySpin: true,
    }));
    // let res = await axiosClient.post(
    //   `${process.env.REACT_APP_BASE_URL_AI}/prompt/summary`,
    //   { call_uuid: selectedCampaignRun?.call_uuid }
    //   // { recording_url: selectedCampaignRun?.callRecordingUrl }
    // );
    let res = await axiosClient.post(
      `${process.env.REACT_APP_BASE_URL_AI}/analyze/summary`,
      { call_uuid: selectedCampaignRun?.call_uuid, language: language }
      // { recording_url: selectedCampaignRun?.callRecordingUrl }
    );
    if (res) {
      if (res.data.summary) {
        setLocalState((prev) => ({
          ...prev,
          summaryView: true,
          summary: res.data.summary,
          buttonTrue: false,
          summarySpin: false,
        }));
      }
    } else {
      setLocalState((prev) => ({
        ...prev,
        summaryView: true,
        buttonTrue: false,
        noSummary: true,
        summarySpin: false,
      }));
    }
  };
  const { summaryView, summary, buttonTrue, noSummary, language, summarySpin } =
    localState;
  const getResponse = (tag) => {
    let returnRes = "";
    selectedCampaignRun?.outcomes_response?.forEach((res) => {
      if (res.split(":")[0] === tag) {
        returnRes = res.split(":")[1];
      }
    });
    return returnRes;
  };
  const Message = ({ message, isUser }) => {
    isUser = isUser === "input" ? true : false;
    return (
      <div style={{ textAlign: isUser ? "right" : "left", margin: "8px 0" }}>
        <div
          style={{
            backgroundColor: isUser ? "#DCF8C6" : "#ECE5DD",
            padding: "8px",
            borderRadius: "8px",
            display: "inline-block",
            background:
              "linear-gradient(to right, rgb(15, 12, 41), rgb(48, 43, 99), rgb(36, 36, 62))",
          }}
        >
          <p style={{ margin: "0" }} className="color-white">
            <strong>{isUser ? "You" : "Bot"}</strong> - {message.value}
          </p>
          <small>{message.timestamp}</small>
        </div>
      </div>
    );
  };
  return (
    <div>
      {" "}
      <Modal keyboard={false} show={show} onHide={toggle} size="lg">
        <Modal.Header closeButton>
          <h6 className="m-0 font-weight-bold text-green">Responses</h6>
        </Modal.Header>
        <Modal.Body style={{ background: "whitesmoke" }}>
          <Scrollbars
            style={{
              height: "420px",
              color: "white",
              borderRadius: "10px",
              // border: "1px solid #d17842",
            }}
            renderThumbVertical={renderThumb}
            autoHide={true}
          >
            <div
              style={{
                padding: "10px",
              }}
            >
              {spin ? (
                <LoadingDotsSpin />
              ) : (
                messages.map((message) => (
                  <Message
                    key={message.id}
                    message={message}
                    isUser={message.type}
                  />
                ))
              )}
            </div>
          </Scrollbars>
        </Modal.Body>
        <Modal.Body>
          <hr />
          <FormGroup>
            <label>
              Enter Language{" "}
              <HintIconWithTooltip
                content={
                  "To receive a summary in your preferred language, kindly specify the language (e.g., English, Hindi, Chinese)."
                }
              />{" "}
            </label>
            <input
              value={language}
              className="form-control w-30"
              placeholder="English/Hindi/Chinese"
              onChange={(e) => {
                e.persist();
                setLocalState((prev) => ({
                  ...prev,
                  language: e.target.value,
                }));
              }}
            />
          </FormGroup>

          <button
            disabled={buttonTrue}
            className="btn btn-info no-padding"
            onClick={viewCallSummary}
          >
            {buttonTrue ? "Fetching" : "Get Call Summary"}
          </button>
          <button className="btn btn-info no-padding" onClick={toggle}>
            Close
          </button>
        </Modal.Body>

        {summaryView && (
          <Modal.Body>
            <div className="card">
              <div className="card-header">
                <h6 className="m-0 font-weight-bold text-green">
                  Summary of Call
                </h6>
              </div>

              <div className="card-body">
                {summarySpin && <Skeleton count={2} height={15} />}
                {summary && summary}
                {noSummary && "There is no summary for this call."}
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
}

export default SummaryModal;
