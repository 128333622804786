import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosClient, BASE_URL_AI } from "../../../../Constant/apiConstant";
import { getCampaignDetailsAllAction } from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../CommonComponents/Table/DynamicTable";
import ReactAudioPlayer from "react-audio-player";
import * as FileSaver from "file-saver";

import BaseButton from "../../Common/Buttons/BaseButton";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import { isEmpty } from "../../CommonComponents/Common";
import * as XLSX from "xlsx";

const Insight = ({ campaignRunListData, campaignName, all = false }) => {
  const dispatch = useDispatch();
  let campaignDetails: any = localStorage.getItem("campaign");
  let data = JSON.parse(campaignDetails);
  const { projectId } = useParams<{ projectId: string }>();
  const location = useLocation();

  const locSearch = new URLSearchParams(location.search);
  let campaignRuns = locSearch.get("campaignRuns");

  const [allCampaignRunDetails, setAllCampaignRunDetails] = useState<any>([]);
  const [state, setState] = useState<any>({
    questions: [{ question: "", key: "", type: "" }],
    about: "",
    data: [],
    selectedData: [],
    isDisabled: false,
  });
  const { selectedData } = state;

  const getName = (phone) => {
    let nameFind1 = allCampaignRunDetails?.find((num) => num.phone === phone);
    if (nameFind1) {
      return {
        firstName: nameFind1?.customParameters?.firstName || "--",
        lastName: nameFind1.customParameters?.lastName || "--",
        recordingUrl: nameFind1?.callRecordingUrl || "--",
      };
    } else {
      return {
        firstName: "--",
        lastName: "--",
        recordingUrl: "",
      };
    }
  };

  useEffect(() => {
    const init = async () => {
      let data1 = [];
      if (all) {
        let res = await axiosClient.get(
          `${BASE_URL_AI}/prompt/entities/list/${data._id}`,
          { params: { page: 0, page_size: 0 } }
        );
        data1 = res?.data?.data;
      } else {
        let res = await axiosClient.get(
          `${BASE_URL_AI}/prompt/entities?campaign_name=${projectId}&campaign_run_id=${campaignRuns}`
        );
        data1 = res.data;
      }

      let obj = {
        pageIndex: 1,
        pageSize: 1000,
        live: false,
        callStatus: ["703"],
      };
      let res: any = await dispatch(
        getCampaignDetailsAllAction(projectId, campaignRuns, obj)
      );

      setAllCampaignRunDetails(res);
      setState((prev) => ({
        ...prev,
        data: data1,
      }));
    };

    init();

    return () => {
      setState((prev) => ({
        ...prev,
        data: [],
      }));
    };
  }, []);

  const getData = (output) => {
    if (typeof output === "string") {
      return output;
    }

    let outputData: Array<string> = [];

    if (Array.isArray(output)) {
      output?.map((data) => {
        let strinOb = `${data?.key} : ${data?.value}`;
        outputData.push(strinOb);
      });
    } else {
      return `${output?.key} : ${output?.value}`;
    }

    return outputData.join(",");
  };

  const ExportCSV = async () => {
    let selectedCopy: any = state.data;
    selectedCopy = selectedCopy.map((da, idx) => {
      return {
        recording_url: da.recording_url,
        phone_number: da.phone_number,
        output: da.output,
        idx: idx,
      };
    });
    setState((prev) => ({
      ...prev,
      selectedData: selectedCopy,
    }));
    if (selectedCopy.length > 0) {
      let modifiedArr: Array<object> = [];
      selectedCopy.forEach((data: any) => {
        let nameFind1 = allCampaignRunDetails?.find(
          (num) => num?.phone === data?.phone_number
        );
        let obj = {
          "First Name": nameFind1?.customParameters?.firstName || "--",
          "Last Name": nameFind1?.customParameters?.lastName || "--",
          "Recording Url": nameFind1?.callRecordingUrl,
          "Phone Number": data.phone_number,
          // Output: getData(data?.output),
        };
        if (!isEmpty(data?.output)) {
          data?.output.forEach((out) => {
            obj[out.key] = out.value;
          });
        }
        modifiedArr.push(obj);
      });
      setState((prev) => ({
        ...prev,
        exportSpin: true,
      }));
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(modifiedArr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Campaign Report ${campaignName}` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };

  return (
    <Box>
      {state?.data?.length > 0 && (
        <Box display={"flex"} justifyContent={"flex-end"}>
          <BaseButton
            title="Export"
            variant="outlined"
            startIcon={<ExportIcon height={14} width={14} />}
            loading={false}
            handleClick={ExportCSV}
            styles={{ px: 2, fontSize: 12 }}
          />
        </Box>
      )}

      <Box mt={2}>
        <DynamicTable
          columns={[
            {
              label: "First Name",
              render: (data) => {
                const nameData = getName(data?.phone_number);
                return <>{nameData?.firstName}</>;
              },
            },
            {
              label: "Last Name",
              render: (data) => {
                const nameData = getName(data?.phone_number);
                return <>{nameData?.lastName}</>;
              },
            },
            {
              label: "Phone Number",
              render: (data) => {
                return data?.phone_number;
              },
            },
            {
              label: "Recording",
              render: (data) => {
                return (
                  <ReactAudioPlayer
                    src={`https://storage.googleapis.com/botstream-recordings/${data?.call_uuid}.mp3`}
                    controls
                    style={{
                      minWidth: "40px",
                      height: "30px",
                    }}
                  />
                );
              },
            },
            {
              label: "Output",
              render: (data) => {
                return getData(data?.output);
              },
            },
          ]}
          data={state?.data}
          loading={false}
          totalPages={1}
          currentPage={1}
          handlePageChange={() => {}}
          handlePrevious={() => {}}
          handleNext={() => {}}
          noDataAvailableMessage="No Insights Available"
          rowCount={state?.data?.length}
        />
      </Box>
    </Box>
  );
};

export default Insight;
