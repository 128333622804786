import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactComponent as AddIcon } from "../../../assets/Images/AddIcon.svg";
import { getCustomWriteAccess } from "../CommonComponents/Common";
import SearchBar from "../Common/Search/SearchBar";

const AudienceHeader = ({ searchText, toggle, setState, viewListShow }) => {
  const reducer: any = useSelector((state) => state);

  const {
    AudienceReducer: { audienceCount },
  } = reducer;
  return (
    <Box pb={4} className="flex-between">
      <Box className="flex-xy-center" gap={1.5}>
        <Typography color="#181818" fontSize={20} fontWeight={600}>
          Audiences
        </Typography>
        <Typography
          color="#555555"
          component="span"
          fontSize={14}
          fontWeight={500}
          border={1}
          borderColor="#CCD2DE"
          px={1.5}
          py={0.25}
          borderRadius="9px"
        >
          Total{" "}
          <span style={{ color: "#55555599", fontSize: 20, fontWeight: 700 }}>
            {audienceCount || 0}
          </span>
        </Typography>
      </Box>
      {!viewListShow && (
        <Box className="flex-xy-center" gap={2}>
          <SearchBar
            searchText={searchText}
            handleChange={(e) => {
              e.persist();
              setState((prev) => ({
                ...prev,
                searchText: e.target.value,
              }));
            }}
          />

          {getCustomWriteAccess(reducer) === "write" && (
            <button onClick={toggle} className="cmn-button flex-xy-center">
              <AddIcon style={{ marginRight: "5px" }} />
              Add New Audience
            </button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AudienceHeader;
