import React, { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Box, ListItem, Typography } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";

const SearchBar = ({
  searchModal,
  handleSearchBar,
  isVisible,
  options,
  setInputValue,
  inputValue,
  setShowSettings,
  setShowAdmin,
  visitedItems,
  setVisitedItems,
  setSearchModal,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  useEffect(() => {
    const visitedLoc: any = localStorage.getItem("visitedItems");
    const visitedItemsArr = JSON.parse(visitedLoc) || [];
    setVisitedItems(visitedItemsArr);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        handleSearchBar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (e) => {
    if (e) {
      if (e.value === "/account/access") {
        setShowSettings(true);
        setShowAdmin(false);
      } else if (e.value === "/account/admin") {
        setShowAdmin(true);
        setShowSettings(false);
      } else {
        setShowSettings(false);
        setShowAdmin(false);
      }

      let visitedLoc: any = localStorage.getItem("visitedItems");
      let visitedItems = JSON.parse(visitedLoc) || [];

      const newItem = { name: e.value, label: e.label };

      if (!visitedItems.some((item) => item.name === newItem.name)) {
        visitedItems.push(newItem);

        localStorage.setItem("visitedItems", JSON.stringify(visitedItems));
        setVisitedItems(visitedItems);
      }

      history.push(e.value);
      setSearchModal(false);
    }
  };

  return createPortal(
    <Box
      ref={containerRef}
      sx={{ ...styles.mainContainer, opacity: isVisible ? 1 : 0 }}
    >
      <Box>
        <Select
          options={options}
          isSearchable={true}
          inputValue={inputValue}
          onInputChange={(value) => {
            if (value.length > 20) {
              return;
            }
            setInputValue(value);
          }}
          value={options?.find((option) => option?.value === inputValue)}
          placeholder="Search..."
          className="basic-single project-select"
          classNamePrefix="select"
          getOptionLabel={(e) => e?.label}
          getOptionValue={(e) => e?.value}
          onChange={(e) => handleSelect(e)}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          styles={styles.searchBarStyle}
        />
        <Box mb={1.5} className="flex-align-center" mt={2}>
          <HistoryIcon sx={{ height: "15px", width: "15px" }} />
          <Typography className="font-14-grey" ml={1}>
            Recently Visited
          </Typography>
        </Box>
        <Box component={"span"} className="font-13-darkgrey">
          {visitedItems?.length > 0 ? (
            visitedItems.map((item, index) => (
              <ListItem
                key={index}
                className="pointer visited-item"
                onClick={() => history.push(item.name)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    transition: "background-color 0.3s ease",
                    borderRadius: 2,
                  },
                }}
              >
                {item.label}
              </ListItem>
            ))
          ) : (
            <Box
              mt={1.5}
              className="font-13-darkgrey flex-xy-center"
              sx={{
                textAlign: "center",
                backgroundColor: "#f0f0f0",
                px: 0.5,
                py: 1,
                borderRadius: 2,
              }}
              component={"span"}
            >
              No recent activity
            </Box>
          )}
        </Box>
      </Box>
    </Box>,
    document.body
  );
};

export default SearchBar;

const styles = {
  searchBarStyle: {
    control: (provided) => ({
      ...provided,
      border: "1px solid #CCD2DE",
      padding: "2px",
      borderRadius: 8,
      boxShadow: "none",
      fontSize: 13,
      "&:hover": {
        borderColor: "#CCD2DE",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#555555",
      fontSize: 13,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333333",
      fontSize: 13,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 8,
      border: "1px solid #CCD2DE",
      padding: "5px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 2,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#333333" : "#555555",
      backgroundColor: state.isSelected ? "#DDE8FF" : "#FFFFFF",
      fontSize: 13,
      borderRadius: 2,
      padding: "10px 12px",
      "&:hover": {
        backgroundColor: state.isSelected ? "#DDE8FF" : "#F2F2F2",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  },
  mainContainer: {
    position: "fixed",
    top: "80px",
    left: "100px",
    width: "350px",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "8px",
    zIndex: 9999,
    transition: "opacity 0.3s ease, transform 0.3s ease",
    minHeight: "250px",
    padding: "13px",
  },
};
