import { Agent } from "../types/workflow";
import { MessageSquare, Mail, Phone, Calendar } from "lucide-react";

export const availableAgents: Agent[] = [
  {
    id: "sms",
    name: "SMS Agent",
    description: "Send and receive SMS messages",
    icon: "MessageSquare",
  },
  {
    id: "email",
    name: "Email Agent",
    description: "Handle email communications",
    icon: "Mail",
  },
  {
    id: "call",
    name: "Calling Agent",
    description: "Make and manage phone calls",
    icon: "Phone",
  },
  {
    id: "meeting",
    name: "Meeting Agent",
    description: "Schedule and manage meetings",
    icon: "Calendar",
  },
];
