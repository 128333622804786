import React from "react";
import { Box, Radio, TextField, Typography } from "@mui/material";
import Select from "react-select";
import { ReactComponent as AlertIcon } from "../../../../assets/Images/Alert.svg";
import { commonModalStyles } from "./styles";

const FilteredCamapaignModal = ({ obj }) => {
  const {
    setCampaignName,
    campaignName,
    audienceName,
    setAudienceName,
    setRange,
    range,
    audienceArray,
  } = obj;
  return (
    <Box minHeight={300}>
      <Box>
        <Box>
          <Typography {...commonModalStyles.label}>Campaign Name</Typography>
          <TextField
            name="name"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            variant="outlined"
            fullWidth
            placeholder="Example it can be “Real Estate Campaign”"
            sx={commonModalStyles.textInput}
            inputProps={{ maxLength: 35 }}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Typography {...commonModalStyles.label}>Audience</Typography>
        <TextField
            name="audname"
            value={audienceName}
            onChange={(e) => setAudienceName(e.target.value)}
            variant="outlined"
            fullWidth
            placeholder="Enter Audience Name"
            sx={commonModalStyles.textInput}
            inputProps={{ maxLength: 35 }}
          />
        {/* <Select
          maxMenuHeight={180}
          styles={styles.customSelect}
          className="basic-single"
          classNamePrefix="select"
          value={
            audienceName
              ? audienceArray?.find(
                  (item: any) => item?.audienceName === audienceName
                )
              : null
          }
          isDisabled={false}
          isSearchable={true}
          options={audienceArray}
          getOptionLabel={(e: any) => e?.audienceName}
          onChange={(e) => {
            setAudienceName(e?.audienceName);
          }}
          placeholder="Select Audience"
        /> */}
      </Box>
      <Box mt={2}>
        <Typography {...commonModalStyles.label}>Campaign Type :</Typography>
        <Box display={"flex"} alignItems={"flex-start"} gap={1}>
          <Box className="flex-xy-center" gap={0.5}>
            <Radio
              checked={!range}
              onChange={() => {
                setRange(false);
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Typography {...commonModalStyles.label} mb={0}>
              Default
            </Typography>
          </Box>
          <Box className="flex-xy-center" gap={1}>
            <Radio
              checked={range}
              onChange={() => {
                setRange(true);
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Typography {...commonModalStyles.label} mb={0}>
              Range
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        mt={2}
        className="flex-xy-center"
        sx={{
          height: "50px",
          maxWidth: "410px",
          bgcolor: "#F0FAFF",
          color: "#0075AD",
          fontSize: "12px",
          fontWeight: 400,
          borderRadius: 2,
        }}
        component={"span"}
      >
        <AlertIcon className="mr-2" />
        Note: Please use unique names for both campaign and audience
      </Box>
    </Box>
  );
};

export default FilteredCamapaignModal;