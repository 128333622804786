import { Box, Typography } from "@mui/material";
import React from "react";

const SummaryCard = ({ title, value, circleColor }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      sx={{
        minHeight: 80,
        padding: 1,
        borderRadius: 2,
        border: "1px solid #CCD2DE",
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography color="#555555" fontSize={12} fontWeight={500}>
          {title}
        </Typography>
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: "50%",
            background: circleColor,
          }}
          />
      </Box>

      <Typography color="#181818" fontSize={20} fontWeight={700}>
        {value}
      </Typography>
    </Box>
  );
};

export default SummaryCard;