import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowBackIcon } from "../../../assets/icons/arrow-back.svg";
import eyeSlash from "../../../assets/icons/eye-slash.svg";
import eye from "../../../assets/icons/eye.svg";
import { registerNewAccountAction } from "../Actions/CommonActions";
import BaseButton from "../Common/Buttons/BaseButton";
import { isVodexLoggedin, LoadingSlides } from "../CommonComponents/Common";
import authStyles from "./styles";

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { whitelabelDetails },
  } = reducer;

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [signUpData, setSignUpData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    agree: false,
    url: "",
  });
  const [msg, setMsg] = useState("");
  const [spin, setSpin] = useState(false);
  const { email, password, confirmPassword, agree, url } = signUpData;

  useEffect(() => {
    const init = async () => {
      let url = "";
      if (isVodexLoggedin()) {
        url = "https://vodex.ai/terms-of-use";
      } else {
        url = whitelabelDetails?.termUrl;
      }

      setSignUpData((prev) => ({
        ...prev,
        url: url,
      }));
    };
    init();
  }, [whitelabelDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTogglePassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSignUp = async (e) => {
    setSpin(true);
    e.preventDefault();
    setMsg("");

    if (!email || !password || !confirmPassword) {
      setMsg(`Please fill all the fields`);
      setSpin(false);
      return;
    }

    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+[\]{}|\\:;'"<>,.?/~`]/.test(password)
    ) {
      if (password === confirmPassword) {
        if (!agree) {
          setMsg(`Please accept terms and conditions.`);
          setSpin(false);

          return;
        }
        let obj = {
          email: email.trim(),
          password: password,
          confirmPassword: confirmPassword,
        };

        let res: any = await dispatch(registerNewAccountAction(obj));
        if (res) {
          history.push("/");
        }
      } else {
        setMsg(`Password doesn't match `);
        setMsg("");
      }
    } else {
      setMsg(
        " Password must be at least 8 characters must contain an uppercase, lowercase,numbers and special characters"
      );
    }
    setSpin(false);
  };

  return (
    <Box className="main-container">
      <Typography color="#656E7F" fontSize={14} fontWeight={500}>
        Welcome!
      </Typography>
      <Typography fontSize={28} fontWeight={700} mb={1} color="#1A1D23">
        Sign Up with your email!
      </Typography>

      <Box mb={2}>
        <Typography {...authStyles.label}>Email</Typography>
        <TextField
          type="email"
          name="email"
          value={email?.toLowerCase()}
          required={true}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Enter your email"
          sx={authStyles.textInput}
        />
      </Box>

      <Box mb={2}>
        <Typography {...authStyles.label}>Password</Typography>
        <TextField
          type={showPassword.password ? "text" : "password"}
          required={true}
          name="password"
          value={password}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Enter your password"
          sx={authStyles.textInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleTogglePassword("password")}
                  edge="end"
                  sx={{ padding: "0", marginRight: 1.5 }}
                  disableRipple
                  disableFocusRipple
                  style={{ outline: "none" }}
                >
                  <img
                    src={showPassword.password ? eyeSlash : eye}
                    alt="password visibility"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box mb={2}>
        <Typography {...authStyles.label}>Confirm Password</Typography>
        <TextField
          type={showPassword.confirmPassword ? "text" : "password"}
          required={true}
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Confirm your password"
          sx={authStyles.textInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleTogglePassword("confirmPassword")}
                  edge="end"
                  sx={{ padding: "0", marginRight: 1.5, outline: "none" }}
                  disableRipple
                  disableFocusRipple
                >
                  <img
                    src={showPassword.confirmPassword ? eyeSlash : eye}
                    alt="password visibility"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {msg && (
        <Typography sx={{ color: "red", textAlign: "center", my: 1 }}>
          {msg}
        </Typography>
      )}
      <Box className="flex-xy-center">
        <Checkbox
          name="agree"
          checked={agree}
          onChange={() => {
            setSignUpData((prev) => ({ ...prev, agree: !agree }));
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 20,
            },
          }}
        />
        <Typography
          color="#1A1D23"
          fontSize={14}
          fontWeight={400}
          textAlign="left"
          my={1}
          pr={2}
        >
          By continuing you agree to Vodex{" "}
          <a target="_blank" href={url}>
            <span style={{ color: "#4280FF" }}>Terms of Service</span> and{" "}
            <span style={{ color: "#4280FF" }}>Privacy Policy</span>
          </a>
        </Typography>
      </Box>

      <Button
        onClick={handleSignUp}
        disabled={spin}
        variant="contained"
        sx={authStyles.loginButton}
        fullWidth
      >
        {spin ? <LoadingSlides bgColor="white" /> : "Sign Up"}
      </Button>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <BaseButton
          title="Go Back to login"
          handleClick={() => history.push("/")}
          startIcon={<ArrowBackIcon />}
          styles={{ color: "#1A1D23", fontSize: 14, fontWeight: 400 }}
        />
      </Box>
    </Box>
  );
};

export default SignUp;
