import { Box, Typography } from "@mui/material";
import React from "react";

const DurationFilter = ({
  durationFilter,
  mainState,
  arraMapped,
  allCallStatus,
  outComefilter,
  onChange,
  setMainState,
}) => {
  const onCustomDurationChange = (e) => {
    const { name, value } = e.target;
    if (value && Number(value) <= 0) return;

    setMainState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Box className="mt-4">
      <span className="font-12-grey">Duration</span>
      <Box
        className="mt-2"
        sx={{
          height: "40px",
          background: "#F1F4FA",
          borderRadius: "100px",
          padding: "0 5px",
        }}
      >
        {arraMapped.map((arr, index) => (
          <Box key={index} sx={{ paddingTop: "4px" }}>
            <Box className="flex-evenly">
              {arr.innerMap?.map((innerArr, i) => {
                const dur = arr?.title === "Filter Calls with status";
                const isSelected = arr?.isObject
                  ? outComefilter.find((o) => o === innerArr?.tag)
                  : mainState[arr?.valueAssigned] === innerArr ||
                    mainState[arr?.valueAssigned].includes(innerArr);

                return (
                  <Box key={i}>
                    <Box
                      onClick={() => onChange(arr?.valueAssigned, innerArr)}
                      sx={{
                        width: "70px",
                        height: "32px",
                        background: isSelected ? "#FFFFFF" : "#F1F4FA",
                        borderRadius: "100px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="font-12-lightgrey">
                        {!arr?.isObject &&
                          (dur ? allCallStatus[innerArr] : innerArr)}
                        {arr?.isObject && innerArr?.name}
                      </span>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
      </Box>
      <Box>
        {durationFilter === "Custom" && (
          <Box>
            <span className="font-12-lightgrey mt-2">Custom Duration</span>
            <Box className="flex-between">
              <input
                style={{ width: "150px" }}
                placeholder="from (in sec.)"
                name="min"
                min={1}
                type="number"
                className="form-control custm-ip mr-1"
                value={mainState?.min}
                onChange={onCustomDurationChange}
              />
              <input
                style={{ width: "150px" }}
                placeholder="to (in sec.)"
                name="max"
                min={1}
                type="number"
                className="form-control custm-ip"
                value={mainState?.max}
                onChange={onCustomDurationChange}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Box
          onClick={() => {
            setMainState((prev) => ({
              ...prev,
              durationFilter: "",
            }));
          }}
          component={"span"}
          fontSize={"12px"}
          fontWeight={500}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            cursor: "pointer",
            color: "#555555",
            marginLeft: "10px",
            marginTop:"3px",
            "&:hover": { color: "#181818" },
          }}
        >
          Clear Duration Filter
        </Box>
      </Box>
    </Box>
  );
};

export default DurationFilter;
