import React, { ReactNode } from "react";
import { Button } from "@mui/material";
import { LoadingSlides } from "../../CommonComponents/Common";

interface BaseButtonProps {
  title: any;
  handleClick: (e: React.FormEvent) => void;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: "text" | "outlined" | "contained";
  styles?: any;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
}

const BaseButton: React.FC<BaseButtonProps> = ({
  title,
  handleClick,
  disabled = false,
  startIcon,
  endIcon,
  styles = {},
  variant = "text",
  loading = false,
  type = "button",
}) => {
  return (
    <Button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      startIcon={startIcon || undefined}
      endIcon={endIcon || undefined}
      variant={variant}
      sx={{
        color: variant === "contained" ? "white" : "#333333",
        fontSize: variant === "text" ? 11 : 13,
        fontWeight: 500,
        borderRadius: 2,
        textTransform: "capitalize",
        textWrap: "nowrap",
        boxShadow: "none",
        paddingX: 1.5,
        paddingY: "6.5px",
        backgroundColor: variant === "contained" ? "#FF5E00BF" : "transparent",
        borderColor: variant === "outlined" ? "#CCD2DE" : "inherit",
        "&:hover": {
          backgroundColor: variant === "contained" ? "#E65500" : "transparent",
          boxShadow: "none",
          borderColor: variant === "outlined" ? "#a2a4a8" : "inherit",
          transition: "width 0.5s ease-in-out",
        },
        "&.Mui-disabled": {
          color: variant === "contained" ? "white" : "default",
          backgroundColor: variant === "contained" ? "#e69d73" : "transparent",
        },
        ...styles,
      }}
    >
      {loading ? (
        <LoadingSlides bgColor={variant === "outlined" ? "#181818" : "white"} />
      ) : (
        title
      )}
    </Button>
  );
};

export default BaseButton;
