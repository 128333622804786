import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

// Assets
import { ReactComponent as CrossIcon } from "../../../../../assets/Images/CrossIcon.svg";
import DurationFilter from "./DurationFilter";
import { useDispatch, useSelector } from "react-redux";
import CallStatusFilter from "./CallStatusFilter";
import FilterFooter from "./FilterFooter";
import {
  getAllCampaignDetailsAction,
  getCampaignDetailsAction,
} from "../../../ProjectSection/CampaignSection/Store/Campaign.action";
import { PAGE_SIZE_50 } from "../../../CommonComponents/Common";

const updateFilter = (filterArray, tag) => {
  return filterArray.includes(tag)
    ? filterArray.filter((item) => item !== tag)
    : [...filterArray, tag];
};

const FilterBody = ({ obj, all = false, toggle }) => {
  const {
    AccountReducer: { constantData },
    ProjectReducer: { projectList },
  }: any = useSelector((state) => state);

  const dispatch = useDispatch();

  const {
    mainState,
    setMainState,
    campaignRuns,
    projectId,
    campaignid,
    searchText,
    inBound,
    live,
  } = obj;
  const { durationFilter, statusFilter, outComefilter, showCreateButton } =
    mainState;

  const [showAll, setShowAll] = useState(false);
  const [state, setState] = useState({
    outComeArr: [],
    loading: false,
    error: false,
    saveFilter: false,
  });
  const { outComeArr, loading, error, saveFilter } = state;

  let arraMapped: any = [
    {
      title: "Filter Calls with duration",
      innerMap: ["0-15 sec", "15-30 sec", "30-45 sec", "45+ sec", "Custom"],
      valueAssigned: "durationFilter",
      title1: "Duration",
    },
  ];
  if (outComeArr?.length > 0) {
    arraMapped = [
      {
        title: "Filter Calls with outcome",
        isObject: true,
        innerMap: outComeArr,
        valueAssigned: "outComefilter",
        title1: "outcome",
      },
      ...arraMapped,
    ];
  }
  const allCallStatus = constantData?.callStatus;

  const onChange = useCallback(
    (name, value) => {
      setMainState((prev) => {
        if (name === "outComefilter") {
          return {
            ...prev,
            outComefilter: updateFilter(outComefilter, value.tag),
          };
        }

        if (name === "statusFilter") {
          return {
            ...prev,
            statusFilter: updateFilter(statusFilter, value),
          };
        }

        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [setMainState, outComefilter, statusFilter]
  );

  const clearFilter = async (val) => {
    localStorage.removeItem("campaignDetailsFilters");
    setMainState((prev) => ({
      ...prev,
      pageIndex: 1,
      filterDrawer: false,
      durationFilter: "",
      outComefilter: [],
      statusFilter: [],
      min: "",
      max: "",
      searchText: "",
    }));
    const obj1 = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: live ? live : false,
      duration: "",
      searchText: "",
      callStatus: [],
      outcome: [],
    };

    if (all) {
      await dispatch(
        getAllCampaignDetailsAction(campaignid, { ...obj1, isDocsOnly: true })
      );
      await dispatch(
        getAllCampaignDetailsAction(campaignid, { ...obj1, isDocsOnly: false })
      );
    } else {
      const campaignsCount: any = await dispatch(
        getCampaignDetailsAction(projectId, campaignRuns, {
          ...obj1,
          count: true,
        })
      );
      const res: any = await dispatch(
        getCampaignDetailsAction(projectId, campaignRuns, obj1)
      );
      if (res?.data) {
        setMainState((prev) => ({
          ...prev,
          campaignRunListData: res?.data,
        }));
      }

      if (campaignsCount?.countsData?.length > 0) {
        let countsData = {};

        for (const status of campaignsCount?.countsData) {
          countsData[status._id] = status.count;
        }
        setMainState((prev) => ({
          ...prev,
          countObj: countsData,
        }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const customDuration =
      durationFilter === "Custom" ? `${mainState?.min}-${mainState?.max}` : "";

    const appliedFilters = {
      outComefilter,
      durationFilter,
      statusFilter,
      customFilter: customDuration,
    };
    localStorage.setItem(
      "campaignDetailsFilters",
      JSON.stringify(appliedFilters)
    );

    if (
      durationFilter === "Custom" &&
      (!mainState.min?.trim() || !mainState.max?.trim())
    ) {
      setState((prev) => ({
        ...prev,
        error: true,
        saveFilter: false,
      }));
      return false;
    }

    setState((prev) => ({ ...prev, loading: true }));

    const requestObj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: live ? live : false,
      duration:
        durationFilter === "Custom"
          ? customDuration
          : durationFilter?.split(" ")[0],
      searchText,

      callStatus: statusFilter,
      outcome: outComefilter,
      callType: inBound ? "INBOUND" : "",
    };

    if (all) {
      await dispatch(
        getAllCampaignDetailsAction(campaignid, {
          ...requestObj,
          isDocsOnly: true,
        })
      );
      await dispatch(
        getAllCampaignDetailsAction(campaignid, {
          ...requestObj,
          isDocsOnly: false,
        })
      );
    } else {
      const campaignsCount: any = await dispatch(
        getCampaignDetailsAction(projectId, campaignRuns, {
          ...requestObj,
          count: true,
        })
      );
      const res: any = await dispatch(
        getCampaignDetailsAction(projectId, campaignRuns, requestObj)
      );
      if (res?.data) {
        setMainState((prev) => ({
          ...prev,
          campaignRunListData: res?.data,
        }));
      }

      if (campaignsCount?.countsData?.length > 0) {
        let countsData = {};

        for (const status of campaignsCount?.countsData) {
          countsData[status._id] = status.count;
        }
        setMainState((prev) => ({
          ...prev,
          countObj: countsData,
        }));
      }
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
    setMainState((prev) => ({
      ...prev,
      showCreateButton: true,
      filterDrawer: false,
      pageIndex: 1,
    }));
    localStorage.setItem("showCreateButton", showCreateButton);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }}
    >
      <Box sx={{ minWidth: "353px", padding: "23px 23px 0 23px" }}>
        <Box className="flex-between" gap="10px">
          <Box component={"span"} className="font-14-600-grey">
            Apply Filters
          </Box>
          <IconButton onClick={() => toggle("filterDrawer")}>
            <CrossIcon />
          </IconButton>
        </Box>
        <Typography className="font-12-lightgrey mt-2">
          Filter Table Data
        </Typography>

        <DurationFilter
          arraMapped={arraMapped}
          mainState={mainState}
          setMainState={setMainState}
          durationFilter={durationFilter}
          outComefilter={outComefilter}
          onChange={onChange}
          allCallStatus={allCallStatus}
        />

        <CallStatusFilter
          statusFilter={statusFilter}
          onChange={onChange}
          allCallStatus={allCallStatus}
          showAll={showAll}
          setShowAll={setShowAll}
        />

        <FilterFooter
          mainState={mainState}
          setMainState={setMainState}
          loading={loading}
          clearFilter={clearFilter}
          applyFilter={handleApplyFilter}
        />
      </Box>
    </Box>
  );
};

export default FilterBody;
