import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectAccess,
  LoadingDots,
  LoadingSlides,
  PAGE_SIZE_50,
} from "../CommonComponents/Common";
import { lastCampaignActionList } from "../ProjectSection/CampaignSection/Store/Campaign.action";
import {
  getDashboardChartAction,
  getDashboardDataAction,
} from "../Store/Account/Account.action";
import BarChart from "./BarChart";
import CampaignList from "./CampainList";
import DoughnutChart from "./DoughnutChart";
import Heading from "./Heading";
import SummaryCard from "./SummaryCard";
import "./dashboard.css";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import { useLocation } from "react-router-dom";

interface CampaignData {
  campaignName: string;
  lastCampaignRun: string;
  projectName: string;
  completed: boolean;
  redial: boolean;
  suspended: boolean;
  _id: string;
}

const ProjectDashboard = () => {
  const dispatch = useDispatch();
  const {
    AccountReducer: { accountRoles, dashboardData, chartData, selectedAccount },
  } = useSelector((state: any) => state);

  const loc = useLocation();
  const projectId = loc.pathname.split("/")[3];

  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [totalCampaignsCount, setTotalCampaignsCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastCampaignListData, setLastCampaignListData] =
    useState<CampaignData[]>();
  const [state, setState] = useState({
    projectId: projectId,
    from: "",
    to: "",
  }) as any;

  const [filterState, setFilterState] = useState({
    dateRange: [
      state?.from ? new Date(state?.from) : null,
      state?.to ? new Date(state?.to) : null,
    ],
  }) as any;

  useEffect(() => {
    setLoading(true);
    fetchData(state);
  }, []);

  const fetchData = async (params = state) => {
    await dispatch(getDashboardDataAction(params));
    await dispatch(getDashboardChartAction(params));

    getCampaigns(params);
    setLoading(false);
    setRefreshLoading(false);
  };

  const getCampaigns = async (params) => {
    const campaignParams = {
      ...params,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
    };

    const res: any = await dispatch(lastCampaignActionList(campaignParams));
    const lastCampaigns = res?.[0];

    setLastCampaignListData(lastCampaigns?.data || []);
    setTotalCampaignsCount(lastCampaigns?.totalCount);

    if (lastCampaigns?.data?.length > 0) {
      const uniqueProjectIds = Array.from(
        new Set(lastCampaigns.data.map((row) => row.projectId))
      );
      for (const projectId of uniqueProjectIds) {
        getProjectAccess(projectId, accountRoles, dispatch);
      }
    }
    setTableLoading(false);
  };

  const applyFilters = (refresh = false) => {
    const [newStartDate, newEndDate] = filterState?.dateRange;
    if (newStartDate && !newEndDate) {
      swalWarningMessage("Please choose the end date");
      return;
    }

    refresh ? setRefreshLoading(true) : setLoading(true);

    const updated = {
      projectId: projectId,
      from: newStartDate ? newStartDate.toISOString() : "",
      to: newEndDate ? newEndDate.toISOString() : "",
    };
    setState((prev) => ({
      ...prev,
      ...updated,
    }));
    fetchData(updated);
  };

  const resetFilters = async () => {
    setRefreshLoading(true);
    const params = {
      projectId: projectId,
      from: "",
      to: "",
    };
    fetchData(params);

    setFilterState((prev) => ({
      ...prev,
      selectedprojectId: projectId,
      dateRange: [null, null],
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setTableLoading(true);
    getCampaigns(state);
  };

  const { totalCost, balance, remainingMinutes, onHoldBalance } = dashboardData;

  const summaryCards = [
    {
      title: "Total Cost",
      circleColor:
        "linear-gradient(180deg, rgba(22, 200, 199, 0.8) 0%, rgba(106, 233, 233, 0.8) 100%)",
      value: balance ? `$ ${balance}` : <LoadingDots />,
      errorMessage: selectedAccount?.balanceExpired,
    },
    {
      title: "Consumed Cost",
      circleColor: "linear-gradient(268.11deg, #FFDA8F 9.11%, #EEB644 105.62%)",
      value: totalCost ? `$ ${totalCost}` : <LoadingDots />,
    },
    {
      title: "Remaining mins",
      circleColor: "linear-gradient(60.26deg, #79EE81 15.53%, #26C231 89.39%)",
      value: remainingMinutes,
    },
    {
      title: "On Hold Balance",
      circleColor: "linear-gradient(180deg, #8FA8FF 0%, #4265E2 100%)",
      value: onHoldBalance ? `$ ${onHoldBalance}` : <LoadingDots />,
    },
  ];

  return (
    <Box height={100} position={"relative"}>
      {loading ? (
        <Box sx={styles.loadingContainer}>
          <LoadingSlides bgColor="#FF5E00" />
        </Box>
      ) : (
        <Box p={1}>
          <Heading
            filterState={filterState}
            setFilterState={setFilterState}
            resetFilters={resetFilters}
            applyFilters={applyFilters}
            refreshLoading={refreshLoading}
          />
          <Grid mt={0.25} mb={2} container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {summaryCards.map(
                  ({ title, circleColor, value, errorMessage }, index) => (
                    <Grid item xs={12} md={3} key={index}>
                      <SummaryCard
                        title={title}
                        circleColor={circleColor}
                        value={value}
                        errorMessage={errorMessage}
                      />
                    </Grid>
                  )
                )}
                <Grid item xs={12}>
                  <BarChart chartData={chartData} state={state} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <DoughnutChart chartData={dashboardData} />
            </Grid>
          </Grid>

          <CampaignList
            lastCampaignListData={lastCampaignListData}
            totalCampaigns={totalCampaignsCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            tableLoading={tableLoading}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProjectDashboard;

const styles = {
  loadingContainer: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
};
