import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import "./styles/sb-admin-2.min.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { AccountRoute } from "./common/components/AccountRoute";
import NewLogin from "./components/NewLogin/NewLogin";
import Routes from "./components/NewDevelopment/Home/Routes";
import { PrivateRoute1 } from "./common/components/PrivateRoute1";
import Register1 from "./components/NewLogin/Register";
import AssignName from "./components/NewDevelopment/AccountSection/AssignName";
import ResetPassword from "./components/NewLogin/ResetPassword";
import VerifyPassword from "./components/NewLogin/VerifyPassword";
import DirectLogin from "./components/NewLogin/DirectLogin";
import InviteAccountVerfication from "./components/NewDevelopment/AccessManger/InviteAccountVerfication";
import { useDispatch, useSelector } from "react-redux";
import { getWhitelabelAction } from "./components/NewDevelopment/Store/Account/Account.action";
import { Helmet } from "react-helmet";
import {
  LoadingDotsSpin,
  isVodexLoggedin,
} from "./components/NewDevelopment/CommonComponents/Common";
import OnboardingPage from "./components/NewDevelopment/LoginRegister/OnboardingPage";
import SiteUnavailable from "./components/SiteUnavailable";
import AuthLayout from "./components/NewDevelopment/auth/AuthLayout";
import NewLoginPage from "./components/NewDevelopment/auth/Login";
import SignUp from "./components/NewDevelopment/auth/Signup";
import NewResetPassword from "./components/NewDevelopment/auth/ResetPassword";
import ForgotPassword from "./components/NewDevelopment/auth/ForgotPassword";
import MainLayout from "./components/NewDevelopment/Home/NewLayout/MainLayout";
import AssignAccountName from "./components/NewDevelopment/auth/AssignAccountName";
import AccountVerfication from "./components/NewDevelopment/auth/AccountVerfication";
import RedirectConnectionPage from "./components/NewDevelopment/Integration/RedirectConnectionPage";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { whitelabelDetails, fetched },
  } = reducer;
  const [state, setState] = useState({
    documentTitle: "",
    documentContent: "",
    faviconIcon: "",
  });
  const { documentContent, documentTitle, faviconIcon } = state;
  const isVodex = isVodexLoggedin();

  useEffect(() => {
    const init = async () => {
      await dispatch(getWhitelabelAction());
    };
    init();
  }, []);

  useEffect(() => {
    if (fetched) {
      setState((prev) => ({
        ...prev,
        documentTitle: whitelabelDetails?.documentTitle || "Vodex",
        documentContent: whitelabelDetails?.documentTitle || "Vodex",
        faviconIcon:
          whitelabelDetails?.faviconLink ||
          "https://firebasestorage.googleapis.com/v0/b/lilchirp-io.appspot.com/o/favicon.ico?alt=media&token=9033a42c-ace9-47d2-afbf-5067bd2f2b43",
      }));
    }
  }, [whitelabelDetails]);

  return (
    <div className="App">
      <Helmet>
        <title>{documentTitle}</title>
        <meta name="description" content={documentContent} />
        <link rel="icon" href={faviconIcon} />
        {/* <meta
          http-equiv="Content-Security-Policy"
          content={`default-src 'self' ${process.env.REACT_APP_BACKEND_CSP} ; script-src 'self' 'unsafe-inline' https://apis.google.com; style-src 'self' https://firebasestorage.googleapis.com https://fonts.googleapis.com/ 'unsafe-inline'; connect-src 'self' ${process.env.REACT_APP_BASE_URL_AI} ${process.env.REACT_APP_BACKEND_CSP}; img-src 'self' https://firebasestorage.googleapis.com data:`}
        /> */}
      </Helmet>
      {whitelabelDetails?.expired === true ? (
        <SiteUnavailable />
      ) : (
        <Router>
          <Suspense fallback={<LoadingDotsSpin />}>
            <Switch>
              <Route path="/redirect-to-vodex">
                <RedirectConnectionPage />
              </Route>
              <Route path={`/account-name`}>
                <AssignAccountName />
              </Route>
              <Route path="/login/api_auth_key/api_gh_key">
                <DirectLogin />
              </Route>
              <Route path={`/verify-email`}>
                <AccountVerfication />
              </Route>
              <Route path={`/verify-invite`}>
                <InviteAccountVerfication />
              </Route>
              {/* <PrivateRoute1 path="/account">
                <Routes />
              </PrivateRoute1> */}
              <Route path="/onboarding">
                <OnboardingPage />
              </Route>
              <Route path={"/account"}>
                <MainLayout />
              </Route>
              <Route path="/register">
                {isVodex ? (
                  <AuthLayout>
                    <SignUp />
                  </AuthLayout>
                ) : (
                  <Register1 />
                )}
              </Route>
              <Route path="/change-password">
                {isVodex ? (
                  <AuthLayout>
                    <NewResetPassword />
                  </AuthLayout>
                ) : (
                  <VerifyPassword />
                )}
              </Route>
              <Route path="/reset-password">
                {isVodex ? (
                  <AuthLayout>
                    <ForgotPassword />
                  </AuthLayout>
                ) : (
                  <ResetPassword />
                )}
              </Route>

              <AccountRoute path="/">
                {isVodex ? (
                  <AuthLayout>
                    <NewLoginPage />
                  </AuthLayout>
                ) : (
                  <NewLogin />
                )}
              </AccountRoute>
            </Switch>
          </Suspense>
        </Router>
      )}
    </div>
  );
};

export default App;
