import React, { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch, useSelector } from "react-redux";
import "../../../../styles/callStatus.css";
import Pagination from "@mui/material/Pagination";
import * as FileSaver from "file-saver";
import {
  Button,
  Form,
  FormControl,
  OverlayTrigger,
  Popover,
  Tooltip
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import TopCard from "../../../../common/components/TopCard";
import { shortenString } from "../../../../Constant/helper";
import SkeletonLodingWithCards from "../../../../Constant/SkeletonLoding";
import { converTime, isEmpty, PAGE_SIZE_50, renderThumb } from "../../CommonComponents/Common";
import CommonDrawer from "../../CommonComponents/CommonDrawer";
import { getAllCampaignDetailsAction } from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import FilterDrawerBody from "../CampaignDetails/FilterDrawerBody";
import InsightsViewModal from "../CampaignDetails/InsightsViewModal";
import SummaryModel from "../CampaignDetails/SummaryModal";

function AllCampaignRuns(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  let campaignDetails: any = localStorage.getItem("campaign");
  let data = JSON.parse(campaignDetails);
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let campaignRuns = locSearch.get("campaignRuns");
  let campaignId = locSearch.get("campaign-id");
  let count = 0;
  const [loading, setLoading] = useState(false);

  const reducer: any = useSelector((state) => state);

  const {
    AccountReducer: { constantData, userDetails: { email } },
    CampaignReducer: {
      allcampaignsDetails,
      allcampaignsDetailsCounts,
      allcampaignsDetailsTotalCounts,
    },
  } = reducer;

  const [state, setState] = useState<any>({
    CALL_STATUS: constantData?.callStatus,
    campaignRunListData: [],
    showCreateButton: false,
    exportSpin: false,
    newCampaignData: [],
    summaryModal: false,
    recordingUrl: "",
    selectedCampaignRun: "",
    //newwwwww
    filterDrawer: false,
    countObj: {},
    insightsPopup: false,
    insightsPopupView: false,
    trackEnabledRecording: [],
    pageIndex: Number(localStorage.getItem("allcampaignrunspageIndex")) || 1,
    hasMore: false,

    durationFilter: "",
    statusFilter: [],
    outComefilter: [],
    searchText: "",
    min: "",
    max: "",
  });
  const {
    campaignRunListData,
    showCreateButton,
    exportSpin,
    newCampaignData,
    summaryModal,

    //new

    filterDrawer,
    countObj,
    insightsPopup,
    insightsPopupView,
    trackEnabledRecording,
    pageIndex,
    hasMore,
    durationFilter,
    statusFilter,
    outComefilter,
    CALL_STATUS,
    searchText,
  } = state;

  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterdata, setFilterData] = useState<any>([]);

  const {
    AccountReducer: {selectedAccount},
    RecordingReducer: { recordings },
  } = reducer;

  let callFn = async () => {
    if (count === 0) {
      setLoading(true);
      count++;
    }
    let obj = {
      pageIndex: pageIndex,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      searchText: searchText,
    };

    await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: true }));
    setLoading(false);
    await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: false }));
    
    setState((prev) => ({
      ...prev,
      hasMore: true,
    }));    
  };

  useEffect(() => {
    callFn();
  }, [dispatch]);

  useEffect(() => {
    if (allcampaignsDetails) {
      setState((prev) => ({
        ...prev,
        campaignRunListData: allcampaignsDetails,
      }));
    }
  }, [allcampaignsDetails]);
  useEffect(() => {
    if (allcampaignsDetailsCounts?.length > 0) {
      let countsData = {};

      for (const status of allcampaignsDetailsCounts) {
        countsData[status._id] = status.count;
      }
      setState((prev) => ({
        ...prev,
        countObj: countsData,
      }));
    }
  }, [allcampaignsDetailsCounts]);
  useEffect(() => {
    if (allcampaignsDetailsTotalCounts > allcampaignsDetails?.length) {
      setState((prev) => ({
        ...prev,
        hasMore: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        hasMore: false,
      }));
    }
  }, [allcampaignsDetails, allcampaignsDetailsCounts]);
  useEffect(() => {
    if (recordings?.length > 0) {
      let arr: any = [];
      for (const record of recordings) {
        if (record?.track === true) {
          arr.push({
            name: record.recordingName,
            tag: record.tag,
          });
        }
      }
      setState((prev) => ({
        ...prev,
        trackEnabledRecording: arr,
      }));
    }
  }, [recordings]);

  const toggle = (name = "summaryModal") => {
    setState((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const getOutcome = (data) => {
    let trackResult: any = [];
    if (data?.outComes) {
      data.outComes.forEach((da) => {
        let kl = trackEnabledRecording.find((kl) => kl.tag === da);
        if (kl) {
          trackResult.push(kl.name);
        }
      });

      trackEnabledRecording?.forEach((track) => {
        if (data.outComes.includes(track.tag)) {
          trackResult.push(track.name);
        }
      });
      return trackResult.length > 0 ? trackResult[trackResult?.length - 1] : "";
    } else {
      return "";
    }
  };
  const getName = (phone) => {
    return `${phone?.customParameters?.firstName || "No Name"} ${phone?.customParameters?.lastName || ""
      }`;
  };

  const openModal = (selectedCampaignRun) => {
    setState((prev) => ({
      ...prev,
      summaryModal: true,
      recordingUrl: selectedCampaignRun.recordingUrl,
      selectedCampaignRun: selectedCampaignRun,
    }));
  };

  const popover = (data) => {
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Call Information</Popover.Title>
        <Popover.Content>
          Call Reference Id : <strong>{data}</strong>
        </Popover.Content>
      </Popover>
    );
  };
  const productElementsAlreadyRun: (JSX.Element | null)[] =
    campaignRunListData?.map((campaign: any, idx) => {
      const { callStatus } = campaign;
      return (
        <tr key={idx}>
          <th scope="row">{idx + 1}</th>
          <td>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-disabled"> {getName(campaign)}</Tooltip>
              }
              rootClose={true}
              rootCloseEvent={"click"}
              onExit={() => {
                // console.log("cliii");
              }}
            >
              <span className="d-inline-block">
                <span style={{ pointerEvents: "none" }}>{campaign.phone} </span>
              </span>
            </OverlayTrigger>
          </td>
          <td style={{ width: "120px" }}>
            {/* <span
              className="call-status"
              style={{
                backgroundColor: constantData?.callStatusColour[callStatus]
                  ? constantData?.callStatusColour[callStatus]
                  : "red",
              }}
            >
              {!isEmpty(CALL_STATUS) && CALL_STATUS[callStatus]}
            </span> */}
            <button
              style={{
                backgroundColor:
                  !isEmpty(constantData) &&
                    constantData?.callStatusColour[callStatus]
                    ? constantData?.callStatusColour[callStatus]
                    : "red",
                cursor: "default",
                pointerEvents: "none",
                minWidth: "140px",
                maxWidth: "140px",
                color: callStatus === "701" ? "black" : "white",
              }}
              className="btn btn-info mr-1 no-padding"
            >
              {!isEmpty(CALL_STATUS) &&
                shortenString(CALL_STATUS[callStatus], 25)}
            </button>
          </td>
          <td>
            <div className="d-flex">
              {trackEnabledRecording?.length > 0 && getOutcome(campaign)}
              {campaign?.call_uuid && (
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover(campaign?.call_uuid)}
                  rootClose={true}
                  rootCloseEvent={"click"}
                  onExit={() => { }}
                >
                  <span className="d-inline-block">
                    <span
                      className={`fas fa-info ml-3 `}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                    </span>
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </td>
          <td>{selectedAccount?.params?.pricesWaivers ? (
            "--"
          ) : campaign?.newCost || "--"}</td>
          <td>
            <button
              disabled={callStatus !== "703"}
              className="btn btn-info no-padding"
              onClick={() => openModal(campaign)}
            >
              Summary
            </button>
          </td>

          <td className="tableRightCol">
            <ReactAudioPlayer
              src={
                campaign?.callRecordingUrl ||
                `https://storage.googleapis.com/botstream-recordings/${campaign?.call_uuid}.mp3`
              }
              controls
              style={{
                minWidth: "40px",
                height: "30px",
              }}
            />
          </td>
          <td>{converTime(campaign?.createdAt)}</td>
        </tr>
      );
    });

  const ExportCSV = async () => {
    setState((prev) => ({
      ...prev,
      exportSpin: true,
    }));
    let send;
    if (filterdata.length > 0) {
      send = filterdata;
    }
    if (filterName !== "") {
      send = filterName;
    }

    let obj = {
      pageIndex: 1,
      pageSize: 1000,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      searchText: searchText,
      isDocsOnly: true
    };
    let res: any = await dispatch(getAllCampaignDetailsAction(campaignId, obj));

    let matchArr = [
      "mandatory_params",
      "firstName",
      "lastName",
      "FirstName",
      "LastName",
      "phone",
      "PhoneNum",
    ];
    if (res) {
      let arr: any = [];
      res.forEach((kl) => {
        let copy = kl?.customParameters;
        let obj: any = {};
        obj["First Name"] = copy?.FirstName || "--";
        obj["Last Name"] = copy?.LastName || "--";
        obj["Phone number"] = kl?.phone || "--";
        obj["Calling Status"] = CALL_STATUS[kl.callStatus] || "";
        obj["Call UUID"] = kl?.call_uuid || "";
        obj["Cost"] = kl?.newCost || "";
        if (selectedAccount?.params?.pricesWaivers) {
          obj["Cost"] = "--"
        }
        obj["Recording Url"] = kl?.callRecordingUrl || "";
        if (!isEmpty(copy)) {
          for (const customKeys in copy) {
            if (!matchArr.includes(customKeys)) {
              obj[customKeys] = copy[customKeys];
            }
          }
        }

        arr.push(obj);
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(arr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Campaign Report ` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };

  const topCards = () => {
    return (
      <div className="row">
        <TopCard
          title="Total Calls Failed"
          text={`${countObj["704"] || 0}`}
          icon="warehouse"
          class="danger"
        />
        <TopCard
          title="Total Calls Connected"
          text={`${countObj["703"] || 0}`}
          icon="box"
          class="primary"
        />
        <TopCard
          title="Total Calls Made"
          text={`${allcampaignsDetailsTotalCounts || 0}`}
          icon="phone-volume"
          class="success"
        />
      </div>
    );
  };

  const obj = {
    projectId,
    campaignRuns,
    setMainState: setState,
    trackEnabledRecording,
    mainState: state,
    campaignId,
  };

  const handlePageNextInScroll = async (index) => {
    let obj = {
      pageIndex: index,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      isScrolled: false,
      searchText: searchText,
    };
    await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: true }));    
    setState((prev) => ({
      ...prev,
      pageIndex: index,
    }));
    localStorage.setItem("allcampaignrunspageIndex", index);
  };

  return loading ? (
    <SkeletonLodingWithCards count={3} />
  ) : (
    <div>
      {topCards()}
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4" style={{ minHeight: "600px" }}>
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Campaign List</h6>

              <div className="header-buttons">
                <button
                  className="custom-button-icon"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      insightsPopupView: true,
                    }))
                  }
                >
                  View Insights
                </button>
                <button
                  disabled={
                    allcampaignsDetailsTotalCounts > 0 ? exportSpin : true
                  }
                  className="custom-button-icon"
                  onClick={ExportCSV}
                  title="Export Excel"
                >
                  {exportSpin ? (
                    <span className="loading">...</span>
                  ) : (
                    <i className="fas fa-file-export"></i>
                  )}
                </button>
                <button
                  className="custom-button-icon"
                  onClick={() => {
                    toggle("filterDrawer");
                  }}
                  title="Filter Calls"
                >
                  <i className="fas fa-filter"></i>
                </button>
                <button
                  className="custom-button-icon"
                  onClick={() => {
                    history.push(
                      `/account/project/${projectId}/campaign-runs?campaignid=${campaignId}`
                    );
                  }}
                >
                  <i className="fas fa fa-arrow-left"></i>
                </button>
                &nbsp;
              </div>
              <hr className="mt-4" />

              <Form inline onSubmit={callFn}>
                <FormControl
                  type="number"
                  style={{ height: "33px" }}
                  placeholder="Search by phone number"
                  className="mr-sm-2 "
                  onChange={(e) => {
                    e.persist();
                    setState((prev) => ({
                      ...prev,
                      searchText: e.target.value,
                    }));
                  }}
                  value={searchText}
                />
                <Button type="submit" variant="success" className="no-padding">
                  {" "}
                  <i className="fas fa-search"></i> Search
                </Button>
              </Form>
            </div>
            <div className="card-body">
              <Scrollbars
                style={{
                  height: "85vh",
                  color: "white",
                  borderRadius: "10px",
                }}
                renderThumbVertical={renderThumb}
                autoHide={true}
              >
                <table
                  className="table"
                  style={{ textAlign: "center" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col" style={{ width: "200px" }}>
                        Calling Status
                      </th>
                      <th scope="col">
                        {trackEnabledRecording?.length > 0
                          ? "Outcomes"
                          : "UUID"}
                      </th>
                      <th scope="col">Call Cost</th>

                      <th scope="col">Call Summary</th>

                      <th className="tableRightCol">
                        Listen Call Recording
                      </th>

                      <th>
                        {shortenString("Campaign Run Time", "10")}{" "}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {campaignRunListData?.length > 0 ? (
                      productElementsAlreadyRun
                    ) : (
                      <tr className="text-center">
                        <td colSpan={7}>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Scrollbars>
              <div className="card-footer float-left d-flex">
              {
                <Pagination
                  count={Math.ceil(Number(allcampaignsDetailsTotalCounts) / PAGE_SIZE_50)}
                  onChange={(e, v) => {
                    handlePageNextInScroll(v);
                  }}
                  page={Number(pageIndex)}
                  variant="outlined"
                  shape="rounded"
                />
              }
            </div>
            </div>
          </div>
        </div>
      </div>

      {filterDrawer && (
        <CommonDrawer
          toggle={() => toggle("filterDrawer")}
          html={<FilterDrawerBody obj={obj} all={true} />}
          open={filterDrawer}
        />
      )}

      {summaryModal && (
        <SummaryModel
          state={state}
          setState={setState}
          show={summaryModal}
          toggle={() => toggle("summaryModal")}
        />
      )}

      {insightsPopupView && (
        <InsightsViewModal
          insightsPopup={insightsPopupView}
          toggle={() =>
            setState((prev) => ({ ...prev, insightsPopupView: false }))
          }
          campaignRunListData={campaignRunListData}
          campaignName={""}
          all={true}
        />
      )}
    </div>
  );
}

export default AllCampaignRuns;
