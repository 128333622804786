import React, { Dispatch, useEffect } from "react";
import Select from "react-select";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../CommonComponents/Common";
import {
  getCallSettingAction,
  getHealthCheckServiceAction,
  saveCallSettingsAction,
} from "../../ProjectSection/CallSettings/Store/callSetting.action";
import BaseButton from "../../Common/Buttons/BaseButton";
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CommonModal from "../../CommonComponents/Modal/CommonModal";

const selectStyle = (val) => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: val !== 1 ? "#D72917" : "skyblue",
      boxShadow:
        val !== 1
          ? state.isFocused
            ? "0 0 0 0.5px #D72917"
            : "none"
          : state.isFocused
          ? "0 0 0 0.5px skyblue"
          : "none",
      "&:hover": {
        borderColor: val !== 1 ? "#D72917" : "skyblue",
      },
    }),
  };
};

const SVG = {
  warning: (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00061 6.63525V10.2176"
        stroke="#D72917"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00061 15.5266H3.6588C1.17265 15.5266 0.133765 13.7498 1.33744 11.5789L3.57282 7.5523L5.67925 3.76934C6.95456 1.46947 9.04666 1.46947 10.322 3.76934L12.4284 7.55947L14.6638 11.586C15.8675 13.7569 14.8214 15.5338 12.3424 15.5338H8.00061V15.5266Z"
        stroke="#D72917"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9967 12.3672H8.00314"
        stroke="#D72917"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  cancel: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#D72917" />
      <path
        d="M7.94043 16.7432L9.27606 15.0534L15.8036 6.9991"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2133 16.511L14.7355 14.9441L7.53075 7.48934"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  success: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#00B050" />
      <path
        d="M6.87305 12.8438L9.83598 15.8067L16.3636 7.75237"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};

const HealthCheck = ({ loading }) => {
  const [toggle, setToggle] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const reducer: any = useSelector((state) => state);
  const { projectId } = useParams<{ projectId: string }>();
  const VODEX_OWNED_GATEWAYS = ["vodex_twilio", "vodex_voice", "vodex_plivo"];
  const {
    AccountReducer: { constantData },
    ProjectReducer: { selectedProject },
    CallSettingReducer: { callSettings, healthCheckStatus },
  } = reducer;
  const history = useHistory();

  const [state, setState] = useState({
    callerIdNumber: "",
    callTransferNumber: "",
    disconnectedTime: "",
    asrEngine: "",
    gateway: "",
    responseFormat: selectedProject?.botPlatform?.includes("recording")
      ? "recording"
      : "TTS",
    voice: "",
    languageCode: "",
    interrupt: 0,
    voiceMailDetection: true,
    silentThreshold: "3",
    temperature: "0.2",
    pauseBetweenSentence: "0",
    transferWebhook: "",
    checkUser: false,
    checkDnc: false,
    checkDnd: false,
    model: "001",
    transferGateway: "",
    ringingDisconnect: "",
    transferType: "bridge_call",
    insightsWebhook: "",
    stream: false,
  });
  const [healtCheckState, setHealthCheckState] = useState({
    asrVal: 0,
    modelVal: 0,
    ttsVal: 0,
  });
  const [showValues, setShowValues] = useState({
    asrShow: false,
    voiceShow: false,
    modelShow: false,
  });
  const [isExpand, setIsExpand] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const { asrShow, voiceShow, modelShow } = showValues;
  const { asrVal, modelVal, ttsVal } = healtCheckState;
  const [isLoading, setIsLoading] = useState(false);

  const [constantArray, setConstantArray] = useState({
    asrEngines: [],
    botPlatforms: [],
    gateways: [],
    responseFormats: [],
    serviceProviders: [],
    voices: [],
    silentThresholds: [],
    temperatures: [],
    pauseBetweenOptions: [],
    modelOptions: [],
    interruptOptions: [],
    languageCodes: [],
  });

  const {
    asrEngines,
    gateways,
    responseFormats,
    voices,
    silentThresholds,
    temperatures,
    pauseBetweenOptions,
    modelOptions,
    interruptOptions,
    languageCodes,
  } = constantArray;

  const {
    callTransferNumber,
    callerIdNumber,
    disconnectedTime,
    asrEngine,
    gateway,
    responseFormat,
    voice,
    languageCode,
    interrupt,
    voiceMailDetection,
    silentThreshold,
    temperature,
    pauseBetweenSentence,
    transferWebhook,
    checkUser,
    checkDnc,
    checkDnd,
    model,
    ringingDisconnect,
    transferGateway,
    insightsWebhook,
    transferType,
    stream,
  } = state;

  useEffect(() => {
    if (!isEmpty(callSettings)) {
      let localCallSetting = callSettings;
      if (typeof localCallSetting?.interrupt === "boolean") {
        if (localCallSetting?.interrupt === true) {
          localCallSetting["interrupt"] = "1";
        } else {
          localCallSetting["interrupt"] = "0";
        }
      }
      setState((prev) => ({
        ...prev,
        ...localCallSetting,
      }));
    }
  }, [callSettings]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      await dispatch(getHealthCheckServiceAction());
      setIsLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    let voicesCopy = constantData?.voices;
    voicesCopy = voicesCopy?.map((voic) => {
      return {
        ...voic,
        isDisabled: voic.disabled,
      };
    });
    setConstantArray({ ...constantData, voices: voicesCopy });
  }, [constantData]);

  useEffect(() => {
    if (isExpand) {
      const timer = setTimeout(() => {
        setIsContentVisible(true);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setIsContentVisible(false);
    }
  }, [isExpand]);

  const saveCallSettings = async (nav) => {
    setIsLoading(true);
    let sendObj: any = {
      projectId: projectId,
      callTransferNumber,
      callerIdNumber,
      disconnectedTime,
      gateway,
      asrEngine,
      responseFormat,
      languageCode,
      interrupt,
      voiceMailDetection,
      silentThreshold,
      temperature,
      pauseBetweenSentence,
      transferWebhook,
      checkUser,
      checkDnc,
      checkDnd,
      model,
      transferGateway,
      ringingDisconnect,
      insightsWebhook,
      transferType: VODEX_OWNED_GATEWAYS.includes(gateway)
        ? "bridge_call"
        : transferType,
      stream,
    };

    if (sendObj?.interrupt === "true" || sendObj.interrupt === true) {
      sendObj["interrupt"] = "1";
    }
    if (sendObj?.interrupt === "false" || sendObj.interrupt === false) {
      sendObj["interrupt"] = "0";
    }

    await dispatch(saveCallSettingsAction(sendObj));
    await dispatch(getCallSettingAction(projectId));
    await dispatch(getHealthCheckServiceAction());
    close();
    if (nav) {
      history.push(`/account/project/${projectId}/call-settings`);
    }
    setIsLoading(false);
  };

  const healthCheckfunction = () => {
    // Getting Value of Current Selected ASR (either 1, 0 or -1)
    let asrStatus;
    if (asrEngine === "google") {
      asrStatus = 1;
    } else {
      const selectedAsrArray = healthCheckStatus?.find(
        (item) => item?.type === "asr" && item?.values?.includes(asrEngine)
      );
      asrStatus = selectedAsrArray?.value;
    }
    // Getting Value of Current Selected Model (either 1, 0 or -1)
    const selectedModelValue = healthCheckStatus?.find(
      (item) => item?.type === "model" && item?.values?.includes(model)
    );
    const modelStatus = selectedModelValue?.value;

    interface Voice {
      engine?: number;
      isDisabled?: boolean;
      name: string;
      url: string;
      value: string;
    }
    // Get the Value of Selected Voice
    let ttsStatus;
    const typeTts = healthCheckStatus?.filter((v) => v?.type === "tts");
    const selectedVoiceProvider = callSettings?.voiceData?.provider;

    const engineValue =
      typeTts
        ?.filter(
          (engine: any) =>
            (selectedVoiceProvider === "gg" && engine?.name === "Google") ||
            (selectedVoiceProvider === "11" &&
              engine?.name === "Eleven Labs") ||
            (selectedVoiceProvider === "ht" && engine?.name === "Play HT") ||
            (selectedVoiceProvider === "ms" && engine?.name === "Azure")
        )
        ?.map((engine) => engine?.value)[0] || null;

    ttsStatus = engineValue;

    return { modelStatus, asrStatus, ttsStatus };
  };

  useEffect(() => {
    const { asrStatus, ttsStatus, modelStatus } = healthCheckfunction();
    setShowValues({
      asrShow: asrStatus === 1 ? true : false,
      voiceShow: ttsStatus === 1 ? true : false,
      modelShow: modelStatus === 1 ? true : false,
    });
    setHealthCheckState((prev) => ({
      ...prev,
      asrVal: asrStatus,
      modelVal: modelStatus,
      ttsVal: ttsStatus,
    }));
  }, [healthCheckStatus, callSettings]);

  useEffect(() => {
    const { asrStatus, ttsStatus, modelStatus } = healthCheckfunction();
    setHealthCheckState((prev) => ({
      ...prev,
      asrVal: asrStatus,
      modelVal: modelStatus,
      ttsVal: ttsStatus,
    }));
  }, [voice, asrEngine, model]);

  const allServicesWorking = asrVal === 1 && modelVal === 1 && ttsVal === 1;

  const getErrorString = (asrValue, modelValue, ttsValue) => {
    const errors: string[] = [];

    if (asrValue !== 1) {
      errors.push("ASR");
    }
    if (modelValue !== 1) {
      errors.push("Model");
    }
    if (ttsValue !== 1) {
      errors.push("Voice");
    }
    return errors.length > 0 ? errors.join(", ") : null;
  };

  // Disabling ASR in Select
  const disableValues = (option) => {
    if (option?.value === "google") {
      return false;
    }
    const check = healthCheckStatus?.filter(
      (st) => st?.type === "asr" && st?.value === 1
    );
    const isWorking = check?.some((st) => st.values?.includes(option.value));
    return !isWorking;
  };

  // Disabling Model Value
  const disableModelValues = (option) => {
    const check = healthCheckStatus?.filter(
      (st) => st?.type === "model" && st?.value === 1
    );
    const isWorking = check?.some((st) => st?.values?.includes(option.value));
    return !isWorking;
  };

  // Disabling TTS values
  const disabledTtsValues = (voice) => {
    const ttsHealth = healthCheckStatus.filter((h) => h.type === "tts");

    for (let health of ttsHealth) {
      const hasEngine = voice.hasOwnProperty("engine");
      const isValueIncluded = hasEngine
        ? health.values.includes(voice.engine)
        : false;
      if (!hasEngine && health.value === 1) {
        return false;
      }
      if (isValueIncluded && health.value !== 1) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (!allServicesWorking) {
      if (!loading) {
        setIsExpand(true);
      }
    } else {
      setIsExpand(false);
    }
  }, [allServicesWorking]);

  function handleClick() {
    setToggle(true);
  }
  function close() {
    setToggle(false);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: isExpand ? "flex-start" : "center",
          width: isExpand ? 450 : 20,
          minHeight: 150,
          overflow: "hidden",
          transition: "all 0.3s ease-in-out",
          position: "absolute",
        }}
        className="health-check-card-cont"
      >
        <Box display="flex">
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => setIsExpand(!isExpand)}
              sx={{
                marginLeft: 1,
                color: "white",
                width: 4,
              }}
              title={
                !isExpand
                  ? "Click to view the status of ASR, Model, and Voice settings."
                  : ""
              }
            >
              {isExpand ? (
                <ArrowForwardIosIcon sx={{ height: 14, width: 14 }} />
              ) : (
                <ArrowBackIosIcon sx={{ height: 14, width: 14 }} />
              )}
            </IconButton>
          </Box>
          {isExpand && isContentVisible && (
            <Box ml={1}>
              {loading ? (
                <CircularProgress
                  sx={{
                    position: "relative",
                    top: "40%",
                    left: "180px",
                    color: "#FFFFFF",
                  }}
                  size={24}
                />
              ) : (
                <Box
                  py={2}
                  pr={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  height="100%"
                >
                  <Box display="flex" gap={1} alignItems="flex-start">
                    <Box>{allServicesWorking ? SVG.success : SVG.cancel}</Box>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        color="white"
                        fontSize={20}
                        fontWeight={600}
                        gutterBottom
                      >
                        {allServicesWorking ? "Health Check" : "Oops!"}
                      </Typography>
                      <Typography color="white" fontSize={16} fontWeight={500}>
                        {allServicesWorking
                          ? " Your ASR, Voice, and Model are working well."
                          : `Your ${getErrorString(
                              asrVal,
                              modelVal,
                              ttsVal
                            )} is not working. Fix with alternative.`}
                      </Typography>
                    </Box>
                  </Box>
                  {!allServicesWorking && (
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <BaseButton
                        title="Check Availability"
                        variant="contained"
                        handleClick={handleClick}
                        styles={{
                          color: "white",
                          backgroundColor: "#202020",
                          borderColor: "#2F353E",
                          fontSize: 16,
                          "&:hover": {
                            backgroundColor: "#202020",
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {toggle && (
        <CommonModal
          show={toggle}
          toggle={close}
          heading={
            <Box>
              <Box display="flex" alignItems="center" gap={1} mb={0.5}>
                {SVG.warning}
                <Typography color="#181818" fontSize={20} fontWeight={600}>
                  Fix Issues
                </Typography>
              </Box>
              <Typography color="#555555" fontSize={14} fontWeight={400}>
                Few features are not working currently. Fix it before running
                campaign.
              </Typography>
            </Box>
          }
          body={
            <Box>
              {!modelShow && (
                <Box>
                  <Typography {...styles.title}>
                    Model <span className="fix-required-star">*</span>
                  </Typography>
                  <Select
                    styles={selectStyle(modelVal)}
                    maxMenuHeight={160}
                    className="basic-single"
                    classNamePrefix="select"
                    value={modelOptions?.find(
                      (name: any) => name.value === model
                    )}
                    isDisabled={false}
                    isOptionDisabled={disableModelValues}
                    isSearchable={true}
                    options={modelOptions}
                    getOptionLabel={(e: any) => e.name}
                    onChange={async (e) => {
                      setState((prev) => ({
                        ...prev,
                        model: e.value,
                      }));
                    }}
                    placeholder="Select Model"
                  />
                  {modelVal !== 1 && (
                    <Typography
                      component="p"
                      py={1}
                      className="fix-required-star"
                    >
                      Currently this selected Model is not working. Try with
                      other Model
                    </Typography>
                  )}
                </Box>
              )}

              {/* {!voiceShow && (
                <Box>
                  <Typography {...styles.title}>
                    Voice <span className="fix-required-star">*</span>
                  </Typography>

                  <Select
                    styles={selectStyle(ttsVal)}
                    maxMenuHeight={160}
                    className="basic-single"
                    classNamePrefix="select"
                    value={voices?.find((name: any) => name.value === voice)}
                    isOptionDisabled={(e: any) => disabledTtsValues(e)}
                    isDisabled={false}
                    isSearchable={true}
                    options={voices}
                    getOptionLabel={(e: any) => e.name}
                    onChange={async (e) => {
                      setState((prev) => ({
                        ...prev,
                        voice: e.value,
                      }));
                    }}
                    placeholder="Select Voice"
                  />
                </Box>
              )} */}

              {ttsVal !== 1 && (
                <Box>
                  <Typography {...styles.title}>
                    Voice <span className="fix-required-star">*</span>
                  </Typography>

                  <Typography component="p" className="fix-required-star">
                    Currently this selected voice is not working. Try with other
                    voice.
                  </Typography>
                  <Button
                    sx={styles.button}
                    onClick={() => saveCallSettings(true)}
                  >
                    {isLoading
                      ? "Redirecting..."
                      : "Click here to Select Voice"}
                  </Button>
                </Box>
              )}

              {!asrShow && (
                <Box>
                  <Typography {...styles.title}>
                    ASR <span className="fix-required-star">*</span>
                  </Typography>

                  <Select
                    styles={selectStyle(asrVal)}
                    maxMenuHeight={180}
                    className="basic-single"
                    classNamePrefix="select"
                    value={asrEngines?.find(
                      (name: any) => name.value === asrEngine
                    )}
                    isDisabled={false}
                    isSearchable={true}
                    options={asrEngines}
                    getOptionLabel={(e: any) => e.name}
                    isOptionDisabled={disableValues}
                    onChange={async (e) => {
                      setState((prev) => ({
                        ...prev,
                        asrEngine: e.value,
                      }));
                    }}
                    placeholder="Select ASR"
                  />
                  {asrVal !== 1 && (
                    <Typography
                      component="p"
                      py={1}
                      className="fix-required-star"
                    >
                      Currently this selected ASR is not working. Try with other
                      ASR.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          }
          size={700}
          handleSave={() => saveCallSettings(false)}
          spin={isLoading}
        />
      )}
    </>
  );
};

export default HealthCheck;

const styles = {
  title: {
    color: "#181818",
    fontSize: 14,
    fontWeight: 500,
    mb: 1,
  },
  button: {
    marginTop: 0.6,
    marginBottom: 2.5,
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 2,
    textTransform: "capitalize",
    textWrap: "nowrap",
    boxShadow: "none",
    paddingX: 1.5,
    paddingY: "6.5px",
    backgroundColor: "transparent",
    border: "1px solid #B0B0B0",
    "&:hover": {
      // backgroundColor: "",
      boxShadow: "none",
      transition: "width 0.5s ease-in-out",
    },
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "transparent",
    },
  },
};
