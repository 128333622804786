import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

// Assets
import { Box } from "@mui/material";
import CallStatusFilter from "./CallStatusFilter";
import { PAGE_SIZE_50 } from "../../CommonComponents/Common";
import {
  getCallHistoryAction,
  getCallHistoryCountAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import DurationFilter from "./DurationFilter";
import FilterHeading from "./FilterHeading";
import DateProjectFilter from "./DateProjectFilter";
import moment1 from 'moment-timezone';
const FilterFooter = React.lazy(() => import("./FilterFooter"));
const SavedFilters = React.lazy(() => import("./SavedFilters"));

const updateFilter = (filterArray, tag) => {
  return filterArray.includes(tag)
    ? filterArray.filter((item) => item !== tag)
    : [...filterArray, tag];
};

function FilterBody({ obj }: any) {
  const {
    searchText,
    setMainState,
    trackEnabledRecording,
    mainState,
    startDate,
    endDate,
    currentDate,
    setDateRange,
    filterName,
    setFilterName,
    setFilterNameFromDropdown,
    setSearchTerm,
  } = obj;
  const dispatch = useDispatch();
  const timezone = moment1.tz.guess();
  
  const {
    AccountReducer: { constantData },
    ProjectReducer: { projectList },
  }: any = useSelector((state) => state);

  const allCallStatus = constantData?.callStatus;
  const [state, setState] = useState<any>({
    outComeArr: [],
    loading: false,
    saveFilter: false,
    saveDate: false,
    savedFilters: [],
  });

  const {
    durationFilter,
    statusFilter,
    outComefilter,
    selectedProjectId,
    min,
    max,
  } = mainState;
  const { outComeArr, loading, saveFilter, saveDate, savedFilters } = state;
  const [showAll, setShowAll] = useState(false);
  const [customDurationError, setCustomDurationError] = useState("");
  const [errMsg, setErrMsg] = useState("");

  let arraMapped: any = [
    {
      title: "Filter Calls with duration",
      innerMap: ["0-15 sec", "15-30 sec", "30-45 sec", "45+ sec", "Custom"],
      valueAssigned: "durationFilter",
      title1: "Duration",
    },
  ];
  if (outComeArr?.length > 0) {
    arraMapped = [
      {
        title: "Filter Calls with outcome",
        isObject: true,
        innerMap: outComeArr,
        valueAssigned: "outComefilter",
        title1: "outcome",
      },
      ...arraMapped,
    ];
  }

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      outComeArr: trackEnabledRecording,
    }));
  }, [trackEnabledRecording]);

  useEffect(() => {
    const locBool =
      localStorage.getItem("savedFilterData") === undefined
        ? "[]"
        : localStorage.getItem("savedFilterData");
    const savedFilterData = JSON.parse(locBool || "[]");
    setState((prev) => ({
      ...prev,
      savedFilters: savedFilterData,
    }));
  }, []);
    const onChange = useCallback(
    (name, value) => {
      setMainState((prev) => {
        if (name === "outComefilter") {
          return {
            ...prev,
            outComefilter: updateFilter(outComefilter, value.tag),
          };
        }

        if (name === "statusFilter") {
          return {
            ...prev,
            statusFilter: updateFilter(statusFilter, value),
          };
        }

        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [setMainState, outComefilter, statusFilter]
  );
  const onCustomDurationChange = (e) => {
    const { name, value } = e.target;
    if (name === "max" && value && Number(value) <= 0) return;
    setCustomDurationError("");
    setMainState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const deleteFilter = async () => {
    localStorage.removeItem("savedFilters");
    const deletedData = savedFilters
      ? savedFilters?.filter((data) => data?.filterName !== filterName)
      : [];
    localStorage.setItem("savedFilterData", JSON.stringify(deletedData));

    setFilterName("");
    let obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration: "",
      callStatus: [],
      outcome: [],
      projectId: "",
      from: moment(currentDate).format("YYYY-MM-DD"),
      to: moment(currentDate).format("YYYY-MM-DD"),
      timezone
    };

    await dispatch(getCallHistoryAction(obj));
    await dispatch(getCallHistoryCountAction(obj));

    setMainState((prev) => ({
      ...prev,
      durationFilter: "",
      outComefilter: [],
      statusFilter: [],
      min: "",
      max: "",
      startDate: currentDate,
      endDate: currentDate,
      selectedProjectId: null,
      filterName: "",
      searchText: "",
      filterDrawer: false,
      pageIndex: 1,
    }));
    setDateRange([new Date(currentDate), new Date(currentDate)]);
  };

  const clearFilter = async (val, prevPage = false) => {
    const resetFilters = () => {
      localStorage.removeItem("savedFilters");
      localStorage.removeItem("selectedFilter");
      localStorage.removeItem("callHistorySearchTerm");
      setMainState((prev) => ({
        ...prev,
        durationFilter: "",
        outComefilter: [],
        statusFilter: [],
        min: "",
        max: "",
        startDate: currentDate,
        endDate: currentDate,
        selectedProjectId: null,
        filterName: "",
        searchText: "",
      }));
      setSearchTerm("");
      setState((prev) => ({
        saveFilter: false,
        saveDate: false,
      }));
    };

    if (saveFilter) {
      if (prevPage) {
        resetFilters();
        return;
      }
      return;
    }

    if (val === "all") {
      resetFilters();
      let obj = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
        live: false,
        duration: "",
        callStatus: [],
        outcome: [],
        projectId: "",
        from: moment(currentDate).format("YYYY-MM-DD"),
        to: moment(currentDate).format("YYYY-MM-DD"),
        timezone
      };

      await dispatch(getCallHistoryAction(obj));
      await dispatch(getCallHistoryCountAction(obj));

      setMainState((prev) => ({
        ...prev,
        filterDrawer: false,
        pageIndex: 1,
        filterName: "",
      }));
      setDateRange([new Date(currentDate), new Date(currentDate)]);
      return;
    }

    const resetValues =
      val === "outComefilter" || val === "statusFilter" ? [] : "";
    const filterMapping = {
      durationFilter: "duration",
      statusFilter: "callStatus",
      outComefilter: "outcome",
    };

    const obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${min}-${max}`
          : durationFilter?.split(" ")[0],
      searchText: "",
      callStatus: statusFilter,
      outcome: outComefilter,
      projectId: selectedProjectId,
      from: moment(currentDate).format("YYYY-MM-DD"),
      to: moment(currentDate).format("YYYY-MM-DD"),
      timezone
    };

    delete obj[filterMapping[val]];

    await dispatch(getCallHistoryAction(obj));
    await dispatch(getCallHistoryCountAction(obj));

    setMainState((prev) => ({
      ...prev,
      [val]: resetValues,
      pageIndex: 1,
    }));
    setDateRange([new Date(currentDate), new Date(currentDate)]);
  };

  const validateDurationFilter = () => {
    if (durationFilter === "Custom") {
      if (!min?.trim() || !max?.trim()) {
        const errorMessage = !min?.trim() ? "Add min value" : "Add max value";

        setCustomDurationError(errorMessage);
        return false;
      }
      if (parseFloat(min) > parseFloat(max)) {
        setCustomDurationError("Max value cannot be less than min value");
        return false;
      }
    }
    return true;
  };

  const handleSaveFilter = () => {
    if (validateDurationFilter()) {
      setState((prev) => ({
        ...prev,
        saveFilter: true,
        saveDate: true,
      }));
      setFilterName("")
    }
  };

  const handleApplyFilter = async (scenario = "apply") => {
    if (scenario === "locApply" && !filterName.trim()) {
      setErrMsg("Please enter a filter name.");
      return;
    }
    if (!validateDurationFilter()) return;

    const customDuration = durationFilter === "Custom" ? `${min}-${max}` : "";

    if (scenario === "apply") {
      const appliedFilters = {
        filterName,
        startDate,
        endDate,
        selectedProjectId,
        outComefilter,
        durationFilter,
        statusFilter,
        customFilter: customDuration,
      };
      localStorage.setItem("savedFilters", JSON.stringify(appliedFilters));
    } else if (scenario === "locApply") {
      const newFilter = {
        filterName,
        startDate,
        endDate,
        durationFilter,
        statusFilter,
        outComefilter,
        selectedProjectId,
        customFilter: customDuration,
      };
      const existingFiltersLoc = localStorage.getItem("savedFilterData");
      const existingFilters = existingFiltersLoc
        ? JSON.parse(existingFiltersLoc)
        : [];
      const existingFilterIndex = existingFilters.findIndex(
        (filter) => filter.filterName === filterName
      );

      if (existingFilterIndex > -1) {
        existingFilters[existingFilterIndex] = newFilter;
      } else {
        existingFilters.push(newFilter);
      }

      localStorage.setItem("savedFilterData", JSON.stringify(existingFilters));
      localStorage.setItem("savedFilters", JSON.stringify(newFilter));
    }

    setState((prev) => ({ ...prev, loading: true }));

    const requestObj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? customDuration
          : durationFilter?.split(" ")[0],
      searchText,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      projectId: selectedProjectId,
      callStatus: statusFilter,
      outcome: outComefilter,
      timezone
    };

    await dispatch(getCallHistoryAction(requestObj));
    await dispatch(getCallHistoryCountAction(requestObj));

    setState((prev) => ({ ...prev, loading: false }));
    setMainState((prev) => ({
      ...prev,
      filterDrawer: false,
      pageIndex: 1,
    }));
  };

  const handleClearDurationFilter = () => {
    setMainState((prev) => ({
      ...prev,
      durationFilter: "",
      min: "",
      max: "",
    }));
    setCustomDurationError("");
  };

  const handleFilterSelect = (e) => {
    setFilterName(e.filterName);
    setFilterNameFromDropdown(e.filterName);
    const obj = {
      searchText,
      startDate: e.startDate,
      endDate: e.endDate,
      durationFilter: e.durationFilter,
      statusFilter: e.statusFilter,
      outComefilter: e.outComefilter,
      selectedProjectId: e.selectedProjectId,
      filterName: e.filterName,
      customFilter: e.customFilter,
      min: e.customFilter.split("-")[0] || "",
      max: e.customFilter.split("-")[1] || "",
    };

    localStorage.setItem("savedFilters", JSON.stringify({ ...obj }));
    const params = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        e.durationFilter === "Custom" ? e.customFilter : e.durationFilter,
      searchText,
      from: moment(e.startDate).format("YYYY-MM-DD"),
      to: moment(e.endDate).format("YYYY-MM-DD"),
      projectId: e.selectedProjectId,
      callStatus: e.statusFilter,
      outcome: e.outComefilter,
      timezone
    };
    dispatch(getCallHistoryAction(params));
    dispatch(getCallHistoryCountAction(params));
    setMainState((prev) => ({
      ...prev,
      ...obj,
      filterDrawer: false,
      pageIndex: 1,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }}
    >
      <Box sx={{ minWidth: "353px", padding: "17px 23px 0 23px" }}>
        <FilterHeading
          filterName={filterName}
          setFilterName={setFilterName}
          saveFilter={saveFilter}
          savedFilters={state?.savedFilters}
          handleFilterSelect={handleFilterSelect}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          deleteFilter={deleteFilter}
        />

        {saveFilter ? (
          <SavedFilters
            mainState={mainState}
            projectList={projectList}
            durationFilter={durationFilter}
            setMainState={setMainState}
            selectedProjectId={selectedProjectId}
            saveDate={saveDate}
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
            setState={setState}
            currentDate={currentDate}
          />
        ) : (
          <>
            <DateProjectFilter
              startDate={startDate}
              endDate={endDate}
              setDateRange={setDateRange}
              selectedProjectId={selectedProjectId}
              setMainState={setMainState}
              projectList={projectList}
            />
            <DurationFilter
              mainState={mainState}
              arraMapped={arraMapped}
              allCallStatus={allCallStatus}
              outComefilter={outComefilter}
              onChange={onChange}
              onCustomDurationChange={onCustomDurationChange}
              customDurationError={customDurationError}
              handleClearDurationFilter={handleClearDurationFilter}
            />
          </>
        )}
        {
          <CallStatusFilter
            saveFilter={saveFilter}
            statusFilter={statusFilter}
            onChange={onChange}
            allCallStatus={allCallStatus}
            showAll={showAll}
            setShowAll={setShowAll}
          />
        }
      </Box>

      <FilterFooter
        loading={loading}
        saveFilter={saveFilter}
        applyFilter={handleApplyFilter}
        clearFilter={clearFilter}
        handleSaveFilter={handleSaveFilter}
      />
    </Box>
  );
}

export default FilterBody;
