export const customSelectStyles = {
  control: (base) => ({
    ...base,
    color: "#1a1d23",
    borderRadius: "8px",
    border: "1px solid #ccd2de",
    padding: "2px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#3b82f6",
    },
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? "#3b82f6" : isFocused ? "#dde8ff80" : "#fff",
    color: isSelected ? "#fff" : "#1a1d23",
    cursor: "pointer",
    padding: "6px 12px",
    "&:hover": {
      backgroundColor: "#dde8ff80",
      color: "#1a1d23",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
    overflowY: "auto",
  }),
};
