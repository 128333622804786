import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../styles/callStatus.css";
import Drawer from "@material-ui/core/Drawer";

import * as FileSaver from "file-saver";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import "./CampaignDetails.css";

import SkeletonLodingWithCards from "../../../../Constant/SkeletonLoding";
import useQueryParams from "../../../Hooks/useQueryParams";
import {
  NotificationPopup,
  PAGE_SIZE_50,
  getCustomWriteAccess,
  isEmpty,
} from "../../CommonComponents/Common";
import {
  getAudienceAction,
  getAudienceForSelectAction,
} from "../../ProjectSection/AudienceSection/Store/Audience.action";
import {
  getCampaignAction,
  getCampaignDetailsAction,
  getCampaignDetailsAllAction,
  saveFilterdCampaignAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import CampaignRunTable from "./CampaignRunTable";
import CallTranscriptModal from "../../CallHistory/CallTranscriptModal";
import RedialBody from "./RedialBody";
import CommonModal from "../../CommonComponents/Modal/CommonModal";
import FilterBody from "./Filter/FilterBody";
import FilteredCampaignBody from "./FilteredCampaignBody";
import FilteredCamapaignModal from "./FilteredCampaignModal";
import Insight from "./Insight";
import CreateInsightModal from "./CreateInsightModal";

import { ReactComponent as SearchIcon } from "../../../../assets/Images/SearchIcon.svg";
import { ReactComponent as MenuDots } from "../../../../assets/Images/MenuDots.svg";
import { ReactComponent as BackIcon } from "../../../../assets/Images/WhiteBackIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/icons/refresh.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/Images/FilterIcon.svg";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import { ReactComponent as AddIcon } from "../../../../assets/Images/GreyAddIcon.svg";

import { styles } from "./styles";
import SummaryCard from "../Campaign/SummaryCards";
import { swalWarningMessage } from "../../../../Constant/swalMessage";
import { ReactComponent as Dot } from "../../../../assets/Images/Text.svg";
import moment from "moment";
import { shortenString } from "../../../../Constant/helper";
import SearchBar from "../../Common/Search/SearchBar";
import BaseButton from "../../Common/Buttons/BaseButton";
import copy from "copy-to-clipboard";

function CampaignDetailsList({
  name,
  campaignData,
  campaignDate,
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { campaignid, campaignRuns } = useQueryParams();
  let data = campaignData;
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let live: any = locSearch.get("live");
  let refresh: any = locSearch.get("refresh");
  let count = 0;
  const appliedFiltersLoc = JSON.parse(
    localStorage.getItem("campaignDetailsFilters") || "{}"
  );

  const reducer: any = useSelector((state) => state);

  const {
    AccountReducer: {
      constantData,
      userDetails: { email },
      selectedAccount,
    },
    CampaignReducer: {
      campaigns,
      campaignsCount,
      campaignsDetails,
      campaignsDetailsCounts,
      campaignsDetailsTotalCounts,
    },
  } = reducer;

  const [state, setState] = useState<any>({
    CALL_STATUS: constantData?.callStatus,
    campaignRunListData: [],
    showCreateButton: Boolean(localStorage.getItem("showCreateButton")),
    exportSpin: false,
    newCampaignData: [],
    summaryModal: false,
    recordingUrl: "",
    selectedCampaignRun: "",
    filterDrawer: false,
    countObj: {},
    insightsPopup: false,
    insightsPopupView: false,
    trackEnabledRecording: [],
    pageIndex: Number(localStorage.getItem("campaignRunDetailsIndex")) || 1,
    durationFilter: appliedFiltersLoc?.durationFilter || "",
    outComefilter: appliedFiltersLoc?.outcomeFilters || [],
    statusFilter: appliedFiltersLoc?.statusFilter || [],
    searchText: "",
    redialModal: false,
    min: appliedFiltersLoc?.customFilter
      ? appliedFiltersLoc?.customFilter.split("-")[0] || ""
      : "",
    max: appliedFiltersLoc?.customFilter
      ? appliedFiltersLoc?.customFilter.split("-")[1] || ""
      : "",
  });

  const {
    campaignRunListData,
    showCreateButton,
    exportSpin,
    newCampaignData,
    filterDrawer,
    countObj,
    insightsPopup,
    insightsPopupView,
    trackEnabledRecording,
    durationFilter,
    statusFilter,
    outComefilter,
    CALL_STATUS,
    searchText,
    redialModal,
  } = state;
  const stateRef = useRef(state);
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [buttonSpin, setButtonSpin] = useState(true);
  const [campaignName, setCampaignName] = useState("");
  const [audienceName, setAudienceName] = useState("");
  const [popup, setPopup] = useState(false);
  const [filterdata, setFilterData] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [range, setRange] = useState(false);
  const [inBound, setInBound] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showCallTranscriptModal, setShowCallTranscriptModal] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [audienceArray, setAudienceArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notofication, setNotification] = useState(false);

  const {
    RecordingReducer: { recordings },
  } = reducer;

  const callFn = async (pIndex = false) => {
    const currentState = stateRef.current;
    if (count === 0) {
      setLoading(true);
      count++;
    }
    if (pIndex) {
      localStorage.setItem("campaignRunDetailsIndex", "1");
      setState((prev) => ({
        ...prev,
        pageIndex: 1,
      }));
    }
    let obj = {
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
      live: live ? live : false,
      duration:
        currentState.durationFilter === "Custom"
          ? `${currentState?.min}-${currentState?.max}`
          : currentState.durationFilter?.split(" ")[0],
      callStatus: currentState.statusFilter,
      outcome: currentState.outComefilter,
      searchText: searchText,
      callType: inBound ? "INBOUND" : "",
    };
    const campaignsCount: any = await dispatch(
      getCampaignDetailsAction(projectId, campaignRuns, { ...obj, count: true })
    );
    const res: any = await dispatch(
      getCampaignDetailsAction(projectId, campaignRuns, obj)
    );
    if (res?.data) {
      setState((prev) => ({
        ...prev,
        campaignRunListData: res?.data,
      }));
    }

    if (campaignsCount?.countsData?.length > 0) {
      let countsData = {};

      for (const status of campaignsCount?.countsData) {
        countsData[status._id] = status.count;
      }
      setState((prev) => ({
        ...prev,
        countObj: countsData,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    callFn();
  }, [inBound, dispatch, refresh, live]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    const handleRouteChange = () => {
      const currentPath = window.location.pathname;
      if (currentPath !== location.pathname) {
        localStorage.removeItem("campaignDetailsFilters");
        localStorage.removeItem("showCreateButton");
      }
    };
    return () => {
      handleRouteChange();
    };
  }, [location.pathname]);

  useEffect(() => {
    if (live === "true") {
      const interval = setInterval(() => {
        callFn();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [live === "true"]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      callFn(true);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (recordings?.length > 0) {
      let arr: any = [];
      for (const record of recordings) {
        if (record?.track === true) {
          arr.push({
            name: record.recordingName,
            tag: record.tag,
          });
        }
      }
      setState((prev) => ({
        ...prev,
        trackEnabledRecording: arr,
      }));
    }
  }, [recordings]);

  useEffect(() => {
    const init = async () => {
      let obj1 = {
        pageIndex: 1,
        pageSize: 100000,
      };
      let res: any = await dispatch(
        getAudienceForSelectAction(projectId, obj1)
      );
      setAudienceArray(res);
    };
    init();
  }, [redialModal]);

  const toggle = (name = "summaryModal") => {
    setState((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const toggleRedial = () => {
    setState((prev) => ({
      ...prev,
      redialModal: !prev.redialModal,
    }));
    setAnchorEl(null);
  };

  const toggleViewInsight = () => {
    setState((prev) => ({
      ...prev,
      insightsPopupView: !state.insightsPopupView,
    }));
    setAnchorEl(null);
  };

  const getOutcome = (data) => {
    let trackResult: any = [];
    if (data?.outComes) {
      data.outComes.forEach((da) => {
        let kl = trackEnabledRecording.find((kl) => kl.tag === da);
        if (kl) {
          trackResult.push(kl.name);
        }
      });

      trackEnabledRecording?.forEach((track) => {
        if (data.outComes.includes(track.tag)) {
          trackResult.push(track.name);
        }
      });
      return trackResult.length > 0 ? trackResult[trackResult?.length - 1] : "";
    } else {
      return "";
    }
  };

  const checkplusThing = (audPhone, phone) => {
    if (audPhone?.includes("+")) {
      let kl = audPhone.split("+")[1];
      if (kl === phone) {
        return true;
      }
    } else return audPhone === phone;
  };

  const openModal = (selectedCampaignRun) => {
    setState((prev) => ({
      ...prev,
      summaryModal: true,
      recordingUrl: selectedCampaignRun.recordingUrl,
      selectedCampaignRun: selectedCampaignRun,
    }));
  };

  const ExportCSV = async () => {
    setState((prev) => ({
      ...prev,
      exportSpin: true,
    }));
    let send;
    if (filterdata.length > 0) {
      send = filterdata;
    }
    if (filterName !== "") {
      send = filterName;
    }

    let obj = {
      pageIndex: 1,
      pageSize: 1000,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      searchText: searchText,
    };
    let res: any = await dispatch(
      getCampaignDetailsAllAction(projectId, campaignRuns, obj)
    );

    let matchArr = [
      "mandatory_params",
      "firstName",
      "lastName",
      "FirstName",
      "LastName",
      "phone",
      "PhoneNum",
    ];
    if (res) {
      let arr: any = [];
      res.forEach((kl) => {
        let copy = kl?.customParameters;
        let obj: any = {};
        obj["First Name"] = copy?.FirstName || "--";
        obj["Last Name"] = copy?.LastName || "--";
        obj["Phone number"] = kl?.phone || "--";
        obj["Call UUID"] = kl?.call_uuid || "";
        obj["Calling Status"] = CALL_STATUS[kl.callStatus] || "";
        obj["Cost"] = kl?.newCost || "";
        if (selectedAccount?.params?.pricesWaivers) {
          obj["Cost"] = "--";
        }
        obj["Recording Url"] = kl?.callRecordingUrl || "";
        if (!isEmpty(copy)) {
          for (const customKeys in copy) {
            if (!matchArr.includes(customKeys)) {
              obj[customKeys] = copy[customKeys];
            }
          }
        }

        arr.push(obj);
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(arr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Campaign Report ${name}` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };

  const createCampaign = async (w) => {
    setButtonSpin(false);
    w.preventDefault();
    if (!campaignName || !audienceName) {
      swalWarningMessage("Campaign Name and Audience Name cannot be empty.");
      setButtonSpin(true);
      return;
    }
    let obj = {
      isSelectAll: selectAll,
      campaignRuns: campaignRuns,
      campaignName: campaignName,
      audienceName: audienceName,
      projectId: projectId,
      newCampaignData: newCampaignData,
      outComefilter,
      statusFilter,
      searchText,
      durationFilter:
        state.durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : state.durationFilter?.split(" ")[0] === "45+"
          ? "45-420"
          : state.durationFilter?.split(" ")[0],
      range: range,
    };

    let res: any = await dispatch(saveFilterdCampaignAction(obj));

    if (res) {
      let obj1 = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
      };
      await dispatch(getCampaignAction(projectId, obj1));
      setPopup(false);
      setOpen(false);
      setButtonSpin(true);
      history.push(`/account/project/${projectId}/campaigns`);
    } else {
      setPopup(false);
      setOpen(false);
      setButtonSpin(true);
    }
  };
  const createCampaign1 = async (w) => {
    setButtonSpin(false);
    w.preventDefault();
    if (!campaignName || !audienceName) {
      swalWarningMessage("Campaign Name and Audience Name cannot be empty.");
      setButtonSpin(true);
      return;
    }
    let statusCodes: any = [];
    let statusArr = ["701", "702", "703", "712", "710"];
    for (const sta in CALL_STATUS) {
      if (!statusArr?.includes(sta)) {
        statusCodes.push(sta);
      }
    }
    let obj = {
      isSelectAll: true,
      campaignRuns: campaignRuns,
      campaignName: campaignName,
      audienceName: audienceName,
      projectId: projectId,
      newCampaignData: newCampaignData,
      outComefilter: [],
      statusFilter: statusCodes,
      searchText: "",
      durationFilter: "",
      range: range,
    };
    let res: any = await dispatch(saveFilterdCampaignAction(obj));

    if (res) {
      let obj1 = {
        pageIndex: 1,
        pageSize: 20,
      };
      await dispatch(getAudienceAction(projectId, obj1));
      await dispatch(getCampaignAction(projectId, obj1));
      setPopup(false);
      setOpen(false);
      setButtonSpin(true);
      history.push(`/account/project/${projectId}/campaigns`);
    } else {
      setPopup(false);
      setOpen(false);
      setButtonSpin(true);
    }
  };

  const obj = {
    projectId,
    campaignid,
    searchText,
    setMainState: setState,
    trackEnabledRecording,
    mainState: state,
    filterName,
    setFilterName,
    campaignRuns,
    inBound,
    live,
  };

  const openCallTranscriptModal = (transcriptData) => {
    setSelectedTranscript(transcriptData);
    setShowCallTranscriptModal(true);
  };
  const closeCallTranscriptModal = () => {
    setShowCallTranscriptModal(false);
    setSelectedTranscript(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleCopy = (id) => {
    copy(id);
    setNotification(true)
  };

  const redialObj = {
    setCampaignName,
    campaignName,
    audienceArray,
    audienceName,
    setAudienceName,
    setRange,
    range,
  };

  const filteredCampObj = {
    setCampaignName,
    campaignName,
    audienceArray,
    audienceName,
    setAudienceName,
    setRange,
    range,
  };
  const totalCallsMade = Object.values(countObj || {}).reduce(
    (acc: number, curr) => acc + (isNaN(Number(curr)) ? 0 : Number(curr)),
    0
  );

  return loading ? (
    <SkeletonLodingWithCards count={3} />
  ) : (
    <Box>
      <Box className="flex-between header" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography className="font-20-grey">Campaign Run Details</Typography>
          {live === "true" && (
            <Button variant="contained" disableRipple sx={styles.live}>
              Live
            </Button>
          )}
        </Box>

        <Box className="flex-align-center" gap={1}>
          {getCustomWriteAccess(reducer) === "write" && showCreateButton && (
            <BaseButton
              variant="outlined"
              title="Create New Campaign"
              startIcon={<AddIcon />}
              handleClick={() => setOpen(true)}
              disabled={campaignRunListData?.length === 0}
            />
          )}
          <SearchBar
            searchText={searchText}
            handleChange={(e) => {
              e.persist();
              setState((prev) => ({
                ...prev,
                searchText: e.target.value,
              }));
            }}
          />

          <Box>
            <BaseButton
              title={<MenuDots title="More" />}
              variant="outlined"
              handleClick={handleClick}
              styles={styles.iconBtn}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: styles.menu,
              }}
            >
              {getCustomWriteAccess(reducer) === "write" && (
                <MenuItem sx={styles.menuItem} onClick={() => toggleRedial()}>
                  Redial
                </MenuItem>
              )}

              {getCustomWriteAccess(reducer) === "write" && (
                <MenuItem
                  sx={styles.menuItem}
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      insightsPopup: true,
                    }));
                    setAnchorEl(null);
                  }}
                >
                  Create Insight
                </MenuItem>
              )}
              <MenuItem
                sx={styles.menuItem}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    insightsPopupView: true,
                  }));
                  setAnchorEl(null);
                }}
              >
                View Insight
              </MenuItem>
              <MenuItem sx={styles.menuItem} onClick={ExportCSV}>
                <ExportIcon />
                <Typography
                  ml={1.2}
                  fontWeight={400}
                  color="#333333"
                  fontSize="14px"
                >
                  Export File
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <BaseButton
            title={<RefreshIcon title="Refresh Page" />}
            variant="outlined"
            handleClick={() =>
              history.push(
                `/account/project/${projectId}/campaign-runs-details?campaignid=${campaignid}&campaignRuns=${campaignRuns}&live=false&refresh=${
                  Number(refresh) + 1
                }`
              )
            }
            styles={styles.iconBtn}
          />

          <BaseButton
            title={<FilterIcon title="Apply Filters" />}
            variant="outlined"
            handleClick={() => toggle("filterDrawer")}
            styles={styles.iconBtn}
          />

          <BaseButton
            title="Back"
            variant="contained"
            handleClick={() =>
              history.push(
                `/account/project/${projectId}/campaign-runs?campaignid=${data?._id}`
              )
            }
            startIcon={<BackIcon className="back-icon" />}
          />
        </Box>
      </Box>
      <Box mt={0.5} className="flex-align-center">
        <Typography
          color={"rgba(102, 102, 102, 1)"}
          fontSize={16}
          fontWeight={500}
          textTransform={"capitalize"}
        >
          {shortenString(name, 30)} -
        </Typography>
        <Typography
          color={"rgba(102, 102, 102, 1)"}
          fontSize={14}
          fontWeight={400}
          component={"span"}
          className="ml-1 flex-align-center"
        >
          {moment(campaignDate, "YYYY-MM-DD hh:mm a").format("Do MMM YYYY")}
          <Dot className="ml-1 mr-1" />
          {moment(campaignDate, "YYYY-MM-DD hh:mm a").format("h:mma")}
        </Typography>
      </Box>
      <Box
        mt={2.5}
        display="grid"
        gridTemplateColumns="repeat(7, 1fr)"
        gap={2}
        sx={{
          "@media (max-width: 1300px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
          },
          "@media (max-width: 1200px)": {
            gridTemplateColumns: "repeat(5, 1fr)",
          },
          "@media (max-width: 1020px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
        }}
      >
        <SummaryCard
          title="Calls Failed"
          value={`${countObj["704"] || 0}`}
          circleColor="linear-gradient(180deg, #FF6666 0%, #CC0000 100%)"
        />
        <SummaryCard
          title=" Calls Connected"
          value={`${countObj["703"] || 0}`}
          circleColor="linear-gradient(237.96deg, rgba(150, 255, 143, 0.8) -12.91%, rgba(87, 209, 78, 0.8) 100.74%)"
        />
        <SummaryCard
          title="Calls Made"
          value={`${totalCallsMade}`}
          circleColor="linear-gradient(122.19deg, rgba(255, 143, 224, 0.8) 34.92%, rgba(242, 93, 203, 0.8) 100.02%)"
        />
      </Box>
      <Box mt={3} className="content">
        {selectedAccount?.params?.inboundEnable && live != "true" && (
          <Box
            className="flex-between"
            sx={{
              background: "#F1F4FA",
              borderRadius: "6px",
              padding: "3px 4px",
            }}
            width="295px"
          >
            <Button
              onClick={() => setInBound(false)}
              sx={{
                ...styles.commonInboundOutbound,
                background: !inBound ? "#FFFFFF" : "transparent",
                color: !inBound ? "#333333" : "#757B93",
                ":hover": {
                  background: "#FFFFFF",
                },
              }}
            >
              Outbound Calls
            </Button>
            <Button
              onClick={() => setInBound(true)}
              sx={{
                ...styles.commonInboundOutbound,
                background: inBound ? "#FFFFFF" : "transparent",
                color: inBound ? "#333333" : "#757B93",
                ":hover": {
                  background: "#FFFFFF",
                },
              }}
            >
              Inbound Calls
            </Button>
          </Box>
        )}

        <Box mt={3}>
          <CampaignRunTable
            getOutcome={getOutcome}
            trackEnabledRecording={trackEnabledRecording}
            data={campaignRunListData}
            live={live}
            selectedAccount={selectedAccount}
            constantData={constantData}
            inBound={inBound}
            handlePageChange={handlePageChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            currentPage={currentPage}
            totalPages={totalPages}
            openCallTranscriptModal={openCallTranscriptModal}
            handleCopy={handleCopy}
          />
        </Box>
      </Box>
      {showCallTranscriptModal && (
        <CallTranscriptModal
          show={showCallTranscriptModal}
          onClose={closeCallTranscriptModal}
          selectedTranscript={selectedTranscript}
        />
      )}
      {redialModal && (
        <CommonModal
          show={redialModal}
          toggle={toggleRedial}
          heading="Redial"
          body={<RedialBody redialObj={redialObj} />}
          size={600}
          handleSave={createCampaign1}
          spin={!buttonSpin}
        />
      )}
      {filterDrawer && (
        <Drawer
          anchor={"right"}
          open={filterDrawer}
          onClose={() => toggle("filterDrawer")}
        >
          <FilterBody toggle={toggle} obj={obj} />
        </Drawer>
      )}
      {open && (
        <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
          <FilteredCampaignBody
            campaignRunListData={campaignRunListData}
            trackEnabledRecording={trackEnabledRecording}
            newCampaignData={newCampaignData}
            constantData={constantData}
            selectedAccount={selectedAccount}
            setOpen={setOpen}
            toggleState={{ popup, setPopup }}
            setState={setState}
            setSelectAll={setSelectAll}
          />
        </Drawer>
      )}
      {popup && (
        <CommonModal
          show={popup}
          toggle={() => setPopup((prev) => !prev)}
          heading="Create Filtered Campaign"
          body={<FilteredCamapaignModal obj={filteredCampObj} />}
          size={600}
          handleSave={createCampaign}
          spin={!buttonSpin}
        />
      )}
      {insightsPopupView && (
        <CommonModal
          show={insightsPopupView}
          toggle={toggleViewInsight}
          size={800}
          handleSave={() =>
            setState((prev) => ({ ...prev, insightsPopupView: false }))
          }
          heading="View Insight"
          body={
            <Insight
              campaignRunListData={campaignRunListData}
              campaignName={name}
            />
          }
          saveButtonText="Close"
        />
      )}
      {insightsPopup && (
        <CreateInsightModal
          campaignName={name}
          campaignData={data}
          show={insightsPopup}
          toggle={() => setState((prev) => ({ ...prev, insightsPopup: false }))}
        />
      )}
      <NotificationPopup
        open={notofication}
        setOpen={setNotification}
        message={"UUID copied."}
      />
    </Box>
  );
}

export default CampaignDetailsList;
