import axios from "axios";
import { swalErrorMessage, swalWarningMessage } from "./swalMessage";

export const API = {
  LOGIN: {
    GET_TOKEN: "/token",
    GOOGLE_TOKEN: "/google-token",
  },
  USER: {
    GET_USER_PROFILE: "get-user-name",
    PROFILE_CHECK: "/profile-check",
  },
  ACCOUNT_DETAILS: {
    GET_ACCOUNT_BALANCE: "get-account-balance",
  },
  BALANCE: {
    CHECK_SUFFICIENT_BALANCE: "check-sufficient-balance",
  },
  TERMS: {
    GET_TERMS_CONDITIONS: "get-terms-conditions",
  },
  CALL_SETTINGS: {
    SAVE_DISCONNECTED_TIME: "save-disconnect-time",
    GET_DISCONNECTED_TIME: "get-disconnect-time",
  },
  CAMPAIGN: {
    CAMPAIGN: "campaign",
    CAMPAIGN1: "/campaigns",
    CAMPAIGN_RUNS: "/run-campaign",
    CAMPAIGN_RUNS_DETAILS: "/run-campaign-details",
    CAMPAIGNS_WIDGET_COUNT: "/campaign-widgets",
    CAMPAIGNS_RUNS_WIDGET_COUNT: "/campaign-run-dashboard",
    CAMPAIGNS_RUNS_DETAILS_WIDGET_COUNT: "/campaign-run-details-widgets",
    EXCEL_EXPORT: "/excel-export",
    SAVE_FILTERED_CAMPAIGN: "/save-filtered-campaign",
    SCHEDULE_CAMPAIGN: "/schedule-call",
  },
  AUDIENCE: {
    AUDIENCE: "/audience",
    EXCEL_AUDIENCE: "/audience-excel",
  },
  PAYMENT: {
    SAVE_PAYMENT: "/topup",
    UPDATE_PAYMENT: "/stripe/balance",
    INVOICE: "/invoice",
    ORDER: "/razorpay/order",
    VERIFY: "/razorpay/verify",
  },
  V1: {
    CRM: {
      LOGIN: "/crm/login",
      WHITELABELDETAILS: "/whiteLabelDetails",
      CONNECTION_CHECK: "/crm/ghl/account-status",
      ACCOUNT_CHECK: "/crm/signup/subaccount",
    },
    VOICE: {
      VOICE: "/voices",
      VOICE_CLONE: "/voice/clone",
    },
    ACCESS: {
      INVITE: "/invite",
      USERS_CHECK: "/users/check",
      ACCEPT_INVITE: "/invite/accept",
      INVITED_USERS: "/invite/users",
      INVITE_USER: "/invite/user",
      ACCOUNT_HISTORY: "/account-history",
      SEND_EMAIL: "/user/sendEmail",
      PAUSE_ACCOUT: "/account-status",
      DELETE_ACCOUNT: "/accounts",
    },
    ADMIN: {
      GET_ALL_USER_LIST: "/users",
      GET_ALL_USER_USAGE_LIST: "/spent-history",
      RECHARGE: "/recharge/manual",
      SET_PRICING: "/account/price",
      GET_API_KEY: "/apikey",
      MASTER_PASSWORD: "/user/passwords",
      RECHARGE_HISTORY: "/account-balance-history",
      ACCOUNT_CREDIT: "/request-balance",
    },
    COMMON: {
      PAYMENT_ENQ: "/payment-enquiry",
      VERIFY_EMAIL: "/verify-email",
      USER: "/user",
      LOGIN: "/login",
      GET_USER_ACCOUNTS_DETAILS: "/user/accounts",
      SYSTEM_CONSTANT: "/system-constant",
      PROFILE_CHECK: "/profile-check",
      DASHBOARD: "/dashboard",
      DASHBOARD_CHART: "/dashboard/chart",
      CHECK_AUTH: "/auth/check",
      RESET_PASSWORD: "/reset-password/email",
      UPDATE_PASSWORD: "/reset-password/update",
      GOOGLE_LOGIN: "/google-login",
      LAST_LOGIN_TRACK: "/user/track",
      DEFAULT_GATEWAY: "/default-gateway",
      FEEDBACK: "/add-feedback",
      GENERATE_CODE: "/verification/generate",
      VERIFY_CODE: "/verification/verify",
    },
    PROJECT: "/project",
    ACCOUNT: "/account",
    RECORDING: {
      RECORDING: "/recording",
      TRACK: "/recording/track",
    },
    AUDIENCE: {
      AUDIENCE: "/audience",
      AUDIENCE_EXCEL: "/audience/excel",
      SELECT_AUDIENCE: "/audienceList-count",
    },
    AI: {
      CHECK_PROMPT: "/prompt/param_checker",
      QUEUE: "/system/queue",
      LOGS: "/cloud/public/logs",
      QUEUE_LIST: "/system/all_queue",
      UPDATE_LIST: "/system/queue",
      CALLS_LIST: "/system/accounts/calls",
      GATEWAY_CALLS_LIST: "/system/sid/calls",
      GATEWAY_PLUS_ACCOUNT_CALLS_LIST: "/system/sid_account/calls",
      INCREASE_CPS: "/gateways/cps",
      INCREASE_CAPACITY: "/gateways/capacity",
      GATEWAY_ASSIGNED_NUMBERS: "/twilio/gateway_phone_numbers",
      SAVE_INBOUND_NUMBER: "/twilio/select-incoming-number",
      GATEWAY_LIST: "/system/botstream_queue",
      GATEWAY_ACTION: "/gateways/action",
      CALL_GRAPH: "/system/call_graph",
      CLEAR_QUEUE: "/gateways/clear/emergency",
      SWITCH_GATEWAY: "/gateways/logical_gateway",
      CLEAR_REFERENCE_QUEUE: "/call/inbound_details",
    },
    TWILLIO: {
      SAVE_SID: "/twilio/register/auth",
      CREATE_TRUNK: "/twilio/create/trunk",
      LIST_BUYED_NUMBERS: "/twilio/phone_numbers",
      LIST_NUMBER_TO_BUY: "/twilio/phone_numbers/buy",
      COUNTRIES_LIST: "/twilio/list/countries",
      PURCHASE_PHONE_NUMBER: "/twilio/phone_number/buy",
    },

    AGENT: {
      AGENT: "/agent-setting",
      DEFAULT_SET: "/default-agent",
      AGENT_OBJECTION: "/prompt/generate_objections",
      CHAT_ID: "/prompt/prompt",
      CHAT: "/chat/chat",
      ONBOARDING_CHAT: "/prompt/onboarding/chat",
      IMPORT_AGENT: "/agent/import",
      GENERATE_PROMPT: "/prompt/generate/agent",
      GENERATE_VOICE: "/voice/generate",
    },
    CAMPAIGN: {
      CAMPAIGN: "/campaigns",
      CALL_HISTORY: "/campaign-run/details/daily",
      CALL_HISTORY_COUNT: "/campaign-run/details/count/daily",
      CAMPAIGN_COUNT: "/campaigns-count",
      TRIGGER_CAMPAIGN: "/trigger-campaign",
      CHECK_CAMPAIGN_VALIDATION: "/campaigns-validation",
      CAMPAIGN_RUNS: "/campaign-runs",
      CAMPAIGN_RUNS_COUNT: "/campaign-run-count",
      STOP_CAMPAIGN_RUNS: "/campaign-runs/stop",
      SCHEDULE_CAMPAIGN: "/schedule-call",
      CAMPAIGN_DETAILS: "/campaign-run/details",
      EXCEL_EXPORT: "/excel-export",
      FILTERED_CAMPAIGN: "/save-filtered-campaign",
      SAVE_MANUAL_FIELDS: "/manual-fields/excel",
      EXISTING_EXCEL: "/manual-fields/excel",
      SAVE_INSIGHTS: "/insights",
      ALL_DETAILS: "/campaign/run-details",
      TRIGGER_CALL_DIRECTLY: "/trigger-call",
      CAMPAIGN_RUNS_LIVE: "/campaign-runs/live",
      REFRENCE_ADD: "/custom-dialer/reference",
    },
    WHATSAPP: "/whatsapp/msg",
    SMS: "/sms/msg",
    CALL_SETTINGS: {
      CALL_SETTINGS: "/call-settings",
      GET_VOXI_OTP: "/get-otp",
      RESEND_OTP: "/resend-otp",
      VERIFY_OTP: "/verify-otp",
      BYOC: "/byoc",
    },
    GOOGLE_INTEGRATION: "/google/callback",
    ADVANCE_SETTINGS: "/advance-settings",
    CHECK_ACCOUNT_NAME: "/account/check-name",
    PAYMENT: {
      SAVE_PAYMENT: "/topup",
      UPDATE_PAYMENT: "/stripe/balance",
      INVOICE: "/invoice",
      ORDER: "/razorpay/order",
      VERIFY: "/razorpay/verify",
    },
    APPOINTMENT: {
      APPOINTMENT: "/appointment-settings",
    },
    WHATSAPP: {
      ADD_WHATSAPP_ACCOUNT: "/whatsapp/account/add",
      GET_WHATSAPP_ACCOUNTS: "/whatsapp/get_business_id_list_details",
      GET_WHATSAPP_ACCOUNT: "/whatsapp/get_business_id_detail",
      CREATE_WHATSAPP_TEMPLATE: "/whatsapp/templates",
    },
  },
};
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const BASE_URL_API_LINK = process.env.REACT_APP_BASE_URL_API;
export const BASE_URL_AI = `${process.env.REACT_APP_BASE_URL_AI}`;
export const BASEURL = `${BASE_URL}`;
export const BASE_URL_API = `${BASE_URL_API_LINK}`;
export const BASE_URL_API_V1 = `${process.env.REACT_APP_BASE_URL_API_V1}`;
export const axiosClient = axios.create();
let token = localStorage.getItem("token");
let selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axiosClient.defaults.headers.post["Content-Type"] = "application/json";
axiosClient.defaults.headers.common["dbUrl"] = `${selectedAccount?.accountUrl}`;

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.log("Response status:", error.response.status);
      console.log("Response status:", error.response.data);
      console.log("Response status:", error.response.data.detail);
      swalErrorMessage(error?.response?.data?.detail?.toString());
    }
    if (error.toString() === "Error: Request failed with status code 403") {
      // swalWarningMessage("Please login again");
      localStorage.clear();
      if (window?.location?.pathname !== "/") window.location.href = "/";
    }
    // localStorage.clear();
    // window.location.href = "/";
  }
);
