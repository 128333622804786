const authStyles = {
    label: {
      color: "#1A1D23",
      fontSize: 14,
      fontWeight: "medium",
      mb: 0.5,
    },
    textInput: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 2,
        borderColor: "#CCD2DE",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-input": {
        padding: 1.5,
        "&::placeholder": {
          color: "#8792A7",
          fontSize: 13,
          fontWeight: 400,
        },
      },
    },
    loginButton: {
      mt: 1,
      borderRadius: 2,
      bgcolor: "#FF5E00",
      fontSize: 13,
      fontWeight: 600,
      padding: 1.5,
      textTransform: "none",
      ":hover": { bgcolor: "#E95500" },
      "&.Mui-disabled": {
            backgroundColor: "#E95500",
          },
    },
  };

export default authStyles;