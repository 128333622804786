import React, { useState, useRef, useCallback, useEffect } from "react";
import { Node, Connection, Workflow, Agent } from "../types/workflow";
import WorkflowNode from "./WorkflowNode";
import AgentSelector from "./AgentSelector";
import { Plus } from "lucide-react";
import { availableAgents } from "../data/agents";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAgentAction } from "../../ProjectSection/Agents/Store/Agent.action";
import {
  editProjectsAction,
  getProjectByIdAction,
} from "../../NewProjects/Store/projects.action";
import BaseButton from "../../Common/Buttons/BaseButton";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { shortenString } from "../../../../Constant/helper";
import AddIcon from "@mui/icons-material/Add";
import CommonModal from "../../CommonComponents/Modal/CommonModal";

const Canvas: React.FC = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const reducer: any = useSelector((state) => state);
  const {
    ProjectReducer: { selectedProject },
  } = reducer;

  const [workflow, setWorkflow] = useState<Workflow>({
    nodes: [],
    connections: [],
  });
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [draggedNode, setDraggedNode] = useState<string | null>(null);
  const [connecting, setConnecting] = useState<string | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showAgentSelector, setShowAgentSelector] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState<Set<string>>(new Set());
  const canvasRef = useRef<HTMLDivElement>(null);
  const dragOffset = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const init = async () => {
      await dispatch(getAgentAction(projectId));
      await dispatch(getProjectByIdAction(projectId));
    };
    init();
  }, []);

  useEffect(() => {
    if (selectedProject?.workFlow) {
      setWorkflow({
        nodes: selectedProject.workFlow.nodes || [],
        connections: selectedProject.workFlow.connections || [],
      });
    }
  }, [selectedProject?.workFlow]);

  const handleDragStart = (nodeId: string, e: React.DragEvent) => {
    setIsDragging(true);
    setDraggedNode(nodeId);

    const node = workflow.nodes.find((n) => n.id === nodeId);
    if (node && canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      dragOffset.current = {
        x: e.clientX - rect.left - node.position.x,
        y: e.clientY - rect.top - node.position.y,
      };
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setDraggedNode(null);
  };

  const handleDragOver = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      if (draggedNode && canvasRef.current) {
        const rect = canvasRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left - dragOffset.current.x;
        const y = e.clientY - rect.top - dragOffset.current.y;

        setWorkflow((prev) => ({
          ...prev,
          nodes: prev.nodes.map((node) =>
            node.id === draggedNode ? { ...node, position: { x, y } } : node
          ),
        }));
      }
    },
    [draggedNode]
  );

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setMousePosition({ x, y });
    }
  }, []);

  const findAvailablePosition = (
    startX: number,
    startY: number
  ): { x: number; y: number } => {
    const nodeWidth = 192;
    const nodeHeight = 100;
    const padding = 20;

    const isPositionOccupied = (x: number, y: number): boolean => {
      return workflow?.nodes?.some((node) => {
        const nodeRight = node.position.x + nodeWidth + padding;
        const nodeBottom = node.position.y + nodeHeight + padding;
        const nodeLeft = node.position.x - padding;
        const nodeTop = node.position.y - padding;

        return (
          x < nodeRight &&
          x + nodeWidth > nodeLeft &&
          y < nodeBottom &&
          y + nodeHeight > nodeTop
        );
      });
    };

    let x = startX;
    let y = startY;
    let spiralStep = 0;
    const spiralSpacing = nodeWidth + padding;

    while (isPositionOccupied(x, y)) {
      spiralStep++;
      switch (spiralStep % 4) {
        case 0:
          x += spiralSpacing;
          break;
        case 1:
          y += spiralSpacing;
          break;
        case 2:
          x -= spiralSpacing;
          break;
        case 3:
          y -= spiralSpacing;
          break;
      }
    }

    return { x, y };
  };

  const addNode = (type: "trigger" | "agent", agentData?: Agent) => {
    const startPosition = { x: 100, y: 100 };
    const position = findAvailablePosition(startPosition.x, startPosition.y);

    const newNode: Node = {
      id: `node-${Math.random().toString(36).substr(2, 9)}`,
      type,
      position,
      data: {
        name: agentData
          ? agentData.name
          : type === "trigger"
          ? "New Trigger"
          : "New Agent",
        description: agentData ? agentData.description : "Click to configure",
        triggerType: type === "trigger" ? "webhook" : undefined,
        agentType: agentData?.id,
      },
      active: true,
    };

    setWorkflow((prev) => ({
      ...prev,
      nodes: [...(prev?.nodes || []), newNode],
    }));
  };

  const handleAgentsSelected = (selectedAgents: Agent[]) => {
    selectedAgents.forEach((agent) => {
      addNode("agent", agent);
    });
  };

  const startConnection = (nodeId: string) => {
    setConnecting(nodeId);
  };

  const completeConnection = (targetId: string) => {
    if (connecting && connecting !== targetId) {
      const sourceNode = workflow.nodes.find((n) => n.id === connecting);
      const targetNode = workflow.nodes.find((n) => n.id === targetId);

      if (sourceNode && targetNode) {
        if (sourceNode.type === "agent" && targetNode.type === "trigger") {
          return;
        }

        const connectionExists = workflow.connections.some(
          (conn) => conn.source === connecting && conn.target === targetId
        );

        if (!connectionExists) {
          const newConnection: Connection = {
            id: `conn-${Math.random().toString(36).substr(2, 9)}`,
            source: connecting,
            target: targetId,
          };

          setWorkflow((prev) => ({
            ...prev,
            connections: [...prev.connections, newConnection],
          }));
        }
      }
    }
    setConnecting(null);
  };

  const updateNode = (nodeId: string, data: Partial<Node["data"]>) => {
    setWorkflow((prev) => ({
      ...prev,
      nodes: prev.nodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, ...data } } : node
      ),
    }));
  };

  const deleteNode = (nodeId: string) => {
    setWorkflow((prev) => ({
      nodes: prev.nodes.filter((node) => node.id !== nodeId),
      connections: prev.connections.filter(
        (conn) => conn.source !== nodeId && conn.target !== nodeId
      ),
    }));
  };

  const getConnectionPath = (
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => {
    const controlPointOffset = Math.abs(endX - startX) * 0.5;
    return `M ${startX} ${startY} 
            C ${startX + controlPointOffset} ${startY},
              ${endX - controlPointOffset} ${endY},
              ${endX} ${endY}`;
  };

  const node = workflow?.nodes?.find((n) => n.id === connecting);
  const x = (node?.position.x ?? -192) + 192;
  const y = (node?.position.y ?? -32) + 32;

  const getName = (id) => {
    const node = workflow?.nodes?.find((n) => n.id === id);
    return node?.data?.name ?? "";
  };

  const removeConnection = (id) => {
    setWorkflow((prev) => ({
      ...prev,
      connections: prev.connections.filter((conn) => conn.id !== id),
    }));
  };

  const saveWorkflow = async () => {
    setLoading(true);
    const updatedNodes = updateConnectedAgents(workflow);
    const updatedWorkflow = {
      ...workflow,
      nodes: updatedNodes,
    };

    await dispatch(
      editProjectsAction({ workFlow: updatedWorkflow }, projectId)
    );
    await dispatch(getProjectByIdAction(projectId));
    setLoading(false);
  };

  const updateConnectedAgents = (workflow) => {
    const { nodes, connections } = workflow;
    const triggerNode = nodes.find((node) => node.type === "trigger");

    if (!triggerNode) {
      return nodes;
    }

    const connectedNodeIds = connections
      .filter((conn) => conn.source === triggerNode.id)
      .map((conn) => conn.target);

    const connectedNodesData = nodes
      .filter((node) => connectedNodeIds.includes(node.id))
      .map((node) => {
        const { agents, ...restData } = node.data;
        return {
          ...restData,
          agentId: agents?.[0] || null,
          active: node?.active,
        };
      });

    const updatedNodes = nodes.map((node) => {
      if (node.id === triggerNode.id) {
        return {
          ...node,
          data: {
            ...node.data,
            connectedAgents: connectedNodesData,
          },
        };
      }
      return node;
    });

    return updatedNodes;
  };
  const handleAgentsSubmit = () => {
    const selected = availableAgents.filter((agent) =>
      selectedAgents.has(agent.id)
    );
    handleAgentsSelected(selected);
    setShowAgentSelector(false);
    setSelectedAgents(new Set());
  };

  return (
    <div className="workflow-container">
      {/* Canvas */}
      <div
        ref={canvasRef}
        className="canvas"
        onMouseMove={handleMouseMove}
        onDragOver={handleDragOver}
      >
        {/* Connections */}
        <div className="svg-container">
          <svg>
            <defs>
              <marker
                id="arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="9"
                refY="3.5"
                orient="auto"
              >
                <polygon points="0 0, 10 3.5, 0 7" fill="#4CAF50" />
              </marker>
            </defs>

            {workflow?.connections?.map((conn) => {
              const sourceNode = workflow.nodes.find(
                (n) => n.id === conn.source
              );
              const targetNode = workflow.nodes.find(
                (n) => n.id === conn.target
              );
              if (sourceNode && targetNode) {
                const startX = sourceNode.position.x + 192;
                const startY = sourceNode.position.y + 32;
                const endX = targetNode.position.x;
                const endY = targetNode.position.y + 32;

                return (
                  <path
                    key={conn.id}
                    d={getConnectionPath(startX, startY, endX, endY)}
                    stroke="#5AA552"
                    strokeWidth="1"
                    fill="none"
                    markerEnd="url(#arrowhead)"
                    className="connection-path dashed-path"
                  />
                );
              }
              return null;
            })}

            {connecting && (
              <path
                d={getConnectionPath(x, y, mousePosition.x, mousePosition.y)}
                className="dashed-path"
              />
            )}
          </svg>
        </div>

        {/* Nodes */}
        <div className="nodes-container">
          {workflow?.nodes?.map((node) => (
            <WorkflowNode
              key={node.id}
              node={node}
              onDragStart={(e) => handleDragStart(node.id, e)}
              onDragEnd={handleDragEnd}
              onStartConnection={() => startConnection(node.id)}
              onCompleteConnection={() => completeConnection(node.id)}
              isConnecting={connecting === node.id}
              onUpdateNode={updateNode}
              onDeleteNode={deleteNode}
            />
          ))}
        </div>

        {/* Agent Selector */}
        {showAgentSelector && (
          <CommonModal
            show={showAgentSelector}
            toggle={() => setShowAgentSelector(false)}
            heading="Select Agents"
            body={
              <AgentSelector
                agents={availableAgents}
                setSelectedAgents={setSelectedAgents}
                selectedAgents={selectedAgents}
              />
            }
            size={500}
            handleSave={handleAgentsSubmit}
            saveButtonText="Add Selected Agents"
          />
        )}
      </div>

      {/* Sidebar */}

      <Box className="multi-agent-sidebar">
        <Box>
          <Typography pt={2} color="#181818" fontSize={14} fontWeight={600}>
            Tools
          </Typography>
          <Box my={1} display="flex" justifyContent="space-between" gap={1}>
            <BaseButton
              variant="outlined"
              title="Add Trigger"
              handleClick={() => addNode("trigger")}
              startIcon={
                <AddIcon
                  style={{
                    color: "#666666",
                    padding: 1,
                    backgroundColor: "white",
                  }}
                />
              }
            />
            <BaseButton
              variant="outlined"
              title="Add Agent"
              handleClick={() => setShowAgentSelector(true)}
              startIcon={
                <AddIcon
                  style={{
                    color: "#666666",
                    padding: 1,
                    backgroundColor: "white",
                  }}
                />
              }
            />
          </Box>
          <Box>
            <Typography mb={1} color="#181818" fontSize={12} fontWeight={500}>
              Assigned Actions
            </Typography>

            {workflow?.connections?.length > 0 ? (
              <Box display="flex" flexDirection="column" gap={1}>
                {workflow?.connections?.map((d) => (
                  <Box
                    key={d?.id}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      backgroundColor: "#F1F4FA",
                      borderRadius: 2,
                      paddingX: 1,
                      paddingY: 0.25,
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      textTransform="capitalize"
                      sx={{
                        textWrap: "nowrap",
                      }}
                    >
                      {shortenString(
                        `${getName(d.source)} - ${getName(d.target)}`,
                        30
                      )}
                    </Typography>
                    <IconButton
                      onClick={() => removeConnection(d.id)}
                      className="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography color="#9E9FA1" fontSize={14} fontWeight={400}>
                No actions appeared
              </Typography>
            )}
          </Box>
        </Box>

        <Box>
          <BaseButton variant="outlined" title="clear" handleClick={() => {}} />
          <BaseButton
            loading={loading}
            variant="contained"
            title="Save workflow"
            handleClick={saveWorkflow}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Canvas;
