import React, { Dispatch, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { PAGE_SIZE_50 } from "../CommonComponents/Common";
import {
  editAudienceAction,
  editAudienceExcelAction,
  getAudienceAction,
  saveAudienceAction,
  saveAudienceExcelAction,
  setExpandViewAction,
} from "../ProjectSection/AudienceSection/Store/Audience.action";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import { getAgentAction } from "../ProjectSection/Agents/Store/Agent.action";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import AudienceForm from "./AudienceForm";
import AudienceTableSection from "./AudienceTableSection";
import AudienceHeader from "./AudienceHeader";
import CommonModal from "../CommonComponents/Modal/CommonModal";
import "./audience.css";

const AudienceList: React.FC = () => {
  const reducer: any = useSelector((state) => state);
  const {
    AudienceReducer: { audiences, audienceCount },
  } = reducer;

  const dispatch: Dispatch<any> = useDispatch();
  let formobj = {
    name: { error: "", value: "" },
    audienceData: [{ firstName: "", lastName: "", phone: "" }],
    source: { error: "", value: "excel" },
  };
  const [formState, setFormState] = useState(formobj);
  const { projectId } = useParams<{ projectId: string }>();
  const [state, setState] = useState<any>({
    show: false,
    pageIndex: localStorage.getItem("audiencePageIndex") || 1,
    hasMore: false,
    editedAudience: {},
    spin: false,
    file: "",
    popup: false,
    viewList: false,
    buttonDisabled: false,
    numbersArray: [],
    duplicate: [],
    fromDuplicate: false,
    viewListShow: false,
    searchText: localStorage.getItem("searchTextAud") || "",
    buttonDisable: false,
  });
  const {
    show,
    viewListShow,
    pageIndex,
    editedAudience,
    spin,
    file,
    numbersArray,
    searchText,
    buttonDisable,
  } = state;

  useEffect(() => {
    setState((inInfinitestate) => ({
      ...inInfinitestate,
      hasMore: audienceCount > audiences?.length ? true : false,
    }));
  }, [audiences, audienceCount]);

  useEffect(() => {
    const init = async () => {
      await getAud();
      setState((prev) => ({
        ...prev,
        hasMore: true,
      }));
      await dispatch(getAgentAction(projectId));
    };
    init();

    return () => dispatch(setExpandViewAction({ expand: false }));
  }, []);

  useEffect(() => {
    let cArray: any = [];
    audiences?.map((o) => {
      o?.audienceList?.map((o) => {
        cArray.push(o);
      });
    });
    setState((prev) => ({
      ...prev,
      numbersArray: cArray,
    }));
  }, [audiences]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getAud();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const toggle = () => {
    setState((prev) => ({
      ...prev,
      show: !prev.show,
    }));
    setFormState((prev) => ({
      ...prev,
      name: { error: "", value: "" },
      audienceData: [{ firstName: "", lastName: "", phone: "" }],
      source: { error: "", value: "excel" },
    }));
    localStorage.removeItem("edit");
  };
  const isEdit = localStorage.getItem("edit") === "true" ? true : false;

  const getAud = async () => {
    localStorage.setItem("searchTextAud", searchText);
    let obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      searchText: searchText,
    };
    await dispatch(getAudienceAction(projectId, obj));
    setState((prev) => ({
      ...prev,
      pageIndex: 1,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const { name, source, audienceData } = formState;
    if (!name.value.trim() || !source.value) {
      swalWarningMessage("Please Enter all the fields");
      return;
    }
    if (name.value.length > 20) {
      swalWarningMessage("Audience name can't be more than 20 characters");
      return;
    }

    if (formState.source.value === "manual") {
      if (audienceData?.length === 1 && audienceData[0]?.phone === "") {
        swalWarningMessage("Please add audience");
        return;
      }
      let err = 0;
      let minNum = 0;
      audienceData?.forEach((aud) => {
        if (aud?.phone.trim() === "") {
          err++;
        }
        if (aud?.phone.length < 10) {
          minNum++;
        }
      });
      if (minNum > 0) {
        swalWarningMessage(`Phone number must be at least 10 digits.`);
        return;
      }
      if (err > 0) {
        swalWarningMessage(`phone number is empty`);
        return;
      }

      setState((prev) => ({
        ...prev,
        spin: true,
      }));
      let obj = {
        audienceName: formState.name.value,
        audienceList: formState.audienceData,
        source: formState.source.value,
        projectId: projectId,
      };

      if (isEmpty(editedAudience)) {
        await dispatch(saveAudienceAction(obj));
      } else {
        await dispatch(editAudienceAction(editedAudience?._id, obj));
      }
    }

    if (formState.source.value === "excel") {
      if (!file) {
        swalWarningMessage("Please add a file");
        setState((prev) => ({
          ...prev,
          spin: false,
        }));
        return;
      }
      setState((prev) => ({
        ...prev,
        spin: true,
      }));
      const formData = new FormData();
      formData.append("audienceName", formState.name.value);
      formData.append("projectId", projectId);
      formData.append("file", file);

      if (isEmpty(editedAudience)) {
        await dispatch(saveAudienceExcelAction(formData));
      } else {
        await dispatch(editAudienceExcelAction(editedAudience?._id, formData));
      }
    }

    let obj1 = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
    };
    await dispatch(getAudienceAction(projectId, obj1));
    setState((prev) => ({
      ...prev,
      spin: false,
      show: false,
      pageIndex: 1,
    }));
    setFormState(formobj);
    localStorage.setItem("audiencePageIndex", "1");
  };

  const handlePageNavigation = async (page) => {
    const obj = {
      pageIndex: page,
      pageSize: PAGE_SIZE_50,
      searchText: searchText,
    };
    await dispatch(getAudienceAction(projectId, obj));
    setState((prev) => ({
      ...prev,
      pageIndex: page,
    }));
    localStorage.setItem("audiencePageIndex", page);
  };

  const audienceFormObj = {
    formState,
    setFormState,
    formobj,
    setState,
    state,
  };

  return (
    <Box>
      <AudienceHeader
        viewListShow={viewListShow}
        searchText={searchText}
        setState={setState}
        toggle={toggle}
      />
      <AudienceTableSection
        setFormState={setFormState}
        setMainState={setState}
        viewListShow={viewListShow}
        pageIndex={pageIndex}
        handlePageNavigation={handlePageNavigation}
      />

      {show && (
        <CommonModal
          show={show}
          toggle={toggle}
          heading={isEdit ? "Edit Audience" : "Add Audience"}
          body={<AudienceForm obj={audienceFormObj} />}
          spin={spin}
          saveButtonText="Save"
          handleSave={handleSave}
          size={700}
          disableSaveButton={buttonDisable}
          handleNext={() => {}}
          handlePrevious={() => {}}
        />
      )}
    </Box>
  );
};

export default AudienceList;
