import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import {
  getCustomWriteAccess,
} from "../CommonComponents/Common";
import {
  editProjectsAction,
  getProjectByIdAction,
} from "../Projects/Store/projects.action";
import NoticeAlert from "../Common/Alert/NoticeAlert";
import BaseButton from "../Common/Buttons/BaseButton";

function Messaging() {
  const { projectId } = useParams<{ projectId: string }>();
  const reducer: any = useSelector((state) => state);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    smsUrl: "",
    whatsAppUrl: "",
    emailUrl: "",
    spin: false,
  });
  const {
    ProjectReducer: { selectedProject },
  } = reducer;

  const { whatsAppUrl, smsUrl, emailUrl, spin } = state;
  useEffect(() => {
    dispatch(updateCurrentPath("Messaging", ""));
    setState((prev) => ({
      ...prev,
      whatsAppUrl: selectedProject?.whatsAppUrl || "",
      smsUrl: selectedProject?.smsUrl || "",
      emailUrl: selectedProject?.emailUrl || "",
    }));
  }, [selectedProject]);
  const onChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const saveCommands = async () => {
    setState((prev) => ({
      ...prev,
      spin: true,
    }));

    let obj = {
      whatsAppUrl,
      smsUrl,
      emailUrl,
    };
    await dispatch(editProjectsAction(obj, projectId));
    await dispatch(getProjectByIdAction(projectId));
    setState((prev) => ({
      ...prev,
      spin: false,
    }));
  };
  return (
    <div>
      <Typography fontWeight={600} fontSize={20} color="#181818">
        Messaging
      </Typography>
      <Typography fontWeight={500} fontSize={16} color="#555555" mt={0.5}>
        Manage your Whatsapp, SMS and Email here
      </Typography>
      <NoticeAlert
        title="Note: Please enter a valid curl command"
        content={`Please replace receiver's number with {PhoneNum} for sending an
            Whatsapp message and SMS. Also please enter the valid email address
            in the custom parameters "email" in the audience for sending an
            Email`}
        variant="info"
      />

      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Box flex={1}>
          <Typography
            color="#181818"
            fontSize={14}
            fontWeight={500}
            gutterBottom
          >
            WhatsApp Link
          </Typography>

          <TextField
            name="whatsAppUrl"
            value={whatsAppUrl}
            onChange={onChange}
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            placeholder="Enter curl whatsapp command here"
            sx={styles.textInput}
          />
        </Box>
        <Box flex={1}>
          <Typography
            color="#181818"
            fontSize={14}
            fontWeight={500}
            gutterBottom
          >
            SMS Link
          </Typography>

          <TextField
            name="smsUrl"
            value={smsUrl}
            onChange={onChange}
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            placeholder="Enter curl custom sms command here"
            sx={styles.textInput}
          />
        </Box>
        <Box flex={1}>
          <Typography
            color="#181818"
            fontSize={14}
            fontWeight={500}
            gutterBottom
          >
            Email Link
          </Typography>
          <TextField
            name="emailUrl"
            value={emailUrl}
            onChange={onChange}
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            placeholder="Enter curl email command here"
            sx={styles.textInput}
          />
        </Box>
      </Box>

      {getCustomWriteAccess(reducer) === "write" && (
        <Box my={3} display="flex" justifyContent="flex-end" >

          <BaseButton
            title="Update Links"
            variant="contained"
            loading={spin}
            handleClick={saveCommands}
          />
        </Box>
      )}
    </div>
  );
}

export default Messaging;

const styles = {
  textInput: {
    "& .MuiOutlinedInput-root": {
      fontSize: 13,
      fontWeight: 400,
      borderRadius: 2,
      borderColor: "#CCD2DE",
      borderWidth: "1px",
      transition: "border-color 0.3s ease",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      p: 1.5,
      "&::placeholder": {
        color: "#555555",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
};
