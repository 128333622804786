import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Spreadsheet from "react-spreadsheet";
import { ReactComponent as NextIcon } from "../../../assets/icons/next.svg";
import { ReactComponent as PreviousIcon } from "../../../assets/icons/previous.svg";
import CustomScrollbars from "../CommonComponents/CustomScrollbars";

const Sheets = ({ sheetsData, currentPage, handlePrevious, handleNext }) => {
  return (
    <>
      <CustomScrollbars height="50vh">
        {!sheetsData || sheetsData.length === 0 ? (
          <p style={{ textAlign: "center", padding: "10px" }}>
            No Data Available
          </p>
        ) : (
          <div className="spreadsheet-container">
            <Spreadsheet data={sheetsData} />
          </div>
        )}
      </CustomScrollbars>
      <Stack
        direction="row"
        sx={styles.bottomContainer}
        className="pagination-container"
      >
        <Box color="#555555" display="flex" gap={1.5} alignItems="center">
          <Typography fontSize={12} fontWeight={500}>
            Total{" "}
          </Typography>
          <Typography
            component="span"
            fontSize={12}
            fontWeight={500}
            border={1}
            borderColor="#CCD2DE"
            px={1.5}
            py={0.25}
            borderRadius={2}
          >
            {`${sheetsData?.length || 0} row(s)`}
          </Typography>
        </Box>

        <Box display="flex" gap={3}>
          <Button
            variant="outlined"
            startIcon={
              <PreviousIcon
                style={{
                  opacity: currentPage === 1 ? 0.5 : 1,
                  color: currentPage === 1 ? "#B0B0B0" : "#666666",
                }}
              />
            }
            onClick={handlePrevious}
            disabled={currentPage === 1}
            sx={styles.paginationButtons}
          >
            Previous
          </Button>

          <Button
            variant="outlined"
            endIcon={
              <NextIcon
              // style={{
              //   opacity: currentPage === totalPages ? 0.5 : 1,
              //   color: currentPage === totalPages ? "#B0B0B0" : "#666666",
              // }}
              />
            }
            onClick={handleNext}
            // disabled={currentPage === totalPages}
            sx={styles.paginationButtons}
          >
            Next
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default Sheets;

const styles = {
  bottomContainer: {
    p: 1,
    paddingLeft: 3,
    border: "1px solid #CCD2DE",
    gap: 2,
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  paginationButtons: {
    textTransform: "capitalize",
    borderColor: "#CCD2DE",
    color: "#666666",
    fontWeight: 500,
    fontSize: 12,
    borderRadius: 2,
    padding: "4px 20px",
    "&:hover": { borderColor: "#5AA552" },
  },
};
