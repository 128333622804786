import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../CommonComponents/Common";
import {
  swalErrorMessage,
  swalWarningMessage,
} from "../../../../Constant/swalMessage";
import {
  getCampaignDetailsAllAction,
  getInsightsCampaignAction,
  saveInsightsCampaignAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import CommonModal from "../../CommonComponents/Modal/CommonModal";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../../assets/Images/Delete.svg";
import AddIcon from "@mui/icons-material/Add";
import { axiosClient, BASE_URL_AI } from "../../../../Constant/apiConstant";
import { useLocation, useParams } from "react-router-dom";

function CreateInsightModal({ campaignData, campaignName, show, toggle }) {
  const [state, setState] = useState<any>({
    questions: [{ question: "", key: "", type: "" }],
    about: "",
    buttonDisable: false,
    disconnectedArray: [],
    errMsg: "",
  });

  const location = useLocation();

  const dispatch = useDispatch();
  const locSearch = new URLSearchParams(location.search);
  let campaignRuns = locSearch.get("campaignRuns");
  const { projectId } = useParams<{ projectId: string }>();

  const reducer: any = useSelector((state) => state);

  const {
    CampaignReducer: { campaignInsights },
  } = reducer;

  useEffect(() => {
    const init = async () => {
      let obj = {
        pageIndex: 1,
        pageSize: 1000,
        live: false,
        callStatus: ["703", "712"],
      };
      let res: any = await dispatch(
        getCampaignDetailsAllAction(projectId, campaignRuns, obj)
      );
      if (res?.length > 0) {
        setState((prev) => ({
          ...prev,
          disconnectedArray: res,
          errMsg: "",
        }));
      } else {
        setState((prev) => ({
          ...prev,
          disconnectedArray: [],
          errMsg: "This campaign is not experiencing any connected calls.",
        }));
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await dispatch(getInsightsCampaignAction(campaignData?._id));
    };
    init();

    return () => {
      const init = async () => {
        await dispatch(getInsightsCampaignAction(campaignData?._id, true));
      };
      init();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(campaignInsights)) {
      setState((prev) => ({
        ...prev,
        questions: [...campaignInsights?.questions],
        about: campaignInsights?.about,
      }));
    }
  }, [campaignInsights]);

  const { questions, about, buttonDisable, disconnectedArray, errMsg } = state;

  const addMoreColumn = (idx) => {
    if (
      questions[idx]?.question &&
      questions[idx]?.key &&
      questions[idx]?.type
    ) {
      let add = { question: "", key: "", type: "" };

      setState((prev) => ({
        ...prev,
        questions: [add, ...prev.questions],
      }));
    } else {
      swalWarningMessage("Please fill existing one first");
    }
  };

  const handleChange = (e, idx, val) => {
    let cloneData = state.questions;
    if (val === "key") {
      cloneData[idx][val] = e.target.value.split(" ").join("");
    } else {
      cloneData[idx][val] = e.target.value;
    }
    setState((prev) => ({
      ...prev,
      questions: cloneData,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let sendRecordingData: Array<object> = [];
    for (const item of disconnectedArray) {
      let obj = {
        call_uuid: item?.call_uuid || "",
        phone_number: item?.phone,
      };
      sendRecordingData.push(obj);
    }

    let err = 0;
    let required = ["question", "type", "key"];
    for (const item of questions) {
      required.forEach((o) => {
        if (!item[o]) {
          err++;
        }
      });
    }
    if (err) {
      swalErrorMessage("Please fill all the field first");
      return;
    } else {
      setState((prev) => ({ ...prev, buttonDisable: true }));
      let obj = {
        campaign_name: projectId,
        campaign_run_id: campaignRuns,
        questions: [...questions],
        about: about,
        recording_data: sendRecordingData,
      };
      let res = await axiosClient.post(`${BASE_URL_AI}/prompt/entities`, obj);
      let obj1 = {
        questions: [...questions],
        about: about,
        campaignId: campaignData?._id,
      };
      await dispatch(saveInsightsCampaignAction(obj1));
    }

    setState((prev) => ({ ...prev, buttonDisable: false }));
    toggle();
  };
  const removeQuestion = (idx) => {
    let cloneData = state.questions;
    cloneData.splice(idx, 1);
    setState((prev) => ({
      ...prev,
      questions: cloneData,
    }));
  };

  return (
    <CommonModal
      show={show}
      toggle={toggle}
      size={800}
      handleSave={handleSubmit}
      heading="Create Insight"
      body={
        <Box>
          <Box
            mt={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography {...styles.label}>Question</Typography>
            <Typography {...styles.label}>Name</Typography>
            <Typography {...styles.label}>Type</Typography>
            <Typography {...styles.label} mr={4.5}></Typography>
          </Box>
          {state?.questions.map((data, idx) => (
            <Box key={idx}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <TextField
                  placeholder="Enter Question"
                  value={data.question}
                  onChange={(e) => handleChange(e, idx, "question")}
                  sx={{
                    ...styles.textInput,
                    width: "30%",
                  }}
                />
                <TextField
                  placeholder="Enter Name"
                  value={data.key}
                  onChange={(e) => handleChange(e, idx, "key")}
                  sx={{
                    ...styles.textInput,
                    width: "30%",
                  }}
                />
                <Select
                  displayEmpty
                  value={data.type}
                  onChange={(e) => handleChange(e, idx, "type")}
                  sx={{
                    ...styles.typeSelect,
                    width: "30%",
                    "& .MuiOutlinedInput-input": {
                      p: 1.5,
                      fontSize: 13,
                      fontWeight: 400,
                      color: "#666666",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                      },
                    },
                  }}
                >
                  <MenuItem value="">Please Choose</MenuItem>
                  <MenuItem value="string">Text</MenuItem>
                  <MenuItem value="integer">Number</MenuItem>
                  <MenuItem value="boolean">True/false</MenuItem>
                </Select>
                <IconButton
                  disabled={state.questions.length === 1}
                  onClick={() => removeQuestion(idx)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>

              {idx === 0 && (
                <Box
                  mt={0.5}
                  sx={{
                    justifyItems: "flex-start",
                    py: 1,
                  }}
                >
                  <Button
                    onClick={() => addMoreColumn(idx)}
                    variant="outlined"
                    startIcon={
                      <AddIcon
                        style={{
                          color: "#666666",
                          padding: 1,
                          backgroundColor: "white",
                        }}
                      />
                    }
                    sx={styles.addBtn}
                  >
                    Add
                  </Button>
                </Box>
              )}
            </Box>
          ))}
          <Box
            component={"span"}
            mt={1.5}
            sx={{ color: "red", fontSize: "12px" }}
          >
            {errMsg}
          </Box>
        </Box>
      }
      saveButtonText="Create"
      disableSaveButton={errMsg}
    />
  );
}

export default CreateInsightModal;

const styles = {
  label: {
    color: "#1A1D23",
    fontSize: 14,
    fontWeight: "medium",
    mb: 1,
  },
  addBtn: {
    textTransform: "none",
    color: "#656E7F",
    border: 0,
    backgroundColor: "#F1F4FA",
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 2,
    padding: "4px 10px",
    "&:hover": {
      transition: "width 0.5s ease-in-out",
      border: 0,
    },
  },
  typeSelect: {
    width: "100px",
    fontSize: 14,
    fontWeight: 400,
    borderColor: "#D1D9EF",
    borderRadius: 1,
    color: "#9E9FA1",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
    },
  },
  textInput: {
    "& .MuiOutlinedInput-root": {
      fontSize: 13,
      fontWeight: 400,
      borderRadius: 2,
      color: "#666666",
      borderColor: "#CCD2DE",
      borderWidth: "1px",
      transition: "border-color 0.3s ease",
    },
    "& .MuiOutlinedInput-input": {
      p: 1.5,
      "-webkit-text-fill-color": "#000 !important",
      "&::placeholder": {
        color: "#555555",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
};
