import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { GoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { swalErrorMessage } from "../../../Constant/swalMessage";
import eyeSlash from "../../../assets/icons/eye-slash.svg";
import eye from "../../../assets/icons/eye.svg";
import { googleLoginAction, loginAction } from "../Actions/CommonActions";
import { goToMentionedRoutes, LoadingSlides } from "../CommonComponents/Common";
import { checkValidationAccountAction } from "../Store/Account/Account.action";
import authStyles from "./styles";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [googleButtonDisable, setGooogleButtonDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { email, password } = loginData;
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    const init = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const res: any = await dispatch(
          checkValidationAccountAction({ token: `Bearer ${token}` })
        );

        if (res?.errMsg === "authorized") {
          await handleAccountRedirection(res?.data);
        }
      }
    };

    init();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setSpin(true);
    try {
      const obj = {
        email: email.trim(),
        password: password,
      };
      const res: any = await dispatch(loginAction(obj));
      if (res) {
        await handleAccountRedirection(res); 
      }
    } catch (error) {
      swalErrorMessage("Email or Password is incorrect");
    }
    setSpin(false);
  };

  const googleResponse = async (response) => {
    setGooogleButtonDisable(true);
    if (response.tokenId) {
      const obj = {
        token: response.tokenId,
      };
      const res: any = await dispatch(googleLoginAction(obj));
      if (res) {
        await handleAccountRedirection(res); 
      }
    }
    setGooogleButtonDisable(false);
  };

  const onFailure = (error) => {
    if (error?.err) swalErrorMessage(error?.err);
  };

 const handleAccountRedirection = async (
    res
  ) => {
    const { accounts, lastLogin, _id } = res;
    const find =
      accounts?.find((acc) => acc?._id === lastLogin?.accountId) ||
      accounts?.[0];
    if (find && !find.accountName) {
      localStorage.setItem("account-id", find?._id);
      history.push(`/account-name`);
    } else {
      await goToMentionedRoutes(find, _id, history, dispatch);
    }
  };
  

  return (
    <Box className="main-container">
      <Typography color="#656E7F" fontSize={14} fontWeight={500}>
        Welcome back!
      </Typography>
      <Typography fontSize={28} fontWeight={700} mb={1} color="#1A1D23">
        Login
      </Typography>
      <GoogleLogin
        buttonText="Login with google"
        disabled={googleButtonDisable}
        className="newGoogleLogin"
        clientId={process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID || ""}
        onSuccess={googleResponse}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        icon={true}
      />
      <Box sx={{ mt: 1, mb: 2, display: "flex", alignItems: "center" }}>
        <Divider sx={{ flexGrow: 1 }} />
        <Typography sx={{ px: 2 }}>or</Typography>
        <Divider sx={{ flexGrow: 1 }} />
      </Box>
      <form onSubmit={handleLogin}>
        <Box mb={2}>
          <Typography {...authStyles.label}>Email</Typography>
          <TextField
            name="email"
            value={email?.toLowerCase()}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your registered email"
            sx={authStyles.textInput}
          />
        </Box>
        <Box mb={1}>
          <Typography {...authStyles.label}>Password</Typography>
          <TextField
            name="password"
            value={password}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            placeholder="Enter your password"
            sx={authStyles.textInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    edge="end"
                    sx={{ padding: "0", marginRight: 1.5 }}
                    disableRipple
                    disableFocusRipple
                    style={{ outline: "none" }}
                  >
                    <img
                      src={showPassword ? eyeSlash : eye}
                      alt="password visibility"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            component={"span"}
            onClick={() => history.push("/reset-password")}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              textDecoration: "none",
              cursor: "pointer",
            }}
            mt={2}
            color="#4280FF"
            fontSize={13}
          >
            Forgot Password?
          </Box>
        </Box>
        <Button
          type="submit"
          disabled={spin}
          variant="contained"
          sx={authStyles.loginButton}
          fullWidth
        >
          {spin ? <LoadingSlides bgColor="white" /> : "Login"}
        </Button>
      </form>
      <Typography
        color="#1A1D23"
        fontSize={14}
        fontWeight={400}
        textAlign="center"
        mt={3}
      >
        Don’t have an account?{" "}
        <Box
          component={"span"}
          onClick={() => history.push("/register")}
          sx={{
            color: "#4280FF",
            fontWeight: "bold",
            cursor: "pointer",
            ":hover": { textDecoration: "underline" },
          }}
        >
          Sign-up
        </Box>
      </Typography>
    </Box>
  );
};

export default Login;
