import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Scrollbars } from "react-custom-scrollbars";
import { axiosClient } from "../../../Constant/apiConstant";
import { renderThumb } from "../CommonComponents/Common";
import BaseButton from "../Common/Buttons/BaseButton";

const BoxLoader = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width="404px"
        sx={{
          backgroundColor: "#F3F6FD",
          borderRadius: "6px",
        }}
      />
      <Skeleton
        variant="text"
        width="596px"
        sx={{
          backgroundColor: "#F3F6FD",
          borderRadius: "6px",
        }}
      />
      <Skeleton
        variant="text"
        width="490px"
        sx={{
          backgroundColor: "#F3F6FD",
          borderRadius: "6px",
        }}
      />
      <Skeleton
        variant="text"
        width="308px"
        sx={{
          backgroundColor: "#F3F6FD",
          borderRadius: "6px",
        }}
      />
    </>
  );
};

const CallTranscriptModal = ({ show, onClose, selectedTranscript }) => {
  const [localState, setLocalState] = useState({
    summaryView: false,
    summary: "",
    buttonTrue: false,
    noSummary: false,
    language: "English",
    summarySpin: true,
  });

  const [messages, setMessages] = useState([]);
  const [spin, setSpin] = useState(true);
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      let res = await axiosClient.get(
        `${process.env.REACT_APP_BASE_URL_AI}/analyze/transcript/${selectedTranscript?.call_uuid}`
      );
      setMessages(res?.data?.transcription || []);
      setSpin(false);
      // viewCallSummary("English");
    };

    fetchMessages();
  }, [selectedTranscript?.call_uuid]);

  const viewCallSummary = async (language = localState.language) => {
    setShowSummary((prev) => !prev);
    setLocalState((prev) => ({
      ...prev,
      summaryView: true,
      buttonTrue: true,
      summarySpin: true,
    }));
    let res = await axiosClient.post(
      `${process.env.REACT_APP_BASE_URL_AI}/analyze/summary`,
      {
        call_uuid: selectedTranscript?.call_uuid,
        language,
      }
    );

    if (res) {
      if (res.data.summary) {
        setLocalState((prev) => ({
          ...prev,
          summaryView: true,
          summary: res.data.summary,
          buttonTrue: false,
          summarySpin: false,
        }));
      }
    } else {
      setLocalState((prev) => ({
        ...prev,
        summaryView: true,
        buttonTrue: false,
        noSummary: true,
        summarySpin: false,
      }));
    }
  };

  const { summaryView, summary, buttonTrue, language, summarySpin } =
    localState;

  const Message = ({ message, isUser }) => {
    const isUserMessage = isUser === "input";
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: isUserMessage ? "flex-end" : "flex-start",
          my: 1.5,
        }}
      >
        <Box
          sx={{
            backgroundColor: isUserMessage ? "#EDF3FF" : "#F3F6FD",
            px: 2,
            py: 1,
            borderRadius: isUserMessage
              ? "30px 0px 50px 50px"
              : "0px 50px 50px 30px",
            maxWidth: "80%",
          }}
        >
          <Typography
            sx={{
              color: "#555555",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            {isUserMessage ? "User" : "Bot"}
          </Typography>
          <Typography
            sx={{
              color: "#181818",
              fontSize: "14px",
              fontWeight: "500",
              ml: 1,
            }}
          >
            {message.value}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={{
          maxWidth: "800px",
          margin: "auto",
          marginTop: "2%",
          borderRadius: 2,
          boxShadow: 24,
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            pt: 3,
            pb: 2,
            bgcolor: "#F1F4FA",
            borderRadius: 2,
          }}
        >
          <Typography color="#181818" fontSize={14} fontWeight="600">
            Call Transcript
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              color: "#62656E",
              height: 8,
              width: 8,
              p: 1,
              borderRadius: 0.75,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Scrollbars
          style={{
            backgroundColor: "white",
            height: "300px",
            borderRadius: "8px",
            pt: 2,
          }}
          renderThumbVertical={renderThumb}
        >
          <Box height="100%" sx={{ px: 3, position: "relative" }}>
            {spin ? (
              <CircularProgress
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            ) : (
              messages.map((message: any, index) => (
                <Message key={index} message={message} isUser={message.type} />
              ))
            )}
          </Box>
        </Scrollbars>

        <Box
          sx={{ mx: 3, py: 3, borderTop: "1px solid #CCD2DE" }}
          minHeight="100px"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography color="#181818" fontSize={14} fontWeight={600}>
              {summaryView
                ? "Summary of the call"
                : "You can now generate summary of the call"}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              justifyContent="flex-end"
            >
              <Select
                value={language}
                onChange={(e) => {
                  setLocalState((prev) => ({
                    ...prev,
                    language: e.target.value,
                    summaryView: false,
                  }));
                  setShowSummary(false);
                }}
                displayEmpty
                sx={{
                  bgcolor: "#fff",
                  fontSize: 14,
                  fontWeight: 400,
                  borderColor: "#CCD2DE",
                  borderRadius: 1,
                  width: "190px",
                  px: "10px",
                  py: "4px",
                  height: "40px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="German">German</MenuItem>
                <MenuItem value="Korean">Korean</MenuItem>
              </Select>
              <BaseButton
                variant="contained"
                title={showSummary ? "Hide Summary" : "View Summary"}
                handleClick={() => viewCallSummary(language)}
                disabled={buttonTrue}
              />
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: "100px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
            }}
          >
            {showSummary && (
              <Typography fontSize={14} fontWeight={500} mt={2}>
                {summarySpin ? <BoxLoader /> : summary}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CallTranscriptModal;
