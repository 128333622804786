import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as FileUploadIcon } from "../../../assets/Images/FileUpload.svg";
import { swalWarningMessage } from "../../../Constant/swalMessage";

const FileUploadComponent = ({ setState }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]) as any;
  const [fileInputKey, setFileInputKey] = useState(0);

  const onSave = (_file) => {
    const saveFil = _file.length > 0 ? _file[0] : null;
    setState((prev) => ({
      ...prev,
      file: saveFil,
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    processFiles(files);
  };

  const processFiles = (files) => {
    if (uploadedFiles.length >= 1 || files.length > 1) {
      swalWarningMessage("You can only upload one file at a time.");
      return;
    }
    setUploadedFiles([...files]);
    onSave(files);
  };

  const handleFileDelete = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setState((prev) => ({
      ...prev,
      file: "",
    }));
    setFileInputKey((prev) => prev + 1);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  return (
    <>
      <Box
        sx={{
          border: "1px dashed #D1D9EF",
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F7F9FC",
            pt: 1,
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <FileUploadIcon />
          <Typography sx={{ color: "#1C233E", fontSize: 14, fontWeight: 500 }}>
            Upload your files
          </Typography>
          <span style={{ color: "#8792A7", fontSize: 12 }}>
            Drag and drop files here or
          </span>
          <Button
            variant="text"
            component="label"
            sx={{
              textTransform: "none",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <span
              style={{
                color: "#4280FF",
                fontSize: 12,
                textDecoration: "underline",
              }}
            >
              choose files
            </span>
            <input
              key={fileInputKey}
              type="file"
              hidden
              multiple
              accept=".xlsx"
              onChange={handleFileChange}
            />
          </Button>
        </Box>
      </Box>
      {uploadedFiles?.length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
          {uploadedFiles.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingX: 1,
                color: "#000",
              }}
            >
              {file.name}
              <IconButton
                onClick={() => handleFileDelete(index)}
                size="small"
                sx={{ color: "#FF0000" }}
              >
                <Close />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default FileUploadComponent;
