import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "react-voice-recorder/dist/index.css";
import { isEmpty, PAGE_SIZE_50 } from "../CommonComponents/Common";
import { getAudienceAction } from "../ProjectSection/AudienceSection/Store/Audience.action";
import TabsSection from "./TabsSection";

const AudienceForm = ({ obj }) => {
  const dispatch = useDispatch();
  const { projectId }: any = useParams();
  let { formState, setFormState, formobj, setState, state } = obj;
  const { audienceData } = formState;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      file: "",
    }));
    
    return () => {
      setFormState(formobj);
      setState((prev) => ({
        ...prev,
        editedAudience: {},
      }));

      const init1 = async () => {
        const obj = {
          pageIndex: 1,
          pageSize: PAGE_SIZE_50,
          searchText: state?.searchText,
        };
        await dispatch(getAudienceAction(projectId, obj));
      };
      init1();
    };
  }, []);


  return (
    <Box>
      <Box flex={1}>
        <Typography {...styles.label}>Name</Typography>
        <TextField
          name="name"
          value={formState.name.value}
          disabled={!isEmpty(state?.editedAudience)}
          onChange={(e) =>
            setFormState({
              ...formState,
              name: { error: "", value: e.target.value },
            })
          }
          variant="outlined"
          fullWidth
          placeholder="Enter the audience list name it can be “Doctor’s List”"
          sx={styles.textInput}
        />
      </Box>
      <TabsSection
        formState={formState}
        setFormState={setFormState}
        state={state}
        setState={setState}
        audienceData={audienceData}
      />
    </Box>
  );
};

export default AudienceForm;

const styles = {
  label: {
    color: "#1A1D23",
    fontSize: 14,
    fontWeight: "medium",
    mb: 1,
  },
  textInput: {
    "& .MuiOutlinedInput-root": {
      fontSize: 13,
      fontWeight: 400,
      borderRadius: 2,
      color: "#666666",
      borderColor: "#CCD2DE",
      borderWidth: "1px",
      transition: "border-color 0.3s ease",
    },
    "& .MuiOutlinedInput-input": {
      p: 1.5,
      "-webkit-text-fill-color": "#000 !important",
      "&::placeholder": {
        color: "#555555",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
};
