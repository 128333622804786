import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectAccess,
  isVodexLoggedin,
  LoadingDots,
  LoadingSlides,
  PAGE_SIZE_50,
} from "../CommonComponents/Common";
import { lastCampaignActionList } from "../ProjectSection/CampaignSection/Store/Campaign.action";
import {
  getDashboardChartAction,
  getDashboardDataAction,
} from "../Store/Account/Account.action";
import BarChart from "./BarChart";
import CampaignList from "./CampaignList";
import DoughnutChart from "./DoughnutChart";
import Heading from "./Heading";
import SummaryCard from "./SummaryCard";
import "./dashboard.css";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import VideoComponentCustom from "../CommonComponents/videomodalCustom";
declare global {
  interface Window {
    intercomSettings: {
      api_base: string;
      app_id: string;
      email: string;
      name: string;
    };
  }
}
interface CampaignData {
  campaignName: string;
  lastCampaignRun: string;
  projectName: string;
  completed: boolean;
  redial: boolean;
  suspended: boolean;
  _id: string;
}

const DashboardHome = () => {
  const dispatch = useDispatch();
  const {
    AccountReducer: {
      userDetails: { email, accounts, _id, firstName },
      accountRoles,
      dashboardData,
      chartData,
      selectedAccount,
    },
    ProjectReducer: { projectList },
  } = useSelector((state: any) => state);

  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [totalCampaignsCount, setTotalCampaignsCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastCampaignListData, setLastCampaignListData] =
    useState<CampaignData[]>();
  const [state, setState] = useState({
    projectId: "",
    from: "",
    to: "",
  }) as any;

  const [filterState, setFilterState] = useState({
    selectedprojectId: state?.projectId,
    dateRange: [
      state?.from ? new Date(state?.from) : null,
      state?.to ? new Date(state?.to) : null,
    ],
  }) as any;

  useEffect(() => {
    if (window.location.origin === "https://app.voizer.ai") {
      // if (window.location.origin === "http://localhost:3000") {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "rvqfqg0r",
        email: email || "",
        name: firstName || email || "",
      };

      (function () {
        var w: any = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i: any = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/rvqfqg0r";
            var x: any = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }

    setLoading(true);
    fetchData(state);
  }, []);

  const fetchData = async (params = state) => {
    await dispatch(getDashboardDataAction(params));
    await dispatch(getDashboardChartAction(params));

    getCampaigns(params);
    setLoading(false);
    setRefreshLoading(false);
  };

  const getCampaigns = async (params) => {
    const campaignParams = {
      ...params,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
    };

    const res: any = await dispatch(lastCampaignActionList(campaignParams));
    const lastCampaigns = res?.[0];

    setLastCampaignListData(lastCampaigns?.data || []);
    setTotalCampaignsCount(lastCampaigns?.totalCount);

    if (lastCampaigns?.data?.length > 0) {
      const uniqueProjectIds = Array.from(
        new Set(lastCampaigns.data.map((row) => row.projectId))
      );
      for (const projectId of uniqueProjectIds) {
        getProjectAccess(projectId, accountRoles, dispatch);
      }
    }
    setTableLoading(false);
  };

  const applyFilters = (refresh = false) => {
    const [newStartDate, newEndDate] = filterState?.dateRange;
    if (newStartDate && !newEndDate) {
      swalWarningMessage("Please choose the end date");
      return;
    }

    refresh ? setRefreshLoading(true) : setLoading(true);

    const updated = {
      projectId: filterState?.selectedprojectId,
      from: newStartDate ? newStartDate.toISOString() : "",
      to: newEndDate ? newEndDate.toISOString() : "",
    };
    setState((prev) => ({
      ...prev,
      ...updated,
    }));
    fetchData(updated);
  };

  const resetFilters = async () => {
    setRefreshLoading(true);
    const params = {
      projectId: "",
      from: "",
      to: "",
    };
    fetchData(params);

    setFilterState((prev) => ({
      ...prev,
      selectedprojectId: "",
      dateRange: [null, null],
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setTableLoading(true);
    getCampaigns(state);
  };

  const { totalCost, balance, remainingMinutes, onHoldBalance } = dashboardData;

  const summaryCards = [
    {
      title: "Account Balance",
      circleColor:
        "linear-gradient(180deg, rgba(22, 200, 199, 0.8) 0%, rgba(106, 233, 233, 0.8) 100%)",
      value: balance ? `$ ${balance}` : <LoadingDots />,
      errorMessage: selectedAccount?.balanceExpired,
    },
    {
      title: "Consumed Cost",
      circleColor: "linear-gradient(268.11deg, #FFDA8F 9.11%, #EEB644 105.62%)",
      value: totalCost ? `$ ${totalCost}` : <LoadingDots />,
    },
    {
      title: "Remaining Mins",
      circleColor: "linear-gradient(60.26deg, #79EE81 15.53%, #26C231 89.39%)",
      value: remainingMinutes,
    },
    {
      title: "On Hold Balance",
      circleColor: "linear-gradient(180deg, #8FA8FF 0%, #4265E2 100%)",
      value: onHoldBalance ? `$ ${onHoldBalance}` : <LoadingDots />,
    },
  ];

  return (
    <Box height={100} position={"relative"}>
      {window?.location?.origin === "https://app.voizer.ai" && (
        <VideoComponentCustom />
      )}
      {loading ? (
        <Box sx={styles.loadingContainer}>
          <LoadingSlides bgColor="#FF5E00" />
        </Box>
      ) : (
        <Box p={1}>
          <Heading
            projectList={projectList}
            filterState={filterState}
            setFilterState={setFilterState}
            resetFilters={resetFilters}
            applyFilters={applyFilters}
            refreshLoading={refreshLoading}
          />
          <Grid mt={0.25} mb={2} container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {summaryCards.map(
                  ({ title, circleColor, value, errorMessage }, index) => (
                    <Grid item xs={12} md={3} key={index}>
                      <SummaryCard
                        title={title}
                        circleColor={circleColor}
                        value={value}
                        errorMessage={errorMessage}
                      />
                    </Grid>
                  )
                )}
                <Grid item xs={12}>
                  <BarChart chartData={chartData} state={state} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <DoughnutChart chartData={dashboardData} />
            </Grid>
          </Grid>

          <CampaignList
            lastCampaignListData={lastCampaignListData}
            totalCampaigns={totalCampaignsCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            tableLoading={tableLoading}
          />
        </Box>
      )}

      {isVodexLoggedin() && (
        <div className="floating-widget">
          {/* Widget content */}
          <i
            className="fab fa-discord"
            title="Connect with us on discord"
            onClick={() =>
              window.open(
                "https://discord.com/channels/1139884697221070848/1139885671713095740",
                "_blank"
              )
            }
          ></i>{" "}
        </div>
      )}
      {/* {isVodexLoggedin() && (
        <div className="floating-widget-youtube">
          <i
            className="fab fa-youtube"
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=4nSIwHPJlTs&list=PLN4jqjD9BdXxOqx7Ex0S6doiwdooGfwAK",
                "_blank"
              )
            }
          ></i>{" "}
        </div>
      )} */}
    </Box>
  );
};

export default DashboardHome;

const styles = {
  loadingContainer: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
};
