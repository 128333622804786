import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { swalSuccessMessage } from "../../../../Constant/swalMessage";
import { BASE_URL_AI, axiosClient } from "../../../../Constant/apiConstant";
import { NoDataFound, isEmpty } from "../../CommonComponents/Common";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch } from "react-redux";
import { getCampaignDetailsAllAction } from "../../ProjectSection/CampaignSection/Store/Campaign.action";

function InsightsViewModal({
  insightsPopup,
  toggle,
  campaignRunListData,
  campaignName,
  all = false,
}) {

  const [allCampaignRunDetails, setAllCampaignRunDetails] = useState<any>([])

  const dispatch = useDispatch()
  let campaignDetails: any = localStorage.getItem("campaign");
  let data = JSON.parse(campaignDetails);
  const { projectId } = useParams<{ projectId: string }>();

  const getName = (phone) => {
    let nameFind1 = allCampaignRunDetails?.find((num) => num.phone === phone);
    if (nameFind1) {
      return {
        firstName: nameFind1?.customParameters?.firstName || "--",
        lastName: nameFind1.customParameters?.lastName || "--",
        recordingUrl: nameFind1?.callRecordingUrl || "--",
      };
    } else {
      return {
        firstName: "--",
        lastName: "--",
        recordingUrl: "",
      };
    }
  };
  const [state, setState] = useState<any>({
    questions: [{ question: "", key: "", type: "" }],
    about: "",
    data: [],
    apiPopup: false,
    isDisabled: false,
    exportSpin: false,
    selectedData: [],
  });

  const [apiData, setApiData] = useState({});

  const location = useLocation();

  const locSearch = new URLSearchParams(location.search);
  let campaignRuns = locSearch.get("campaignRuns");
  const { apiPopup, isDisabled, exportSpin, selectedData } = state;
  useEffect(() => {
    const init = async () => {
      let data1 = [];
      if (all) {
        let res = await axiosClient.get(
          `${BASE_URL_AI}/prompt/entities/list/${data._id}`,
          { params: { page: 0, page_size: 0 } }
        );
        data1 = res?.data?.data;
      } else {
        let res = await axiosClient.get(
          `${BASE_URL_AI}/prompt/entities?campaign_name=${projectId}&campaign_run_id=${campaignRuns}`
        );
        data1 = res.data;
      }

      let obj = {
        pageIndex: 1,
        pageSize: 1000,
        live: false,
        callStatus: ["703"],
      };
      let res: any = await dispatch(
        getCampaignDetailsAllAction(projectId, campaignRuns, obj)
      );

      setAllCampaignRunDetails(res)
      setState((prev) => ({
        ...prev,
        data: data1,
      }));
    };

    init();

    return () => {
      setState((prev) => ({
        ...prev,
        data: [],
      }));
    };
  }, []);

  const getData = (output) => {
    if (typeof output === "string") {
      return output;
    }

    let outputData: Array<string> = [];

    if (Array.isArray(output)) {
      output?.map((data) => {
        let strinOb = `${data?.key} : ${data?.value}`;
        outputData.push(strinOb);
      });
    } else {
      return `${output?.key} : ${output?.value}`;
    }

    return outputData.join(",");
  };

  const handleChange = (e) => {
    e.persist();

    setApiData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    setState((prev) => ({
      ...prev,
      isDisabled: true,
    }));
    e.preventDefault();
    let obj = {
      ...apiData,
      campaign_name: projectId,
      campaign_run_id: location.search.split("?")[1],
      data: selectedData.map((da: any) => {
        return {
          recording_url: da.recording_url,
          phone_number: da.phone_number,
          output: da.output,
        };
      }),
    };
    let res = await axiosClient.post(`${BASE_URL_AI}/prompt/export`, obj);

    setState((prev) => ({
      ...prev,
      isDisabled: false,
      apiPopup: false,
    }));
    swalSuccessMessage(res?.data?.status);
  };

  const handlePopup = () => {
    setState((prev) => ({
      ...prev,
      apiPopup: !prev.apiPopup,
    }));
  };

  const ExportCSV = async () => {
    if (selectedData.length > 0) {
      let modifiedArr: Array<object> = [];
      selectedData.forEach((data: any) => {
        let nameFind1 = allCampaignRunDetails?.find(
          (num) => num?.phone === data?.phone_number
        );
        let obj = {
          "First Name": nameFind1?.customParameters?.firstName || "--",
          "Last Name": nameFind1?.customParameters?.lastName || "--",
          "Recording Url": nameFind1?.callRecordingUrl,
          "Phone Number": data.phone_number,
          // Output: getData(data?.output),
        };
        if (!isEmpty(data?.output)) {
          data?.output.forEach((out) => {
            obj[out.key] = out.value;
          });
        }
        modifiedArr.push(obj);
      });
      setState((prev) => ({
        ...prev,
        exportSpin: true,
      }));
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(modifiedArr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Campaign Report ${campaignName}` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };

  const handleCheckboxChange = (e, data, idx) => {
    if (idx === "all") {
      if (!e.target.checked) {
        setState((prev) => ({
          ...prev,
          selectedData: [],
        }));
        return;
      }
      let selectedCopy: any = state.data;
      selectedCopy = selectedCopy.map((da, idx) => {
        return {
          recording_url: da.recording_url,
          phone_number: da.phone_number,
          output: da.output,
          idx: idx,
        };
      });
      setState((prev) => ({
        ...prev,
        selectedData: selectedCopy,
      }));
      return;
    }
    let selectedCopy: any = selectedData;
    if (!e.target.checked) {
      selectedCopy.splice(idx, 1);
    } else {
      let obj = {
        recording_url: data.recording_url,
        phone_number: data.phone_number,
        output: data.output,
        idx: idx,
      };
      selectedCopy.push(obj);
    }

    setState((prev) => ({
      ...prev,
      selectedData: selectedCopy,
    }));
  };
  const getCheckedStatus = (index) => {
    let selectedCopy: any = selectedData;
    return selectedCopy.find((p) => p?.idx === index) ? true : false;
  };
  return (
    <div>
      <Modal size="xl" keyboard={false} show={insightsPopup} onHide={toggle}>
        <Modal.Header closeButton>
          <h6 className="m-0 font-weight-bold text-green">View Insights</h6>

          <div style={{ marginLeft: "60%" }}>
            <button
              className="btn btn-info m-1 no-padding"
              onClick={handlePopup}
              disabled={state?.data?.length === 0}
            >
              {" "}
              Export via api
            </button>
            <button
              className="btn btn-info m-1 no-padding"
              disabled={
                exportSpin ||
                selectedData.length === 0 ||
                state?.data?.length === 0
              }
              onClick={ExportCSV}
            >
              {exportSpin ? "Exporting" : "Export Excel"}
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive portlet">
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <th>
                  <input
                    key={`keyall`}
                    className="mr-1"
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e, {}, "all")}
                    checked={
                      state.data.length !== 0 &&
                      state.data.length === selectedData.length
                    }
                  />
                </th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Number</th>
                <th>Recording Url</th>
                <th>Output</th>
              </thead>
              <tbody>
                {state?.data.length > 0
                  ? state?.data?.map((data, idx) => {
                    let nameData = getName(data?.phone_number);
                    return (
                      <tr key={idx}>
                        <td>
                          <input
                            key={`key${idx}`}
                            className="ml-1"
                            type="checkbox"
                            onChange={(e) =>
                              handleCheckboxChange(e, data, idx)
                            }
                            checked={getCheckedStatus(idx)}
                          />
                        </td>
                        <td>{nameData?.firstName}</td>
                        <td>{nameData?.lastName}</td>
                        <td>{data?.phone_number || "--"}</td>
                        <td>
                          <ReactAudioPlayer
                            src={`https://storage.googleapis.com/botstream-recordings/${data?.call_uuid}.mp3`}
                            controls
                            style={{
                              minWidth: "40px",
                              height: "30px",
                            }}
                          />
                        </td>
                        {/* {console.log(data.output)} */}
                        <td>{getData(data?.output)}</td>
                      </tr>
                    );
                  })
                  : NoDataFound(6)}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg" keyboard={false} show={apiPopup} onHide={handlePopup}>
        <form onChange={handleChange} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title> Save Insights</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              placeholder="Enter Url"
              required
              className="form-control m-1"
              name="url"
            />
            <input
              placeholder="Enter Authorization token"
              required
              className="form-control m-1"
              name="authorization_token"
            />
            <select
              required
              name="authenticated"
              placeholder="Select Authenticated"
              className="form-control m-1"
            >
              <option value={""}>Select Is API Authenticated</option>
              <option value={"true"}>True</option>
              <option value={"false"}>False</option>
            </select>
            <input
              placeholder="Enter Authorization Key"
              required
              className="form-control m-1"
              name="authorization_key"
            />
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-success" type="submit">
              {isDisabled ? "Exporting" : "Submit"}
            </button>
            <button className="btn btn-info" onClick={handlePopup}>
              Close
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default InsightsViewModal;
