import React from "react";
import { Agent } from "../types/workflow";
import * as Icons from "lucide-react";

interface AgentSelectorProps {
  agents: Agent[];
  setSelectedAgents: any;
  selectedAgents: any;
}

const AgentSelector: React.FC<AgentSelectorProps> = ({
  agents,
  setSelectedAgents,
  selectedAgents,
}) => {
  const toggleAgent = (agentId: string) => {
    const newSelected = new Set(selectedAgents);
    if (newSelected.has(agentId)) {
      newSelected.delete(agentId);
    } else {
      newSelected.add(agentId);
    }
    setSelectedAgents(newSelected);
  };

  return (
    <div className="modal-container">
      <div className="agents-list">
        {agents.map((agent) => {
          const IconComponent = Icons[agent.icon] as React.FC<{
            size?: number;
            className?: string;
          }>;

          return (
            <div
              key={agent.id}
              className="agent-item"
              onClick={() => toggleAgent(agent.id)}
            >
              <div className="agent-info">
                <div className="agent-details">
                  {IconComponent && (
                    <>
                      <IconComponent size={16} className="agent-icon" />
                      <p className="agent-name">{agent.name}</p>
                    </>
                  )}
                </div>
                <p className="agent-description">{agent.description}</p>
              </div>
              <input
                type="checkbox"
                checked={selectedAgents.has(agent.id)}
                onChange={() => toggleAgent(agent.id)}
                className="agent-checkbox"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AgentSelector;
