import React from "react";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import { Box, Typography } from "@mui/material";

const NoticeAlert = ({ title = "", content = "", variant = "warning" }) => {
  const bannerbackground =
    variant === "warning" ? "#FFF7E6" : variant === "info" ? "#F0FAFF" : "";
  const borderColor =
    variant === "warning" ? "#FFC233" : variant === "info" ? "#00ACFF" : "";
  const textColor =
    variant === "warning" ? "#8A6100" : variant === "info" ? "#0075AD" : "";

  return (
    <Box
      sx={{
        my: 2,
        p: 2,
        backgroundColor: bannerbackground,
        borderRadius: 2,
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
        borderLeft: `2px solid ${borderColor}`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <InfoIcon
          height={20}
          width={20}
          fill={borderColor}
          className="info-icon"
        />
      </Box>
      <Box>
        {title && (
          <Typography
            sx={{
              color:  textColor ,
              fontSize: 14,
              fontWeight: 500,
              marginBottom: 1,
            }}
          >
            {title}
          </Typography>
        )}
        {content && (
          <Typography
            sx={{
              color: textColor ,
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            {content}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default NoticeAlert;
