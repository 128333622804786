import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { ReactComponent as ArrowBack } from "../../../../assets/icons/arrow-back.svg";
import { ReactComponent as Dot } from "../../../../assets/Images/Text.svg";
import BaseButton from "../../Common/Buttons/BaseButton";
import { converTime } from "../../CommonComponents/Common";
import { shortenString } from "../../../../Constant/helper";

const CampaignRunsHeader = ({ campaign, handleBack, handleViewAllRuns }) => {
  const campaignDate = converTime(campaign?.updatedAt);

  return (
    <Box className="flex-between">
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography color="#181818" fontSize={20} fontWeight={600}>
          Campaigns Runs
        </Typography>
        <Box mt={0.5} className="flex-align-center">
          <Typography
            color={"rgba(102, 102, 102, 1)"}
            fontSize={16}
            fontWeight={500}
            textTransform={"capitalize"}
          >
            {shortenString(campaign?.campaignName,30)} -
          </Typography>
          <Typography
            color={"rgba(102, 102, 102, 1)"}
            fontSize={14}
            fontWeight={400}
            component={"span"}
            className="ml-1 flex-align-center"
          >
            {moment(campaignDate, "YYYY-MM-DD hh:mm a").format("Do MMM YYYY")}
            <Dot className="ml-1 mr-1" />
            {moment(campaignDate, "YYYY-MM-DD hh:mm a").format("h:mma")}
          </Typography>
        </Box>
      </Box>
      <Box className="flex-xy-center" alignContent="justify-start" gap={1.5}>
        <BaseButton
          title="View all runs"
          variant="outlined"
          handleClick={handleViewAllRuns}
          styles={{ color: "34280FF" }}
        />
        <BaseButton
          title="Back"
          variant="contained"
          handleClick={handleBack}
          startIcon={<ArrowBack className="back-icon" />}
        />
      </Box>
    </Box>
  );
};

export default CampaignRunsHeader;
