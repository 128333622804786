import { Box } from "@mui/material";
import React from "react";
import { LoadingSlides } from "../../../CommonComponents/Common";
const FilterFooter = ({
  loading,
  clearFilter,
  applyFilter,
  setMainState,
  mainState,
}) => {
  const { durationFilter, statusFilter, outComefilter } = mainState;
  return (
    <Box sx={{ maxHeight: "70px" }}>
      <hr style={{ marginBottom: "3px", marginTop: "18px" }} />

      <Box>
        <Box
          py={0.5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            style={{ minWidth: "70px", height: "35px" }}
            className="project-button mr-2"
            onClick={() => {
              clearFilter("all", true);
              setMainState((prev) => ({ ...prev, showCreateButton: false }));
              localStorage.removeItem("showCreateButton")
            }}
          >
            Cancel
          </button>

          <button
            className="cmn-button"
            style={{ padding: "7.5px 20px" }}
            disabled={
              loading ||
              (!durationFilter &&
                outComefilter?.length === 0 &&
                statusFilter?.length === 0)
            }
            onClick={() => applyFilter("apply")}
          >
            {loading ? <LoadingSlides bgColor="white" /> : "Apply filter"}
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterFooter;
