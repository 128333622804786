import React, { useEffect, useState } from "react";
import CommonTable from "../CommonComponents/CommonTable";
import { useDispatch } from "react-redux";
import {
  getCallGraphAction,
  getCallsListAction,
  getCallsListGatewayAction,
  getCallsListWithAccountPlusGatewaysAction,
  getQueueListAction,
  increaseQueueListAction,
} from "./Store/admin.action";
import CommonModal from "../CommonComponents/CommonModal";
import "../Dashboard/Onboarding.css";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  LoadingDotsSpin,
  NoDataFound,
  renderThumb,
} from "../CommonComponents/Common";
import moment from "moment";
import TopCard from "../../../common/components/TopCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import * as XLSX from "xlsx";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
interface CallGraphData {
  time: string;
  dialed: number;
  connected: number;
  error: number;
  failed: number;
  transferred: number;
  voicemail: number;
}

const removeZeroValueObjects = (data: any[]) => {
  return data.filter((que) => {
    const { account_data, sid_data, data } = que || {};
    let matchData = account_data || sid_data || data;
    if (
      matchData?.dialed === 0 &&
      matchData?.connected === 0 &&
      matchData?.error === 0 &&
      matchData?.misc === 0 &&
      matchData?.voicemail === 0 &&
      matchData?.duration === 0 &&
      matchData?.price === 0
    ) {
      return false;
    }
    return true;
  });
};

function TotalCalls() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [date, setDate] = useState("");
  const [spin, setSpin] = useState(false);
  const [graphData, setGraphData] = useState<CallGraphData[]>([]);
  const [total, setTotal] = useState({
    dialed: 0,
    connected: 0,
    error: 0,
    voicemail: 0,
  });
  const init = async (initial = false) => {
    const previousDay = moment().subtract(1, "days");
    const formattedDate = previousDay.format("D-M-YYYY");
    let filterData = initial ? formattedDate : date;
    if (initial) {
      setDate(formattedDate);
    }
    setSpin(true);
    let res: any = await dispatch(getCallsListAction(filterData));
    let graphRes: any = await dispatch(getCallGraphAction(filterData));
    let kl: any = removeZeroValueObjects(res?.data);
    setData(kl);
    setGraphData(graphRes?.data);
    setSpin(false);
    let res1: any = await dispatch(getCallsListGatewayAction(filterData));
    let kl1: any = removeZeroValueObjects(res1?.data);
    console.log(kl1, "kl1111111");
    setData1(kl1);
    let res2: any = await dispatch(
      getCallsListWithAccountPlusGatewaysAction(filterData)
    );
    let kl2: any = removeZeroValueObjects(res2?.data);
    console.log(kl2, "kl1111111");

    setData2(kl2);
  };

  useEffect(() => {
    init(true);
  }, []);
  const calculateConnectRatio = (connected, dialed) => {
    if (dialed === 0) return 0;
    return (connected / dialed) * 100;
  };
  const labels = graphData?.map((item) => item?.time);
  const callsDialed = graphData?.map((item) => item?.dialed);
  const callsConnected = graphData?.map((item) => item?.connected);
  const connectRatioData = graphData?.map((item) =>
    calculateConnectRatio(item?.connected, item?.dialed)
  );
  const options: ChartOptions<"line"> = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Call Count",
        },
        min: 0,
        grid: {
          drawOnChartArea: true,
        },
      },
      percentage: {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "Connect Ratio (%)",
        },
        min: 0,
        max: 100,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: "Outbound Attempted",
        data: callsDialed,
        borderColor: "#EC8C36",
        backgroundColor: "#EC8C36",
        borderWidth: 3,
        tension: 0,
        pointRadius: 2,
        pointBackgroundColor: "#EC8C36",
        yAxisID: "y",
        fill: false,
      },
      {
        label: "Outbound Connected",
        data: callsConnected,
        borderColor: "#26DE5B",
        backgroundColor: "#26DE5B",
        borderWidth: 3,
        tension: 0,
        pointRadius: 2,
        pointBackgroundColor: "#26DE5B",
        yAxisID: "y",
        fill: false,
      },
      {
        label: "Connect Ratio",
        data: connectRatioData,
        borderColor: "#C800FF",
        backgroundColor: "#C800FF",
        borderWidth: 3,
        tension: 0,
        pointRadius: 2,
        pointBackgroundColor: "#C800FF",
        yAxisID: "percentage",
        fill: false,
      },
    ],
  };
  useEffect(() => {
    const totals = data.reduce(
      (acc, que: any) => {
        acc.dialed += que?.account_data?.dialed || 0;
        acc.connected += que?.account_data?.connected || 0;
        acc.error += que?.account_data?.error || 0;
        acc.voicemail += que?.account_data?.voicemail || 0;

        return acc;
      },
      { dialed: 0, connected: 0, error: 0, voicemail: 0 }
    );
    setTotal(totals);
  }, [data]);

  const exportTableDataToExcel = (
    dataToBeMapped,
    fileName: string = "export.xlsx"
  ) => {
    if (!data || data.length === 0) {
      console.warn("No data available to export.");
      return;
    }

    // Define headers
    const headers1 = [
      "Account Email",
      "Account Name",
      "Total Dialed",
      "Total Connected",
      "Total Errors",
      "Misc",
      "Total Voicemail",
      "Duration",
      "Pricing",
      "Whitelabel",
      "Pickup Rate",
      "Average Call Duration",
    ];

    const headers2 = [
      "Gateway Owner Email",
      "Account Name",
      "Account Sid",
      "Total Dialed",
      "Total Connected",
      "Total Errors",
      "Misc",
      "Total Voicemail",
      "Duration",
      "Pricing",
      "Pickup Rate",
      "Average Call Duration",
    ];
    const headers3 = [
      "Account Email",
      "Account Name",
      "Account Sid",
      "Total Dialed",
      "Total Connected",
      "Total Errors",
      "Misc",
      "Total Voicemail",
      "Duration",
      "Pricing",
      "Pickup Rate",
      "Average Call Duration",
    ];

    let worksheetHeader: any = [];
    let worksheetData: any = [];
    if (dataToBeMapped === "data") {
      const sheetData: any = data.map((que: any) => [
        que?.user_email,
        que?.account_name,
        que?.account_data?.dialed,
        que?.account_data?.connected,
        que?.account_data?.error,
        que?.account_data?.misc,
        que?.account_data?.voicemail,
        que?.account_data?.duration,
        que?.account_data?.price,
        que?.white_label_super_admin,
        que?.sid_data?.pikup_rate,
        que?.sid_data?.average_call_duration,
      ]);
      worksheetHeader = headers1;
      worksheetData = sheetData;
    }

    if (dataToBeMapped === "data1") {
      const sheetData: any = data1.map((que: any) => [
        que?.user_email,
        que?.account_name,
        que?.sid,
        que?.sid_data?.dialed,
        que?.sid_data?.connected,
        que?.sid_data?.error,
        que?.sid_data?.misc,
        que?.sid_data?.voicemail,
        que?.sid_data?.duration,
        que?.sid_data?.price,
        que?.sid_data?.pikup_rate,
        que?.sid_data?.average_call_duration,
      ]);
      worksheetHeader = headers2;
      worksheetData = sheetData;
    }
    if (dataToBeMapped === "data2") {
      const sheetData: any = data2.map((que: any) => [
        que?.user_email,
        que?.account_name,
        que?.sid,
        que?.data?.dialed,
        que?.data?.connected,
        que?.data?.error,
        que?.data?.misc,
        que?.data?.voicemail,
        que?.data?.duration,
        que?.data?.price,
        que?.data?.pikup_rate,
        que?.data?.average_call_duration,
      ]);
      worksheetHeader = headers3;
      worksheetData = sheetData;
    }

    const worksheet = XLSX.utils.aoa_to_sheet([
      worksheetHeader,
      ...worksheetData,
    ]);
    const commonWidth = 25; // Set your desired width here
    worksheet["!cols"] = new Array(worksheetHeader.length).fill({
      wch: commonWidth,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <div>
      <div className="row">
        <TopCard
          title="Total Calls"
          text={`${total?.dialed || 0}`}
          icon="box"
          class="primary"
        />
        <TopCard
          title="Total Connected"
          text={`${total?.connected || 0}`}
          icon="warehouse"
          class="danger"
        />
        <TopCard
          title="Total Failed or Errors"
          text={`${total?.error || 0}`}
          icon="phone-volume"
          class="success"
        />
        <TopCard
          title="Total Voicemail"
          text={`${total?.voicemail || 0}`}
          icon="phone-volume"
          class="success"
        />
      </div>
      <strong>Date format example : 9-9-2024 or 14-10-2024 (d-m-yyyy)</strong>

      <div className="card-header d-flex">
        <input
          type="text"
          className="form-control w-20"
          onChange={(e) => {
            e.persist();
            setDate(e.target.value);
          }}
          value={date}
        />
        <button className="custom-button-icon" onClick={() => init()}>
          Fetch
        </button>
      </div>

      {spin ? (
        <LoadingDotsSpin />
      ) : (
        <>
          <div className="card-body">
            <div style={{ marginTop: "23px", marginBottom: "43px" }}>
              <span style={{ color: "grey", fontSize: "13px", fontWeight: "" }}>
                CALL STATISTICS
              </span>
              <h3>Call Distribution</h3>
              <div className="w-full h-[400px]">
                <Line data={chartData} options={options} />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <strong className="ml-2 mt-2">Account Wise Calls Data </strong>
              <span className="float-right">
                <button
                  className="custom-button-icon"
                  onClick={() =>
                    exportTableDataToExcel(
                      "data",
                      `${date} Account_Wise_Calls_Data.xlsx`
                    )
                  }
                >
                  Export
                </button>
              </span>
            </div>

            <div className="card-body">
              <Scrollbars
                style={{ height: "420px", color: "white" }}
                renderThumbVertical={renderThumb}
              >
                <CommonTable
                  body={
                    data?.length > 0
                      ? data.map((que: any, idx) => (
                          <tr key={`${idx}comm`}>
                            <td>{que?.user_email}</td>
                            <td>{que?.account_name}</td>
                            <td>{que?.account_data?.dialed}</td>
                            <td>{que?.account_data?.connected}</td>
                            <td>{que?.account_data?.error}</td>
                            <td>{que?.account_data?.misc}</td>
                            <td>{que?.account_data?.voicemail}</td>
                            <td>{que?.account_data?.duration}</td>
                            <td>{que?.account_data?.price}</td>
                            <td>{que?.white_label_super_admin}</td>
                            <td>{que?.sid_data?.pikup_rate}</td>
                            <td>{que?.sid_data?.average_call_duration}</td>
                          </tr>
                        ))
                      : NoDataFound(12)
                  }
                  headers={[
                    "Account Email",
                    "Account Name",
                    "Total Dialed",
                    "Total Connected",
                    "Total Errors",
                    "Misc",
                    " Total Voicemail",
                    "Duration",
                    "Pricing",
                    "Whitelabel",
                    "Pickup Rate",
                    "Average Call Duration",
                  ]}
                />
              </Scrollbars>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <strong className="ml-2 mt-2">Gateway Wise Calls Data </strong>
              <span className="float-right">
                <button
                  className="custom-button-icon"
                  onClick={() =>
                    exportTableDataToExcel(
                      "data1",
                      `${date}_Gateway_Wise_Calls_Data.xlsx`
                    )
                  }
                >
                  Export
                </button>
              </span>
            </div>
            <div className="card-body">
              <Scrollbars
                style={{ height: "420px", color: "white" }}
                renderThumbVertical={renderThumb}
              >
                <CommonTable
                  body={
                    data1?.length > 0
                      ? data1.map((que: any, idx) => (
                          <tr key={`${idx}comm`}>
                            <td>{que?.user_email}</td>
                            <td>{que?.account_name}</td>
                            <td>{que?.sid}</td>
                            <td>{que?.sid_data?.dialed}</td>
                            <td>{que?.sid_data?.connected}</td>
                            <td>{que?.sid_data?.error}</td>
                            <td>{que?.sid_data?.misc}</td>
                            <td>{que?.sid_data?.voicemail}</td>
                            <td>{que?.sid_data?.duration}</td>
                            <td>{que?.sid_data?.price}</td>
                            <td>{que?.sid_data?.pikup_rate}</td>
                            <td>{que?.sid_data?.average_call_duration}</td>
                          </tr>
                        ))
                      : NoDataFound(12)
                  }
                  headers={[
                    "Gateway Owner Email",
                    "Account Name",
                    "Account Sid",
                    "Total Dialed",
                    "Total Connected",
                    "Total Errors",
                    "Misc",
                    " Total Voicemail",
                    "Duration",
                    "Pricing",
                    "Pickup Rate",
                    "Average Call Duration",
                  ]}
                />
              </Scrollbars>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <strong className="ml-2 mt-2">
                {" "}
                Account + Gateway Wise Calls Data
              </strong>
              <span className="float-right">
                <button
                  className="custom-button-icon"
                  onClick={() =>
                    exportTableDataToExcel(
                      "data2",
                      `${date}_Account + Gateway_Calls_Data.xlsx`
                    )
                  }
                >
                  Export
                </button>
              </span>
            </div>

            <div className="card-body">
              <Scrollbars
                style={{ height: "420px", color: "white" }}
                renderThumbVertical={renderThumb}
              >
                <CommonTable
                  body={
                    data2?.length > 0
                      ? data2.map((que: any, idx) => (
                          <tr key={`${idx}comm`}>
                            <td>{que?.user_email}</td>
                            <td>{que?.account_name}</td>
                            <td>{que?.sid}</td>
                            <td>{que?.data?.dialed}</td>
                            <td>{que?.data?.connected}</td>
                            <td>{que?.data?.error}</td>
                            <td>{que?.data?.misc}</td>
                            <td>{que?.data?.voicemail}</td>
                            <td>{que?.data?.duration}</td>
                            <td>{que?.data?.price}</td>
                            <td>{que?.data?.pikup_rate}</td>
                            <td>{que?.data?.average_call_duration}</td>
                          </tr>
                        ))
                      : NoDataFound(12)
                  }
                  headers={[
                    "Account Email",
                    "Account Name",
                    "Account Sid",
                    "Total Dialed",
                    "Total Connected",
                    "Total Errors",
                    "Misc",
                    " Total Voicemail",
                    "Duration",
                    "Pricing",
                    "Pickup Rate",
                    "Average Call Duration",
                  ]}
                />
              </Scrollbars>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TotalCalls;
